// This is a helper class to fix a touch issue that came up in flickity
// on iOS devices as of version 13. It should smooth out some of the scroll
// and swipe issues that flickity is having on that version of iOS.
const flickityTouchFix = () => {
  let touchingSlider = false;
  let touchStartCoordsX = 0;

  const onTouchStart = e => {
    if (e.target.closest && e.target.closest('.flickity-slider')) {
      touchingSlider = true;
      touchStartCoordsX = e.touches[0].pageX;
    } else {
      touchingSlider = false;
    }
  };

  const onTouchMove = e => {
    if (!(touchingSlider && e.cancelable)) {
      return;
    }

    if (Math.abs(e.touches[0].pageX - touchStartCoordsX) > 10) {
      e.preventDefault();
    }
  };

  document.body.addEventListener('touchstart', onTouchStart);
  document.body.addEventListener('touchmove', onTouchMove, { passive: false });
};

export default flickityTouchFix;
