import AsyncView from '@pixelunion/shopify-asyncview';
import rimg from '@pixelunion/rimg-shopify';
import Modal from './Modal';
import ProductDetails from './ProductDetails';
import RichText from './RichText';
import initShopifyProductReviews from '../helpers/ProductReviews';
import Shortcodes from '../shortcodes/Shortcodes';

/**
 * Updates element IDs to ensure they are unique within the document by checking for conflicts.
 * Also checks associated labels and updates the `for` attribute if required.
 * @param {NodeList} idEls Elements to check for ID uniqueness within the document
 * @param {NodeList} labels Labels to update if element with associated ID is updated.
 */
function makeIDsUnique(idEls, labels) {
  idEls.forEach(idEl => {
    const oldId = idEl.getAttribute('id');
    const duplicateCount = document.querySelectorAll(`[id$="${oldId}"]`).length;

    if (duplicateCount > 0) {
      const newId = `${duplicateCount}${oldId}`;
      idEl.setAttribute('id', newId);

      // update label
      labels.forEach(label => {
        if (label.matches(`[for="${oldId}"]`)) {
          label.setAttribute('for', newId);
        }
      });
    }
  });
}

export default class ProductQuickshop {
  constructor(options) {
    this.options = options;
    this.el = null;
    this.modal = null;
    this.richText = null;
    this.productDetails = null;
    this.initialVariant = this.options.initialVariant || null;

    this.isLoaded = false;
    this.loader = null;

    this.isOpen = false;
  }

  /**
   * Sets a variant to be preselected when quickshop is opened
   * @param {integer} variantId - Variant ID to preselect when quickshop is opened
   */
  setInitialVariant(variantId) {
    this.initialVariant = variantId;
  }

  open(full) {
    const loader = !this.isLoaded && this.loader ? this.loader : this.load();

    loader.then(() => {
      const {
        id,
        url,
        trigger,
        button,
      } = this.options;
      const modalCallbacks = {
        onOpen: () => this._selectInitialVariant(),
        onClose: () => this.close(),
      };

      const leftThumbsClass = button[0] && button[0].hasAttribute('data-thumbs-left')
        ? ' quickshop-thumbs-left'
        : '';

      this.modal = new Modal(modalCallbacks);
      this.modal.open(
        `#shopify-section-${id} [data-product-quickshop-url="${url}"]`,
        full ? `quickshop-full${leftThumbsClass}` : 'quickshop-slim',
        trigger,
      );

      rimg.watch(this.el);

      this.productDetails.onQuickshopOpen();

      if (window.Shopify && Shopify.PaymentButton) {
        Shopify.PaymentButton.init();
      }

      this.isOpen = true;
    });
  }

  close() {
    if (this.isLoaded || this.loader) {
      const loader = this.loader || Promise.resolve();

      return loader.then(() => {
        if (!this.isOpen) {
          return;
        }

        if (!this.openingAddToCart) {
          const { trigger } = this.options;
          trigger.focus();
        }

        this._clearNotification();
        this.modal.unload();

        this.productDetails.unloadAllMedia();

        this.isOpen = false;
      });
    }

    return Promise.resolve();
  }

  unload() {
    if (this.isLoaded || this.loader) {
      return this.close()
        .then(() => {
          if (this.productDetails) {
            this.productDetails.unload();
          }

          if (this.richText) {
            this.richText.unload();
          }

          if (this.shortcodes) {
            this.shortcodes.unload();
          }
        });
    }

    return Promise.resolve();
  }

  load() {
    // If we're already loaded, return true
    if (this.isLoaded) {
      return Promise.resolve(true);
    }

    // If we're currently loading, return the existing promise
    if (this.loader) {
      return this.loader;
    }

    const {
      el,
      url,
      hash,
    } = this.options;
    this.openingAddToCart = false;

    this.loader = AsyncView.load(url, { view: '_quickshop' }, { hash })
      .then(response => {
        const { data, html } = response;
        const {
          context,
          settings,
          product,
          models,
        } = data;
        const container = document.createElement('div');

        container.innerHTML = html;

        const formArea = container.querySelector('[data-product-form-area]');
        makeIDsUnique(formArea.querySelectorAll('select[id], input[id]'), formArea.querySelectorAll('label[for]'));
        const gallery = container.querySelector('[data-product-gallery]');
        const details = container.querySelector('[data-product-details]');
        const description = container.querySelector('[data-product-description]');

        this._onATCInit = this._onATCInit.bind(this);
        this._onATCError = this._onATCError.bind(this);
        this._onATCSuccess = this._onATCSuccess.bind(this);
        this._onATCClose = this._onATCClose.bind(this);

        const atcCallbacks = {
          onInit: this._onATCInit,
          onError: this._onATCError,
          onSuccess: this._onATCSuccess,
          onClose: this._onATCClose,
        };

        this.productDetails = new ProductDetails({
          $formArea: $(formArea),
          gallery,
          $details: $(details),
          atcCallbacks,
          context,
          settings,
          product,
          models,
          useHistory: false,
          isQuickshop: true,
          productEl: container,
        });

        this.shortcodes = new Shortcodes(container);

        if (description) {
          this.richText = new RichText(description);
        }

        initShopifyProductReviews();

        el.setAttribute('data-loaded', true);

        this.el = el.appendChild(container);
        this.isLoaded = true;
      })
      .then(() => {
        this.loader = null;
        return true;
      })
      .catch(() => {
        this.loader = null;
        return false;
      });

    return this.loader;
  }

  _selectInitialVariant() {
    this.productDetails.selectVariant(this.initialVariant);
  }

  _setNotification(notification, isVisible = false, shouldFocus = false) {
    if (!this.isLoaded) {
      return;
    }

    const notificationContainer = this.el.querySelector('[data-product-quickshop-message]');

    notificationContainer.appendChild(notification);

    if (isVisible) {
      notificationContainer.classList.add('visible');
    } else {
      notificationContainer.classList.remove('visible');
    }

    if (shouldFocus) {
      notification.focus();
    }
  }

  _clearNotification() {
    if (!this.isLoaded) {
      return;
    }

    const notificationContainer = this.el.querySelector('[data-product-quickshop-message]');

    notificationContainer.classList.remove('visible');

    while (notificationContainer.firstChild) {
      notificationContainer.removeChild(notificationContainer.firstChild);
    }
  }

  _onATCInit() {
    this.openingAddToCart = true;
    this._clearNotification();
  }

  _onATCError(error) {
    const notification = document.createElement('div');

    notification.classList.add('product-message--error');
    notification.setAttribute('tabindex', '-1');
    notification.innerHTML = error;

    this._setNotification(notification, true, true);
  }

  _onATCSuccess() {
    this.close();
  }

  _onATCClose() {
    const { trigger } = this.options;
    trigger.focus();
  }
}
