import Shortcode from './Shortcode';

export default class Details extends Shortcode {
  static get shortcode() {
    return 'details';
  }

  constructor(el) {
    super();

    this.summary = el.querySelector('summary');

    // We need to add a wrapper to remove the outline when clicking on the summary.
    // This way there will still be an outline for keyboard users.
    const summaryWrapper = document.createElement('div');
    summaryWrapper.setAttribute('tabindex', '-1');
    summaryWrapper.classList.add('summary-wrapper');
    summaryWrapper.innerHTML = this.summary.innerHTML;

    this.summary.innerHTML = '';
    this.summary.appendChild(summaryWrapper);
  }
}
