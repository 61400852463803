import * as breakpoint from '@pixelunion/breakpoint';
import Header from './Header';

export default class BasicHeader extends Header {
  constructor(options, settings) {
    super(options, settings);

    this.headerContent = this.header.querySelector('[data-site-header-main]');
    this.logo = this.header.querySelector('.site-logo');
    this.logoImage = this.logo.querySelector('img');
    this.logoText = this.logo.querySelector('.desktop-logo-text');
    this.logoPosition = settings.desktop_logo_position;
    this.navList = this.header.querySelector('.site-navigation > [data-navmenu]');
    this.headerActions = this.header.querySelector('.site-header-main-actions');
    this.searchWrapper = this.header.querySelector('[data-search-wrapper]');
    this.searchButton = this.headerActions.querySelector('[data-search-toggle]');
    this.searchInput = this.header.querySelector('[data-live-search-input]');
    this.searchClose = this.header.querySelector('[data-search-close]');
    this.takeoverCancel = this.header.querySelector('[data-live-search-takeover-cancel]');

    this._adjustSizeAndPlacement = this._adjustSizeAndPlacement.bind(this);
    this._openSearch = this._openSearch.bind(this);
    this._closeSearch = this._closeSearch.bind(this);

    // Need to watch for logo load so we can determin nav placement based on its width
    if (this.logoImage) {
      this.logoImage.addEventListener('load', this._adjustSizeAndPlacement);
    }

    this._adjustSizeAndPlacement();

    this.previousWindowWidth = window.innerWidth;
    this.windowResize = () => {
      if (this.previousWindowWidth === window.innerWidth) return;

      this.previousWindowWidth = window.innerWidth;
      this._adjustSizeAndPlacement();
    };

    this._closeEsc = e => {
      if (e.key === 'Escape') {
        this._closeSearch();
      }
    };

    this._searchFocusIn = () => {
      if (!this.searchWrapper.contains(document.activeElement)) {
        this._closeSearch();
      }
    };

    this.searchButton.addEventListener('click', this._openSearch);
    this.searchClose.addEventListener('click', this._closeSearch);
    this.takeoverCancel.addEventListener('click', this._closeSearch);
    window.addEventListener('resize', this.windowResize);
    window.addEventListener('load', this._adjustSizeAndPlacement);
    window.addEventListener('keydown', this._closeEsc);
  }

  _adjustSizeAndPlacement() {
    const headerStyles = window.getComputedStyle(this.headerContent);
    const headerWidth = this.headerContent.clientWidth - headerStyles.paddingLeft.replace('px', '');
    const headerActionsWidth = this.headerActions.clientWidth;
    const logoWidth = this.logo.clientWidth;
    const logoHeight = this.logo.clientHeight;
    const navListWidth = this.navList.clientWidth;

    const setHeaderMinHeight = () => {
      if (breakpoint.max('M')) {
        this.headerContent.style.minHeight = '';
      } else {
        const paddingTop = parseInt(headerStyles.paddingTop.replace('px', ''), 10);
        const paddingBottom = parseInt(headerStyles.paddingBottom.replace('px', ''), 10);
        this.headerContent.style.minHeight = `${logoHeight + paddingTop + paddingBottom}px`;
      }
    };

    if (this.settings.navigation_layout === 'below') {
      setHeaderMinHeight();
      return;
    }

    if (this.logoPosition === 'center') {
      const logoContentWidth = this.logoImage
        ? this.logoImage.clientWidth : this.logoText.clientWidth;

      if (navListWidth > logoWidth / 2 - logoContentWidth / 2) {
        this.headerContent.classList.add('site-header-stacked');
      } else {
        this.headerContent.classList.remove('site-header-stacked');
      }
    } else {
      const widestSide = headerActionsWidth > logoWidth ? headerActionsWidth : logoWidth;

      setHeaderMinHeight();

      if (headerWidth / 2 <= navListWidth / 2 + widestSide) {
        this.headerContent.classList.add('site-header-stacked');
      } else {
        this.headerContent.classList.remove('site-header-stacked');
      }
    }

    this.header.classList.remove('site-header-loading');
  }

  _openSearch(e) {
    e.stopPropagation();

    if (this.liveSearch && breakpoint.max('S')) {
      // If LiveSearch is enabled, open the mobile search takeover
      this.liveSearch.openMobileTakeover();
    }

    this.headerContent.classList.remove('live-search--hidden');
    this.searchInput.focus();
    window.addEventListener('focusin', this._searchFocusIn);
  }

  _closeSearch() {
    this.headerContent.classList.add('live-search--hidden');
    if (this.searchWrapper.contains(document.activeElement)) {
      this.searchButton.focus();
    }

    window.removeEventListener('focusin', this._searchFocusIn);
  }

  unload() {
    super.unload();

    if (this.logoImage) {
      this.logoImage.removeEventListener('load', this._adjustSizeAndPlacement);
    }

    this.searchButton.removeEventListener('click', this._openSearch);
    this.searchClose.removeEventListener('click', this._closeSearch);
    this.takeoverCancel.removeEventListener('click', this._closeSearch);
    window.removeEventListener('resize', this.windowResize);
    window.removeEventListener('load', this._adjustSizeAndPlacement);
    window.removeEventListener('keydown', this._closeEsc);
  }
}
