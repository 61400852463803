export default class DynamicCollectionList {
  constructor(section) {
    this.el = section.el;
    this.collections = this.el.querySelectorAll('.dynamic-collection-list--item');
    this.showMoreButton = this.el.querySelector('.dynamic-collection-list--show-more-button');

    this._showAllCollections = this._showAllCollections.bind(this);
    this.showMoreButton.addEventListener('click', this._showAllCollections);  
  }

  _applyClasses() {
    const collections = this.collections;

    collections.forEach((collection) => {
      collection.classList.add('active', 'active-mobile');
    });
  }

  _showAllCollections() {
    this._applyClasses();
    this.showMoreButton.classList.remove('active', 'active-mobile');
  }

  onSectionUnload() {
    this.showMoreButton.removeEventListener('click', this._showAllCollections);
  }
}
