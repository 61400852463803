import Drift from 'drift-zoom';
import Flickity from 'flickity';
import EventHandler from '@pixelunion/events';
import rimg from '@pixelunion/rimg-shopify';

import layout from '../Layout';
import Video from './Video';
import ProductClicktoZoom from './ProductClickToZoom';

export default class ProductGallery {
  constructor(options) {
    this.el = options.el;
    this.settings = options.settings;
    this.isQuickshop = options.isQuickshop || false;
    this.modelJson = options.models;

    this.events = new EventHandler();
    this.viewer = this.el.querySelector('[data-gallery-viewer]');
    this.navigation = this.el.querySelector('[data-gallery-navigation]');
    this.navScroller = this.el.querySelector('[data-gallery-scroller]');
    this.navScrollerButtons = this.el.querySelectorAll('[data-gallery-scroll-button]');
    this.figures = this.viewer.querySelectorAll('[data-gallery-figure]');
    this.thumbnails = this.navigation.querySelectorAll('[data-gallery-thumbnail]');
    this.zoomButton = this.viewer.querySelector('[data-gallery-expand]');

    this.selected = {
      figure: this.viewer.querySelector('[data-gallery-selected="true"]'),
      thumbnail: this.navigation.querySelector('[data-gallery-selected="true"]'),
    };

    this.models = {};
    this.videos = {};
    this.selectedVideo = null;
    this.selectedModel = null;
    this.showingInitialSlide = true;

    this.updateSlideHeight = this._setSlideHeight.bind(this);
    this._setupSelectedVideo = this._setupSelectedVideo.bind(this);

    const features = [];

    if (this.el.querySelector('[data-media-type="video"]')) {
      Promise.all([
        new Promise(resolve => {
          if (!document.querySelector('#plyr-stylesheet')) {
            const link = document.createElement('link');

            link.setAttribute('id', 'plyr-stylesheet');
            link.setAttribute('rel', 'stylesheet');
            link.setAttribute('href', 'https://cdn.shopify.com/shopifycloud/shopify-plyr/v1.0/shopify-plyr.css');
            link.onload = resolve;
            document.body.appendChild(link);
          } else {
            resolve();
          }
        }),
        new Promise(resolve => {
          features.push({
            name: 'video-ui',
            version: '1.0',
            onLoad: resolve,
          });
        }),
      ]).then(() => this._setupSelectedVideo());
    }

    if (this.el.querySelector('[data-media-type="model"]')) {
      this.viewInYourSpaceEl = this.el.querySelector('[data-shopify-xr]');
      this.events.register(this.viewInYourSpaceEl, 'click', e => this._onViewInYourSpaceClick(e));

      features.push(
        {
          name: 'model-viewer-ui',
          version: '1.0',
          onLoad: () => this._onModelLibraryLoad(),
        },
      );
      features.push(
        {
          name: 'shopify-xr',
          version: '1.0',
          onLoad: () => this._onShopifyXRLoad(),
        },
      );
    }

    if (features.length) {
      window.Shopify.loadFeatures(features);
    }

    if (this.isQuickshop) {
      const quickshopModal = document.querySelector('[data-modal-container]');
      quickshopModal.classList.add('quickshop-thumbs-left');

      // Because of the way the quickshop is animated to open, if a video is first in the
      // gallery the height of the slide will not be correct, so we need to wait until all the
      // transitions end. This will only fire once, as opposed to listening to transitionend.
      // this is especially important if the thumbnails are left aligned.
      if (this.figures[0].dataset.mediaType === 'video') {
        setTimeout(() => this._setSlideHeight(), 800);
      }
    }

    this.events.register(this.navScroller, 'click', e => this._onThumbnailClick(e));
    this.events.register(this.navScroller, 'scroll', () => this._handleScrollButtonVisibility());
    this.events.register(this.navScrollerButtons[0], 'click', () => this._onScrollButtonClick(true));
    this.events.register(this.navScrollerButtons[1], 'click', () => this._onScrollButtonClick(false));

    if (this.settings.click_to_zoom !== 'disabled') {
      const onZoomButtonClick = e => {
        e.stopPropagation();
        this._openImageZoom();
      };

      this.events.register(this.zoomButton, 'click', e => onZoomButtonClick(e));
      this.events.register(this.viewer, 'click', e => onZoomButtonClick(e));
    }

    this._onResize = this._onResize.bind(this);
    this.events.register(window, 'resize', () => this._onResize());

    if (layout.isLessThanBreakpoint('S')) {
      this._setupFlickity();
      this._handleScrollButtonVisibility();
    }

    this._selectMediaByEl(this.selected.figure);
  }

  selectMediaByVariant(variant) {
    if (!variant.featured_media) return;

    const slide = this.viewer.querySelector(`[data-media="${variant.featured_media.id}"]`);
    this._selectMediaByEl(slide);
  }

  // There are a few things we have to reset when the quickshop is opened. This is to catch
  // the situation where if a quickshop is closed and then re-opened, especially after
  // screen resize.
  onQuickshopOpen() {
    this._setSlideHeight();
    this._handleScrollButtonVisibility();
    this._selectMediaByEl(this.selected.figure);
  }

  unloadAllMedia() {
    for (let i = 0; i < this.figures.length; i++) {
      if (this.models[i]) {
        this.models[i].destroy();
        this.models[i] = null;
      }

      if (this.videos[i]) {
        this.videos[i].destroy();
        this.videos[i] = null;
      }
    }
  }

  unload() {
    this.events.unregisterAll();

    this.unloadAllMedia();

    if (this.isQuickshop) {
      rimg.unwatch(this.el);
    }

    this._closeImageZoom();
    this._disableHoverZoom();
    this._disableFlickity();
  }

  _onModelLibraryLoad() {
    const onPlayListener = () => {
      if (this.flickity) {
        this.flickity.unbindDrag();
      }
    };
    const onPauseListener = () => {
      if (this.flickity) {
        this.flickity.bindDrag();
      }
    };
    this.el.querySelectorAll('.product-gallery--model')
      .forEach(figureEl => {
        const modelEl = figureEl.querySelector('model-viewer');
        const { galleryIndex } = figureEl.dataset;
        const controls = ['zoom-in', 'zoom-out'];
        if (document.fullscreenEnabled) controls.push('fullscreen');

        this.models[galleryIndex] = new Shopify.ModelViewerUI(modelEl, { controls });

        this.events.register(modelEl, 'shopify_model_viewer_ui_toggle_play', onPlayListener);
        this.events.register(modelEl, 'shopify_model_viewer_ui_toggle_pause', onPauseListener);
      });
  }

  _onShopifyXRLoad() {
    if (this.modelJson) {
      if (!window.ShopifyXR) {
        document.addEventListener('shopify_xr_initialized', () => this._onShopifyXRLoad());
      } else {
        window.ShopifyXR.addModels(this.modelJson);
        window.ShopifyXR.setupXRElements();
      }
    }
  }

  _onResize() {
    if (layout.isGreaterThanBreakpoint('S', true)) {
      this._disableFlickity();
    } else {
      this._setupFlickity();
    }

    if (layout.isGreaterThanBreakpoint('L', true) && !this.drift) {
      this._setupHoverZoom();
    } else if (layout.isLessThanBreakpoint('L')) {
      this._disableHoverZoom();
    }

    this._adjustGalleryPositioning();
    this._handleScrollButtonVisibility();
  }

  _onThumbnailClick(e) {
    let button;
    this.showingInitialSlide = false;

    // We use pointer-events: none on all of the elements within the thumbnail
    // so that any click event will be triggered on the button.
    if (e.target.classList.contains('product-gallery--media-thumbnail')) {
      button = e.target;
    } else {
      return;
    }

    const selectedIndex = parseInt(button.dataset.galleryIndex, 10);

    if (this.flickity) {
      this.flickity.select(selectedIndex);
    } else {
      this._selectMediaByIndex(selectedIndex);
    }
  }

  _onViewInYourSpaceClick(e) {
    const { currentTarget } = e;
    const figure = this.viewer.querySelector(`[data-media="${currentTarget.dataset.shopifyModel3dId}"]`);

    this._selectMediaByEl(figure);
  }

  _selectMediaByEl(el) {
    this._selectMediaByIndex(parseInt(el.dataset.galleryIndex, 10));
  }

  _selectMediaByIndex(index) {
    const figure = this.figures[index];

    this.selected.figure.dataset.gallerySelected = false;
    this.selected.figure.setAttribute('aria-hidden', true);
    this.selected.figure = figure;
    this.selected.figure.dataset.gallerySelected = true;
    this.selected.figure.setAttribute('aria-hidden', false);

    this.selected.thumbnail.dataset.gallerySelected = false;
    this.selected.thumbnail = this.thumbnails[index];
    this.selected.thumbnail.dataset.gallerySelected = true;

    const { media, mediaType } = this.selected.figure.dataset;

    this.viewer.dataset.selectedMediaType = mediaType;

    this._disableHoverZoom();

    if (this.selectedModel) {
      this.selectedModel.pause();
    }

    if (this.models[figure.dataset.galleryIndex]) {
      this.selectedModel = this.models[figure.dataset.galleryIndex];
      if (!document.documentElement.classList.contains('has-touch') && !this.showingInitialSlide) {
        this.selectedModel.play();
      }
    }

    if (this.selectedVideo) {
      this.selectedVideo.pause();
    }

    if (this.viewInYourSpaceEl) {
      this.viewInYourSpaceEl.dataset.shopifyModel3dId = this.viewInYourSpaceEl.dataset.defaultModelId;
    }

    switch (mediaType) {
      case 'model':
        this.viewInYourSpaceEl.dataset.shopifyModel3dId = media;
        break;
      case 'video':
      case 'external_video':
        this._setupSelectedVideo();
        break;
      case 'image':
        this._setupHoverZoom();
        break;
      default: break;
    }

    if (this.flickity) {
      this.flickity.bindDrag();
      this.flickity.select(index);
    }

    this._adjustGalleryPositioning();
  }

  _setupSelectedVideo() {
    if (this.selectedVideo) {
      this.selectedVideo.pause();
    }

    const { mediaType, galleryIndex } = this.selected.figure.dataset;
    const shouldAutoplay = layout.isGreaterThanBreakpoint('L', true)
      && this.settings.gallery_video_autoplay
      && !this.showingInitialSlide;

    if (mediaType === 'video' || mediaType === 'external_video') {
      let videoElement;

      if (this.videos[galleryIndex]) {
        this.selectedVideo = this.videos[galleryIndex];
        if (shouldAutoplay) this.selectedVideo.play();
      } else {
        if (mediaType === 'video') {
          // Plyr hasn't loaded yet, will be called again after loadFeatures
          if (!Shopify.Plyr) return;
          videoElement = this.selected.figure.querySelector('video');

          this.selectedVideo = new Shopify.Plyr(videoElement, {
            loop: { active: this.settings.gallery_video_looping },
          });
          if (shouldAutoplay) this.selectedVideo.play();
        } else {
          videoElement = this.selected.figure.querySelector('[data-video]');
          this.selectedVideo = new Video(videoElement, {
            loop: this.settings.gallery_video_looping,
          });
          if (shouldAutoplay) this.selectedVideo.play();
        }
        this.videos[galleryIndex] = this.selectedVideo;
      }
      this.selected.figure.focus();
    }
  }

  _setupHoverZoom() {
    if (
      this.settings.hover_zoom === 'disabled'
      || !layout.isGreaterThanBreakpoint('L', true)
      || this.selected.figure.dataset.mediaType !== 'image'
      || document.documentElement.classList.contains('has-touch')
    ) return;

    const selectedImage = this.selected.figure.querySelector('[data-rimg]');
    selectedImage.setAttribute('data-zoom', this.selected.figure.getAttribute('data-zoom'));

    this.drift = new Drift(selectedImage, {
      paneContainer: this.settings.hover_zoom === 'separate'
        ? this.el.querySelector('[data-zoomed-image]')
        : this.selected.figure,
      inlinePane: false,
      hoverBoundingBox: this.settings.hover_zoom === 'separate',
      handleTouch: false,
      onShow: () => {
        if (this.settings.hover_zoom === 'separate') {
          document.body.classList.add('product-gallery--fade');
        }
      },
      onHide: () => {
        if (this.settings.hover_zoom === 'separate') {
          document.body.classList.remove('product-gallery--fade');
        }
      },
    });
  }

  _disableHoverZoom() {
    if (this.drift) {
      this.drift.disable();
      this.drift = null;
    }
  }

  _setupFlickity() {
    if (
      this.flickity
      || !layout.isLessThanBreakpoint('S')
    ) return;

    this.flickity = new Flickity(this.viewer, {
      accessibility: false,
      adaptiveHeight: true,
      cellSelector: '.product-gallery--media',
      contain: true,
      friction: 0.5,
      freeScroll: false,
      initialIndex: parseInt(this.selected.figure.dataset.galleryIndex, 10),
      lazyLoad: false,
      percentPosition: false,
      prevNextButtons: false,
      pageDots: false,
      selectedAttraction: 0.1,
      setGallerySize: false,
      wrapAround: true,
    });

    this.flickityEvent = this.events.register(
      this.settings.lazy_load ? this.viewer : this.selected.figure.querySelector('img'),
      this.settings.lazy_load ? 'rimg:load' : 'load',
      () => this._setSlideHeight(),
    );

    this.flickity.on('change', index => {
      this.showingInitialSlide = false;
      this._selectMediaByEl(this.figures[index]);
    });

    // Flickity controls the pointerdown event which prevents model-viewer-ui from
    // triggering .play() on mouseup. This returns this functionality.
    this.flickity.on('staticClick', (event, pointer, cellElement) => {
      const figure = cellElement.querySelector('.product-gallery--model');

      if (figure && this.models[figure.dataset.galleryIndex]) {
        const model = this.models[figure.dataset.galleryIndex];
        model.play();
      }
    });

    this.flickity.on('pointerDown', () => {
      // Removes the focus ring when swiping with touch device
      this.viewer.blur();
    });

    // We need to disable the product zoom open if a user is swiping
    // This was an issue on mouse gestures, not on touch
    if (this.settings.click_to_zoom !== 'disabled'
      && document.documentElement.classList.contains('no-touch')) {
      this.flickity.on('dragEnd', () => {
        this.swiped = true;
      });
    }

    this._setSlideHeight();
  }

  _disableFlickity() {
    if (this.flickity) {
      this.flickity.destroy();
      this.flickity = null;
      this.events.unregister(this.flickityEvent);
    }
  }

  _setSlideHeight() {
    this.navigation.classList.remove('loading');

    // Use the stored image height for situations where the DOM is in a midway state.
    const containerHeight = this.storedImageHeight
      || this.selected.figure.getBoundingClientRect().height;
    this.storedImageHeight = null;

    if (containerHeight > 0) {
      this.viewer.style.height = `${containerHeight}px`;
    }
  }

  _adjustGalleryPositioning() {
    if (this.settings.thumbnail_position === 'left') {
      if (this.isQuickshop) {
        if (layout.isGreaterThanBreakpoint('L', true)) {
          this.el.dataset.productGalleryThumbs = 'left';
        } else {
          this.storedImageHeight = this.selected.figure.getBoundingClientRect().height;
          this.el.dataset.productGalleryThumbs = 'center';
        }
      }

      // We need to adjust the width of the thumbnails if they are positioned left
      // and they wrap into 2 columns. CSS flexbox does not cause the width of the
      // element to stretch. Looks like it's a problem with flexbox when wrapping columns:
      // https://stackoverflow.com/questions/33891709/when-flexbox-items-wrap-in-column-mode-container-does-not-grow-its-width
      if (layout.isGreaterThanBreakpoint('L', true)
        || (layout.isGreaterThanBreakpoint('S', true) && !this.isQuickshop)) {
        if (this.el.firstChild !== this.navigation) {
          const activeButton = document.activeElement;
          this.el.insertBefore(this.navigation, this.viewer);
          activeButton.focus(); // This prevents loss of focus if one of the thumbnails are selected
        }

        // When switching layouts in the editor sometimes we need to wait for a few milliseconds
        // before positioning the thumbs.
        setTimeout(() => {
          // Double check that the layout is the correct size, since the timeout will
          if (layout.isGreaterThanBreakpoint('L', true)
            || (layout.isGreaterThanBreakpoint('S', true) && !this.isQuickshop)) {
            const leftmostBounds = this.thumbnails[0].getBoundingClientRect();
            const thumbStyle = this.thumbnails[0].currentStyle
              || window.getComputedStyle(this.thumbnails[0]);
            const thumbMargin = (parseInt(thumbStyle.marginLeft, 10)) * 2;
            const wrapped = Array.prototype.some.call(this.thumbnails,
              thumb => thumb.getBoundingClientRect().left > leftmostBounds.left);
            if (wrapped) {
              this.navigation.style.width = `${(leftmostBounds.width + thumbMargin) * 2}px`;
              this.navigation.style.maxWidth = `${(leftmostBounds.width + thumbMargin) * 2}px`;
            }
          } else {
            this.navigation.style.width = '';
            this.navigation.style.maxWidth = '';
          }
          this._setSlideHeight();
        }, 500);
      } else {
        if (this.el.firstElementChild === this.navigation) {
          this.el.appendChild(this.navigation);
        }
        this.navigation.style.width = '';
        this.navigation.style.maxWidth = '';
        this._setSlideHeight();
      }

      if (layout.isLessThanBreakpoint('S')) {
        this._adjustMobileThumbnailPosition();
      }
    } else {
      this._setSlideHeight();
      this._adjustMobileThumbnailPosition();
    }
  }

  _adjustMobileThumbnailPosition() {
    // This brings the thumbnail into view if it is not visible on the screen after
    // a user swipes to a slide that it is tied to. Only important on mobile.
    if (layout.isLessThanBreakpoint('S')
      || (layout.isLessThanBreakpoint('L') && this.isQuickshop)) {
      const thumbBounds = this.selected.thumbnail.getBoundingClientRect();
      const thumbWrapperBounds = this.navScroller.getBoundingClientRect();

      if (this.selected.thumbnail.offsetLeft + thumbBounds.width + 30
        - this.navScroller.scrollLeft > thumbWrapperBounds.width) {
        this.navScroller.scrollLeft = this.selected.thumbnail.offsetLeft
          + thumbBounds.width - thumbWrapperBounds.width + 35;
      } else if (this.selected.thumbnail.offsetLeft < this.navScroller.scrollLeft) {
        this.navScroller.scrollLeft = this.selected.thumbnail.offsetLeft - 35;
      }
    }
  }

  _handleScrollButtonVisibility() {
    if (layout.isLessThanBreakpoint('S')
      || (layout.isLessThanBreakpoint('L') && this.isQuickshop)) {
      // We use 4px here just to ensure the user has scrolled a little bit before
      // showing the buttons.
      if (this.navScroller.scrollLeft > 4) {
        this.navScrollerButtons[0].classList.add('visible');
      } else {
        this.navScrollerButtons[0].classList.remove('visible');
      }

      if (this.navScroller.scrollLeft < this.navScroller.scrollWidth
        - this.navScroller.clientWidth) {
        this.navScrollerButtons[1].classList.add('visible');
      } else {
        this.navScrollerButtons[1].classList.remove('visible');
      }
    } else {
      this.navScrollerButtons[0].classList.remove('visible');
      this.navScrollerButtons[1].classList.remove('visible');
    }
  }

  _onScrollButtonClick(scrollRight) {
    if (scrollRight) {
      this.navScroller.scrollLeft = this.navScroller.scrollLeft - 100;
    } else {
      this.navScroller.scrollLeft = this.navScroller.scrollLeft + 100;
    }
  }

  _closeImageZoom() {
    if (this.imageZoom) {
      this.imageZoom.unload();
      this.imageZoom = null;
    }
  }

  _openImageZoom() {
    if (this.selected.figure.dataset.mediaType !== 'image'
      || (layout.isGreaterThanBreakpoint('L') && this.settings.click_to_zoom === 'mobile')
      || (layout.isLessThanBreakpoint('L') && this.settings.click_to_zoom === 'desktop')
    ) return;

    if (this.swiped) {
      this.swiped = false;
      return;
    }

    this._closeImageZoom();

    this.imageZoom = new ProductClicktoZoom({
      settings: this.settings,
      slides: this.figures,
      originalThumbnails: this.thumbnails,
      startIndex: parseInt(this.selected.figure.dataset.galleryIndex, 10),
      selectGalleryIndex: index => this._selectMediaByIndex(index),
    });
  }
}
