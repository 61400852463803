import $ from 'jquery';

import Forms from '../Forms';

export default class DynamicNewsletter {
  constructor(section) {
    this.$el = $(section.el);

    this.forms = new Forms(this.$el);
  }

  onSectionUnload() {
    this.forms.unload();
  }
}
