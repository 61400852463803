const eventHandlers = [];
const breakpointSequence = ['XS', 'S', 'M', 'L', 'L+', 'XL', 'XXL'];

function getBreakpoint() {
  return window
    .getComputedStyle(document.documentElement, ':after')
    .getPropertyValue('content')
    .replace(/"/g, '');
}

function isBreakpoint(...breakpoints) {
  for (let i = 0; i < breakpoints.length; i++) {
    if (getBreakpoint() === breakpoints[i]) {
      return true;
    }
  }

  return false;
}

function isLessThanBreakpoint(breakpoint, inclusive) {
  const currentBreakpointIndex = breakpointSequence.indexOf(getBreakpoint());

  return currentBreakpointIndex < breakpointSequence.indexOf(breakpoint)
    || (inclusive && currentBreakpointIndex <= breakpointSequence.indexOf(breakpoint));
}

function isGreaterThanBreakpoint(breakpoint, inclusive) {
  const currentBreakpointIndex = breakpointSequence.indexOf(getBreakpoint());

  return currentBreakpointIndex > breakpointSequence.indexOf(breakpoint)
    || (inclusive && currentBreakpointIndex >= breakpointSequence.indexOf(breakpoint));
}

function onBreakpointChange(eventHandler) {
  if (eventHandlers.indexOf(eventHandler) === -1) {
    eventHandlers.push(eventHandler);
  }
}

function offBreakpointChange(eventHandler) {
  const index = eventHandlers.indexOf(eventHandler);

  if (index !== -1) {
    eventHandlers.splice(index, 1);
  }
}

let previousBreakpoint = getBreakpoint();

window.addEventListener('resize', event => {
  const currentBreakpoint = getBreakpoint();

  if (previousBreakpoint !== currentBreakpoint) {
    eventHandlers.forEach(eventHandler => eventHandler(event, {
      previous: previousBreakpoint,
      current: currentBreakpoint,
    }));
  }

  previousBreakpoint = currentBreakpoint;
});

export default {
  getBreakpoint,
  isBreakpoint,
  isLessThanBreakpoint,
  isGreaterThanBreakpoint,
  onBreakpointChange,
  offBreakpointChange,
};
