import $ from 'jquery';

export default class CartItem {
  constructor(el, data, settings) {
    this.el = el;
    this.data = data;
    this.settings = settings;
    this._id = el.getAttribute('data-cartitem-id');

    this.value = window.api.theme.useValue({
      name: `line-item:${this._id}:price`,
      handler: ({ price }) => {
        $(this.el).each((index, cartItem) => {
          const cartItemTotal = cartItem.querySelector('[data-cartitem-total]');

          cartItemTotal.innerHTML = Shopify.formatMoney(price, this.settings.money_format);
        });
      },
      initialValue: { price: this.data.line_price, compare_at_price: this.data.original_line_price },
    });
  }

  unload() {
    this.value.unload();
  }
}
