import $ from 'jquery';
import $script from 'scriptjs';
import * as breakpoint from '@pixelunion/breakpoint';

import Forms from '../Forms';
import LiveSearch from '../components/search/LiveSearch';
import SearchForm from '../components/search/SearchForm';

export default class DynamicSearch {
  constructor(section) {
    this.el = section.el;
    this.settings = section.data.settings;
    this.search = null;

    this.$scripts = $('[data-scripts]');

    this.searchArea = this.el.querySelector('[data-live-search]');
    this.searchInput = this.el.querySelector('[data-live-search-input]');

    this.forms = new Forms(this.el);

    if (this.settings.live_search.enable) {
      $script(this.$scripts.data('shopify-api-url'), () => {
        this.search = new LiveSearch({
          el: this.searchArea,
          header: this.el,
        }, this.settings.live_search);
      });
    } else {
      this.search = new SearchForm(this.searchField);
    }

    this.searchFocused = () => {
      if (this.settings.live_search.enable && breakpoint.max('S')) {
        this.search.openMobileTakeover();
      }
    };

    this.searchInput.addEventListener('focus', this.searchFocused);
  }

  onSectionUnload() {
    this.search.unload();
    this.forms.unload();
    this.searchInput.removeEventListener('focus', this.searchFocused);
  }
}
