import $ from 'jquery';
import Quantity from './helpers/Quantity';

export default class Forms {
  constructor(el, selector = '.form-field-input') {
    this.$el = $(el);
    this.filledClass = 'form-field-filled';
    this.fieldSelector = selector;

    this.quantityItems = [];
    this.$quantityWrapper = this.$el.find('[data-quantity-wrapper]');

    this._toggleFilled = this._toggleFilled.bind(this);
    this.$el.on('focus.forms', this.fieldSelector, this._toggleFilled);
    this.$el.on('blur.forms', this.fieldSelector, this._toggleFilled);

    this._checkFilled();

    if (this.$quantityWrapper.length) {
      this.$quantityWrapper.each((i, element) => {
        this.quantityItems.push(
          new Quantity(element),
        );
      });
    }
  }

  unload() {
    this.$el.off('.forms');

    this.quantityItems.forEach(quantityItem => {
      quantityItem.unload();
    });
  }

  _checkFilled() {
    this.$el.find(this.fieldSelector).each((i, el) => {
      if ($(el).hasClass(this.filledClass)) return;

      this._toggleFilled(null, el);
    });
  }

  _toggleFilled(event = null, el = false) {
    const target = event ? event.currentTarget : el;
    const $target = $(target);
    const { value } = target;

    let isFilled = value.length > 0;

    try {
      isFilled = isFilled || $target.is(':-webkit-autofill');
      $target.toggleClass(this.filledClass, isFilled);
    } catch (e) {
      $target.toggleClass(this.filledClass, isFilled);
    }
  }
}
