export default class StaticUtilityBar {
  constructor(section) {
    this.mobileEl = section.el.querySelector('[data-utility-bar-mobile]');
    this.mobileNavContentEl = null;
    this.mobileElChildren = [];
    this.documentFragment = document.createDocumentFragment();
    this.mobileLayout = section.data.settings.mobile_layout;
    this._loadMobileNavItems = this._loadMobileNavItems.bind(this);

    if (this.mobileEl) {
      this._loadMobileNavItems();
      window.addEventListener('shopify:section:load', this._loadMobileNavItems);
    }
  }

  onSectionUnload() {
    window.removeEventListener('shopify:section:load', this._loadMobileNavItems);
    if (this.mobileNavContentEl) {
      this.mobileElChildren.forEach(child => this.mobileNavContentEl.removeChild(child));
    }
  }

  _loadMobileNavItems() {
    const inDom = this.mobileElChildren.some(child => document.body.contains(child));

    if (inDom) return;

    this.mobileNavContentEl = document.body.querySelector('[data-mobile-nav-content]');
    this.mobileElChildren = [];

    if (this.mobileNavContentEl) {
      const { children } = this.mobileEl;

      for (let i = 0; i < children.length; i++) {
        const clone = children[i].cloneNode(true);
        this.mobileElChildren.push(clone);
        this.documentFragment.appendChild(clone);
      }

      if (this.mobileLayout === 'below') {
        this.mobileNavContentEl.appendChild(this.documentFragment);
      } else {
        this.mobileNavContentEl.insertBefore(this.documentFragment, this.mobileNavContentEl.firstElementChild);
      }
    }
  }
}
