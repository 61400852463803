import $ from 'jquery';

/*
 * Function to convert any given latitude and longitude format to decimal degrees
 */
function getDecimalDegrees(
  firstComponent = 0,
  secondComponent = 0,
  thirdComponent = 0,
  fourthComponent = 0,
) {
  const directions = {
    N: 1,
    E: 1,
    S: -1,
    W: -1,
  };
  let decimalDegrees = 0.0;
  const components = [firstComponent, secondComponent, thirdComponent, fourthComponent];

  for (let i = 0; i < components.length; i++) {
    const component = components[i];

    if (component) {
      if (Number.isNaN(parseFloat(component))) {
        decimalDegrees *= directions[component];
      } else {
        decimalDegrees += parseFloat(component) / (60 ** i);
      }
    }
  }

  return decimalDegrees;
}

/*
 * By providing the ability to use a place name, or latitude and longitude coordinates
 * we give merchants, and our demo stores the option to bypass the Geocoding API.
 * The Geocoding API (https://developers.google.com/maps/documentation/geocoding/usage-and-billing) allows us
 * to take a place name and convert it to latitude and longitude expressed in decimal degrees.
 */
function getLatitudeLongitude(address) {
  const deferred = $.Deferred();
  // Degrees, Minutes and Seconds: DDD° MM' SS.S"
  const latLongDegreesMinutesSeconds = /^([0-9]{1,3})(?:°[ ]?| )([0-9]{1,2})(?:'[ ]?| )([0-9]{1,2}(?:\.[0-9]+)?)(?:"[ ]?| )?(N|E|S|W) ?([0-9]{1,3})(?:°[ ]?| )([0-9]{1,2})(?:'[ ]?| )([0-9]{1,2}(?:\.[0-9]+)?)(?:"[ ]?| )?(N|E|S|W)$/g;
  // Degrees and Decimal Minutes: DDD° MM.MMM'
  const latLongDegreesMinutes = /^([0-9]{1,3})(?:°[ ]?| )([0-9]{1,2}(?:\.[0-9]+)?)(?:'[ ]?| )?(N|E|S|W) ?([0-9]{1,3})(?:°[ ]?| )([0-9]{1,2}(?:\.[0-9]+)?)(?:'[ ]?| )?(N|E|S|W)$/g;
  // Decimal Degrees: DDD.DDDDD°
  const latLongDegrees = /^([-|+]?[0-9]{1,3}(?:\.[0-9]+)?)(?:°[ ]?| )?(N|E|S|W)?,? ?([-|+]?[0-9]{1,3}(?:\.[0-9]+)?)(?:°[ ]?| )?(N|E|S|W)?$/g;
  const latLongFormats = [latLongDegreesMinutesSeconds, latLongDegreesMinutes, latLongDegrees];
  const latLongMatches = latLongFormats.map(latLongFormat => address.match(latLongFormat));

  /*
   * Select the first latitude and longitude format that is matched.
   * Ordering:
   *   1. Degrees, minutes, and seconds,
   *   2. Degrees, and decimal minutes,
   *   3. Decimal degrees.
   */
  const latLongMatch = latLongMatches.reduce((accumulator, value, index) => {
    if (!accumulator && value) {
      const latLongResult = latLongFormats[index].exec(address);
      const lat = latLongResult.slice(1, latLongResult.length / 2 + 1);
      const lng = latLongResult.slice(latLongResult.length / 2 + 1, latLongResult.length);

      return {
        lat,
        lng,
      };
    }

    return accumulator;
  }, null);

  // If we've got a match on latitude and longitude, use that and avoid geocoding
  if (latLongMatch) {
    const latDecimalDegrees = getDecimalDegrees(...latLongMatch.lat);
    const longDecimalDegrees = getDecimalDegrees(...latLongMatch.lng);

    deferred.resolve({
      lat: latDecimalDegrees,
      lng: longDecimalDegrees,
    });
  } else {
    // Otherwise, geocode the assumed address
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({ address }, (results, status) => {
      if (status !== google.maps.GeocoderStatus.OK) {
        deferred.reject(status);
      }

      deferred.resolve(results[0].geometry.location);
    });
  }

  return deferred;
}

function getMapStyles(colors) {
  if (!colors) {
    return [];
  }

  return [
    { elementType: 'geometry', stylers: [{ color: colors.e }] },
    { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
    { elementType: 'labels.text.fill', stylers: [{ color: colors.a }] },
    { elementType: 'labels.text.stroke', stylers: [{ color: colors.e }] },
    { featureType: 'administrative', elementType: 'geometry', stylers: [{ visibility: 'off' }] },
    { featureType: 'administrative.country', stylers: [{ visibility: 'off' }] },
    { featureType: 'administrative.land_parcel', stylers: [{ visibility: 'off' }] },
    { featureType: 'administrative.neighborhood', stylers: [{ visibility: 'off' }] },
    { featureType: 'administrative.locality', stylers: [{ visibility: 'off' }] },
    { featureType: 'poi', stylers: [{ visibility: 'off' }] },
    { featureType: 'road', elementType: 'geometry.fill', stylers: [{ color: colors.d }] },
    { featureType: 'road', elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
    { featureType: 'road.arterial', elementType: 'geometry', stylers: [{ color: colors.c }] },
    { featureType: 'road.highway', elementType: 'geometry', stylers: [{ color: colors.b }] },
    { featureType: 'road.highway.controlled_access', stylers: [{ visibility: 'off' }] },
    { featureType: 'road.local', elementType: 'labels.text.fill', stylers: [{ color: colors.b }] },
    { featureType: 'road.local', elementType: 'labels.text.stroke', stylers: [{ color: colors.e }] },
    { featureType: 'transit', stylers: [{ visibility: 'off' }] },
    { featureType: 'water', elementType: 'geometry', stylers: [{ color: colors.f }] },
  ];
}

function createMap(options) {
  const deferred = $.Deferred();
  const {
    container,
    address,
    zoom,
    colors,
  } = options;

  getLatitudeLongitude(address)
    .done((latLong) => {
      const map = new google.maps.Map(container, {
        center: latLong,
        clickableIcons: false,
        disableDefaultUI: true,
        disableDoubleClickZoom: true,
        gestureHandling: 'none',
        keyboardShortcuts: false,
        maxZoom: zoom,
        minZoom: zoom,
        scrollWheel: false,
        styles: getMapStyles(colors),
        zoom,
        zoomControl: false,
      });

      new google.maps.Marker({
        clickable: false,
        map,
        position: map.getCenter(),
      });

      map.panBy(0, 0);
      deferred.resolve(map);
    })
    .fail((status) => {
      const usageLimits = 'https://developers.google.com/maps/faq#usagelimits';
      let errorMessage;

      switch (status) {
        case 'ZERO_RESULTS':
          errorMessage = `<p>Unable to find the address:</p> ${address}`;
          break;
        case 'OVER_QUERY_LIMIT':
          errorMessage = `
            <p>Unable to load Google Maps, you have reached your usage limit.</p>
            <p>
              Please visit
              <a href="${usageLimits}" target="_blank">${usageLimits}</a>
              for more details.
            </p>
          `;
          break;
        default:
          errorMessage = 'Unable to load Google Maps.';
          break;
      }

      deferred.reject(errorMessage);
    });

  return deferred;
}

function displayErrorInThemeEditor(container, errorMessage) {
  const isThemeEditor = window.Shopify && window.Shopify.designMode;

  if (!isThemeEditor) {
    return;
  }

  container.innerHTML = `<div class="map-error-message">${errorMessage}</div>`;
}

export {
  createMap,
  displayErrorInThemeEditor,
};
