import $ from 'jquery';
import $script from 'scriptjs';
import * as breakpoint from '@pixelunion/breakpoint';
import LazyLoader from '../helpers/LazyLoader';
import ProductQuickshop from './ProductQuickshop';
import AddToCartFlyout from './AddToCartFlyout';
import GridItemSwatches from './GridItemSwatches';

export default class ProductGridItem {
  constructor(options) {
    this.$el = options.$el;
    this.id = options.id;
    this.product = options.product;
    this.currentView = options.view;
    this.queryParams = options.queryParams || {};
    this.events = [];

    this._init = this._init.bind(this);

    if (options.lazy === true) {
      this.lazyLoader = new LazyLoader(this.$el[0], this._init);
    } else {
      this._init();
    }
  }

  _init() {
    this.productQuickshop = null;
    this.quickBuySettings = null;
    this.actionsOpen = false;

    this.$window = $(window);
    this.$html = $('html');
    this.$content = this.$el.find('[data-product-item-content]');
    this.$actions = this.$el.find('[data-product-actions]');
    this.swatchesEl = this.$el[0].querySelector('[data-swatches]');
    this.hasProductActions = this.$actions.length;

    this._addToCart = this._addToCart.bind(this);
    this._actionsToggle = this._actionsToggle.bind(this);

    const productQuickshopEl = this.$el.get(0).querySelector('[data-product-quickshop]');
    if (productQuickshopEl) {
      this.productQuickshop = new ProductQuickshop({
        el: productQuickshopEl,
        id: this.id,
        url: productQuickshopEl.dataset.productQuickshopUrl,
        hash: productQuickshopEl.dataset.productQuickshopHash,
        trigger: this.$el.find('.productitem--title a')[0],
        button: this.$el.find('[data-quickshop-trigger="full"]'),
      });
      this._openQuickShop = e => {
        e.preventDefault();
        this.productQuickshop.open(e.currentTarget.dataset.quickshopTrigger === 'full');
      };
      this._loadQuickShop = () => {
        this.productQuickshop.load();
      };
    }

    if (this.hasProductActions || (this.swatchesEl && this.swatchesEl.classList.contains('productitem--swatches-show-on-hover'))) {
      this.events.push(this.$content.on('mouseenter.product-item', this._actionsToggle));
      this.events.push(this.$content.on('mouseleave.product-item', this._actionsToggle));
      this.events.push(this.$content.on('focusin.product-item', this._actionsToggle));
    }

    if (this.hasProductActions) {
      // $scripts checks existence of script in header before attempting to inject
      $script($('[data-scripts]').data('shopify-api-url'), () => {
        this.events.push(this.$el.on('click.product-item', '[data-quick-buy]', this._addToCart));

        if (this.productQuickshop) {
          this.events.push(this.$el.on('focusin.product-item', '[data-quickshop-trigger]', this._loadQuickShop));
          this.events.push(this.$el.on('mouseover.product-item', '[data-quickshop-trigger]', this._loadQuickShop));
          this.events.push(this.$el.on('click.product-item', '[data-quickshop-trigger]', this._openQuickShop));
        }
      });
    }

    if (this.$el.find('[data-quick-buy]').length) {
      this._initQuickBuy();
    }

    this._objectFitPolyfill();

    if (this.swatchesEl) {
      this.swatches = new GridItemSwatches({
        el: this.$el[0],
        productQuickshop: this.productQuickshop,
        product: this.product,
      });
    }
  }

  handleViewChange(view) {
    this.currentView = view;
    if (this.swatches) {
      this.swatches.resize();
    }
  }

  _initQuickBuy() {
    try {
      this.quickBuySettings = JSON.parse(this.$el.find('[data-quick-buy-settings]').text());
    } catch (error) {
      return console.warn(`Quick buy: invalid QuickBuy data found. ${error.message}`);
    }
  }

  _isObjectFitAvailable() {
    return 'objectFit' in document.documentElement.style;
  }

  _objectFitPolyfill() {
    if (this._isObjectFitAvailable()) {
      return;
    }

    const $figure = $('[data-product-item-image]', this.$el);
    const featuredSrc = $('.productitem--image-primary', $figure).attr('src');
    const alternateSrc = $('.productitem--image-alternate', $figure).attr('src');

    $figure.addClass('product-item-image-no-objectfit');
    $figure.css('background-image', `url("${ featuredSrc }")`);

    if (alternateSrc) {
      this.$el.on('mouseover', () => {
        $figure.css('background-image', `url("${ alternateSrc }")`);
      });

      this.$el.on('mouseleave', () => {
        $figure.css('background-image', `url("${ featuredSrc }")`);
      });
    }
  }

  /**
   * Get height of element, and combined height of element + actions
   *
   * @returns {{heightBase, heightExpanded: *}}
   * @private
   */
  _getHeights() {
    let heightBase = 0;

    const parent = this.$el.parent();

    if (parent.hasClass('product-row--no-spacing') || parent.hasClass('productgrid--no-spacing')) {
      const imageHeight = this.$el.find('[data-product-page-link]').outerHeight(true);
      const infoHeight = this.$el.find('.productitem--info').outerHeight(true);
      const cardPadding = parseFloat(this.$content.css('padding-top').replace('px', ''))
        + parseFloat(this.$content.css('padding-bottom').replace('px', ''));

      heightBase = imageHeight + infoHeight + cardPadding;
    } else {
      heightBase = this.$el.height();
    }

    const actionsHeight = this.$el.find('[data-product-actions]').outerHeight(true) || 0;
    const swatchesHeight = this.$el.find('.productitem--swatches-show-on-hover').outerHeight(true) || 0;

    const heightExpanded = heightBase + actionsHeight + swatchesHeight;

    return {
      heightBase,
      heightExpanded,
    };
  }

  _actionsToggle(event) {
    if (!breakpoint.min('L')
      || document.documentElement.classList.contains('has-touch')
      || this.currentView === 'list-view') return;

    const $currentTarget = $(event.currentTarget);
    const $target = $(event.target);

    let openProductItem = false;

    // This function gets called on the element as well as the document focusin, so we want to
    // be extra careful that we are inside the product card in question. We want the product card
    // to close if another product card has received focus.
    const productHasFocus = this.$el.is($currentTarget)
      || this.$el.is($target)
      || this.$el.is($target.parents('.productgrid--item').first())
      || (event.type === 'focusin' && $target[0].contains(this.$el[0]));

    if (event.type === 'mouseenter' || event.type === 'mouseleave') {
      openProductItem = event.type === 'mouseenter';
    } else if (productHasFocus) {
      openProductItem = true;
    }

    if (openProductItem) {
      this._showActions();
    } else {
      this._hideActions();
    }
  }

  _showActions() {
    if (this.actionsOpen) { return; }

    const { heightBase, heightExpanded } = this._getHeights();

    // Lock heights to prevent jumping
    this.$el.css('height', heightBase);
    this.$content.css('height', heightBase);

    // Store height for when hovering out
    this.$el.data('base-height', heightBase);

    // Start animation, and transition height to expanded height
    this.$el
      .revealer('show')
      .one('revealer-animating.product-item', () => {
        this.$content.css('height', heightExpanded);
      });

    document.addEventListener('focusin', this._actionsToggle);

    this.actionsOpen = true;
  }

  _hideActions() {
    const height = this.$el.data('base-height');

    /*
      - Transition buttons up, using base height for animation
      - Remove heights after animation is complete in case of resize
     */
    this.$el
      .revealer('hide')
      .one('revealer-animating', () => {
        this.$el.off('revealer-animating.product-item');
        this.$el.css({ height });
        this.$content.css({ height });
      }).one('revealer-hide', () => {
        this.$el
          .off('revealer-hide.product-item')
          .css('height', '');
        this.$content.css('height', '');
      });

    document.removeEventListener('focusin', this._actionsToggle);

    this.actionsOpen = false;
  }

  _addToCart(event) {
    event.preventDefault();
    const $atcButton = $(event.currentTarget);
    const variantID = $atcButton.attr('data-variant-id');

    const formData = [
      {
        'name': 'id',
        'value': variantID,
      },
      {
        'name': 'quantity',
        'value': 1,
      },
    ];

    const options = {
      $atcButton,
      settings: {
        moneyFormat: this.quickBuySettings.money_format,
        cartRedirection: this.quickBuySettings.cart_redirection,
      },
    };

    AddToCartFlyout.init(formData, this.product, options);
  }

  unload() {
    this.events.forEach($el => $el.off('.product-item'));

    if (this.productQuickshop) {
      this.productQuickshop.unload();
    }

    document.removeEventListener('focusin', this._actionsToggle);

    if (this.swatches) {
      this.swatches.unload();
    }
    this.lazyLoader.unload();
  }
}
