// jQuery plugins
import $ from './globals/jquery'; // eslint-disable-line
import '@pixelunion/jquery-trend';
import '@pixelunion/jquery-revealer';

import scriptjs from 'scriptjs';
import rimg from '@pixelunion/rimg-shopify';

// Template imports
import Account from './templates/Account';
import Contact from './templates/Contact';
import Page from './templates/Page';
import GiftCard from './templates/GiftCard';

// Section imports
import Sections from './Sections';
import DynamicBlogPosts from './sections/DynamicBlogPosts';
import DynamicCollectionList from './sections/DynamicCollectionList';
import DynamicFeaturedCollection from './sections/DynamicFeaturedCollection';
import DynamicHighlightsBanners from './sections/DynamicHighlightsBanners';
import DynamicMap from './sections/DynamicMap';
import DynamicMenuList from './sections/DynamicMenuList';
import DynamicNewsletter from './sections/DynamicNewsletter';
import DynamicPromoBlocks from './sections/DynamicPromoBlocks';
import DynamicRichText from './sections/DynamicRichText';
import DynamicSearch from './sections/DynamicSearch';
import DynamicTestimonials from './sections/DynamicTestimonials';
import DynamicTwitterFeed from './sections/DynamicTwitterFeed';
import DynamicVideo from './sections/DynamicVideo';
import StaticAnnouncement from './sections/StaticAnnouncement';
import StaticArticle from './sections/StaticArticle';
import StaticBlog from './sections/StaticBlog';
import StaticCart from './sections/StaticCart';
import StaticCollection from './sections/StaticCollection';
import StaticCollectionMinimal from './sections/StaticCollectionMinimal';
import StaticPassword from './sections/StaticPassword';
import StaticProduct from './sections/StaticProduct';
import StaticProductRecommendations from './sections/StaticProductRecommendations';
import StaticRecentlyViewed from './sections/StaticRecentlyViewed';
import StaticSearch from './sections/StaticSearch';
import StaticSectionFooter from './sections/StaticSectionFooter';
import StaticSectionHeader from './sections/StaticSectionHeader';
import StaticSlideshow from './sections/StaticSlideshow';
import StaticUtilityBar from './sections/StaticUtilityBar';

import polyfillUrls from './checkPolyfills';

import ContainSwatchTooltips from './helpers/ContainSwatchTooltips';
import flickityTouchFix from './helpers/FlickityTouchFix';

const initTheme = () => {
  rimg.init('[data-rimg="lazy"]', { round: 1 });

  const sections = new Sections();
  sections.register('dynamic-blog-posts', section => new DynamicBlogPosts(section));
  sections.register('dynamic-collection-list', section => new DynamicCollectionList(section));
  sections.register('dynamic-featured-collection', section => new DynamicFeaturedCollection(section));
  sections.register('dynamic-featured-product', section => new StaticProduct(section));
  sections.register('dynamic-highlights-banners', section => new DynamicHighlightsBanners(section));
  sections.register('dynamic-map', section => new DynamicMap(section));
  sections.register('dynamic-menu-list', section => new DynamicMenuList(section));
  sections.register('dynamic-newsletter', section => new DynamicNewsletter(section));
  sections.register('dynamic-promo-grid', section => new DynamicPromoBlocks(section));
  sections.register('dynamic-promo-mosaic', section => new DynamicPromoBlocks(section));
  sections.register('dynamic-rich-text', section => new DynamicRichText(section));
  sections.register('dynamic-search', section => new DynamicSearch(section));
  sections.register('dynamic-testimonials', section => new DynamicTestimonials(section));
  sections.register('dynamic-twitter-feed', section => new DynamicTwitterFeed(section));
  sections.register('dynamic-video', section => new DynamicVideo(section));
  sections.register('static-announcement', section => new StaticAnnouncement(section));
  sections.register('static-article', section => new StaticArticle(section));
  sections.register('static-blog', section => new StaticBlog(section));
  sections.register('static-cart', section => new StaticCart(section));
  sections.register('static-collection', section => new StaticCollection(section));
  sections.register('static-collection-minimal', section => new StaticCollectionMinimal(section));
  sections.register('static-footer', section => new StaticSectionFooter(section));
  sections.register('static-header', section => new StaticSectionHeader(section));
  sections.register('static-password', section => new StaticPassword(section));
  sections.register('static-product', section => new StaticProduct(section));
  sections.register('static-product-recommendations', section => new StaticProductRecommendations(section));
  sections.register('static-recently-viewed', section => new StaticRecentlyViewed(section));
  sections.register('static-search', section => new StaticSearch(section));
  sections.register('static-slideshow', section => new StaticSlideshow(section));
  sections.register('static-utility-bar', section => new StaticUtilityBar(section));

  new ContainSwatchTooltips();

  if ($('body.giftcard').length) {
    new GiftCard();
  }

  new Account();
  new Contact();
  new Page();

  flickityTouchFix();
};

if (polyfillUrls.length) {
  scriptjs(polyfillUrls, initTheme);
} else {
  initTheme();
}
