import $ from 'jquery';
import debounce from 'just-debounce';
import Flickity from 'flickity';
import * as breakpoint from '@pixelunion/breakpoint';

export default class DynamicHighlightsBanners {
  constructor(section) {
    this.$el = $(section.el);
    this.$window = $(window);

    this.$carousel = this.$el.find('.highlights-banners');
    this.slides = '.highlights-banners-block';

    this.flickity = null;
    this.flickityOptions = null;

    if (this.$carousel.find(this.slides).length > 1) {
      this._initSlider();
    }
  }

  _initSlider() {
    this.flickityOptions = {
      autoPlay: 0,
      accessibility: true,
      cellAlign: 'center',
      cellSelector: this.slides,
      pageDots: false,
      prevNextButtons: false,
      contain: true,
    };

    this._bindFlickity();
  }

  onSectionUnload() {
    this._destroyFlickity();
    this.$window.off('.twitter');
  }

  _bindFlickity() {
    if (breakpoint.min('L') === false && this.flickity === null) {
      this.flickity = new Flickity(this.$carousel[0], this.flickityOptions);
    }

    this.$window.on('resize', debounce(() => {
      if (breakpoint.min('L') === false && this.flickity === null) {
        this._bindFlickity();
      } else if (breakpoint.min('L') && this.flickity) {
        this._destroyFlickity();
      }
    }));
  }

  _destroyFlickity() {
    if (!this.flickity) {
      return;
    }

    this.flickity.destroy();
    this.flickity = null;
  }
}
