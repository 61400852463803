function setTooltipOffset(target) {
  const margin = 10;
  const { innerWidth } = window;
  const { x, width } = target.getBoundingClientRect();
  const tooltipWidth = parseFloat(window.getComputedStyle(target, ':after').width);

  const required = tooltipWidth * 0.5;
  let available = 0;
  let offset = 0;

  if (x < innerWidth * 0.5) {
    // Closer to left side of screen
    available = x + width * 0.5 - margin;
    offset = required - available;
  } else {
    available = innerWidth - x - width * 0.5 - margin;
    offset = available - required;
  }

  target.style.setProperty('--swatch-tooltip-offset', `${required >= available ? offset : 0}px`);
}

function handleMouseover({ target }) {
  // We need to check for dataset, because IE11 doesn't support
  // the dataset property for certain elements like svgs
  if (target.dataset && !target.dataset.swatchTooltip) return;

  window.requestAnimationFrame(() => setTooltipOffset(target));
}

export default class ContainSwatchTooltips {
  constructor() {
    window.addEventListener('mouseover', handleMouseover);
  }

  unload() {
    window.removeEventListener('mouseover', handleMouseover);
  }
}
