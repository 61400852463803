import $ from 'jquery';
import RichText from '../components/RichText';

export default class StaticBlog {
  constructor(section) {
    this.$el = $(section.el);

    this.richText = new RichText(section.el);
  }

  onSectionUnload() {
    this.richText.unload();
  }
}
