/**
 * Shows a list item component when result is an article, or a page
 *
 * @param contentItem
 * @returns {string}
 * @constructor
 */
function ContentItem(contentItem) {
  return `
  <li class="search-flydown--content-item">
    <a href="${contentItem.url}">${contentItem.title}</a>
  </li>
  `;
}

/**
 * Shows a header for the article and page results
 *
 * @param context
 * @returns {string}
 * @constructor
 */
function ContentResultsHeader(context) {
  return `
    <h5 class="search-flydown--content-title">
      ${context.content_results.title}
    </h5>
  `;
}

/**
 * Shows a list of results from articles, or pages
 *
 * @param props
 * @returns {jQuery|HTMLElement}
 * @constructor
 */
function ContentResults(props) {
  const { content, context } = props;
  const $title = ContentResultsHeader(context);
  const contentItems = content.map(contentItem => ContentItem(contentItem));

  return $(`
    <div class="search-flydown--content-items">
      ${$title}
      <ul class="search-flydown--content-items-list">
        ${contentItems.join('')}
      </ul>
    </div>
  `);
}

/**
 * Shows a message when there are no article or page results
 *
 * @param props
 * @returns {jQuery|HTMLElement}
 * @constructor
 */
function ContentNoResults(props) {
  const { context } = props;
  const $title = ContentResultsHeader(context);

  return $(`
    <div class="search-flydown--content-items">
      ${$title}
      <p class="search-flydown--content-items-none">
        ${context.content_results.no_results}
      </p>
    </div>
  `);
}

/**
 * Shows the product's compare_at_price
 *
 * @param price
 * @returns {string}
 * @constructor
 */
function ProductPriceCompareAt(price) {
  return `
  <span class="search-flydown--product-price--compare-at">
    <span class="money">${price.compare_at_price}</span>
  </span>
  `;
}

/**
 * Shows the product's price
 *
 * @param price
 * @returns {string}
 * @constructor
 */
function ProductPriceMain(price) {
  return `
  <span class="search-flydown--product-price--main">
    <span class="money">${price.price}</span>
  </span>
  `;
}

/**
 * Shows the product's prices
 *
 * @param props
 * @returns {string}
 * @constructor
 */
function ProductPrice(props) {
  const { price, onSale, consistentSaved } = props;
  const $compareAt = onSale && consistentSaved ? ProductPriceCompareAt(price) : '';
  const $price = ProductPriceMain(price);

  return `
    <span class="search-flydown--product-price ${onSale ? 'search-flydown--product-price-has-sale' : ''}">
      ${$price}
      ${$compareAt}
    </span>
  `;
}

/**
 * Shows text indicating the number of product colours
 *
 * @param swatches
 * @returns {string}
 * @constructor
 */
function ProductSwatches(swatches) {
  if (swatches.length === 0) {
    return '';
  }

  return `<span class="search-flydown--swatches">${swatches}</span>`;
}

/**
 * Shows a product's main content
 *
 * @param product
 * @returns {string}
 * @constructor
 */
function ProductContent(product) {
  const $price = ProductPrice({
    price: product.price,
    onSale: product.on_sale,
    consistentSaved: product.consistent_saved,
  });
  const $swatches = ProductSwatches(product.swatches);

  return `
    <div class="search-flydown--product-text">
      <span class="search-flydown--product-title">
        ${product.title}
      </span>
      ${$price}
      ${$swatches}
    </div>
  `;
}

/**
 * Shows a product's image
 *
 * @param image
 * @returns {string}
 * @constructor
 */
function ProductImage(image) {
  return `
    <div class="search-flydown--product-image">
      ${image}
    </div>
  `;
}

/**
 * Shows a product list item component when result is a product
 *
 * @param product
 * @param enableImages
 * @returns {string}
 * @constructor
 */
function ProductItem(product, enableImages) {
  const $image = enableImages ? ProductImage(product.image) : '';
  const $content = ProductContent(product);

  return `
    <a class="search-flydown--product search-flydown--product" href="${product.url}">
      ${$image}
      ${$content}
    </a>
  `;
}

/**
 * Shows a list of product results
 *
 * @param props
 * @returns {jQuery|HTMLElement}
 * @constructor
 */
function ProductResults(props) {
  const { products, enableImages } = props;
  const productItems = products.map(product => ProductItem(product, enableImages));

  return $(`
    <div class="search-flydown--product-items">
      ${productItems.join('')}
    </div>
  `);
}

/**
 * Show a message when no product results are found
 *
 * @param props
 * @returns {jQuery|HTMLElement}
 * @constructor
 */
function ProductNoResults(props) {
  const { context, terms } = props;

  return $(`
    <div class="search-flydown--product-items--none" data-live-search-no-products>
      <h5>
        ${context.no_results_products.title.replace('*terms*', terms)}
      </h5>
      <p>
        ${context.no_results_products.message}
      </p>
      <p>
        <a class="search-flydown--continue" href="${window.Theme.routes.all_products_collection_url}">
          ${context.view_all_products}
        </a>
      </p>
    </div>
  `);
}

/**
 * Show's the Results footer, directing customers to all products
 *
 * @param props
 * @returns {jQuery|HTMLElement}
 * @constructor
 */
function ResultsFooter(props) {
  const { context, terms, type } = props;

  return $(`
    <div class="search-flydown--footer">
      <a class="search-flydown--continue" href="${window.Theme.routes.search_url}?q=${terms}&type=${type}">
        ${context.view_all_results}
      </a>
    </div>
  `);
}

export {
  ContentResults,
  ContentNoResults,
  ProductResults,
  ProductNoResults,
  ResultsFooter,
};
