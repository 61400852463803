import $ from 'jquery';
import * as breakpoint from '@pixelunion/breakpoint';

import Accordion from '../Accordion';

export default class DynamicMenuList {
  constructor(section) {
    this.$el = $(section.el);
    this.context = section.data.context;
    this.Accordion = new Accordion(this.$el);

    // Handle Accordion interaction when window size changes
    this.layoutHandler = this.onBreakpointChange.bind(this);
    breakpoint.onChange(this.layoutHandler);

    this._bindEvents();
  }

  _bindEvents() {
    this.$el.on('click.menu-list', '[data-accordion-trigger]', event => {
      event.preventDefault();
      this._toggleAccordion($(event.currentTarget).parent());
    });

    this.$el.on('click.menu-list', '[data-menulist-toggle]', event => {
      event.preventDefault();
      this._toggleList(event.currentTarget);
    });
  }

  onSectionUnload() {
    this.$el.off('.menu-list');

    delete this.Accordion;
  }

  onSectionBlockSelect(block) {
    this._toggleAccordion($(block.el), true);
  }

  onSectionBlockDeselect(block) {
    this._toggleAccordion($(block.el), false);
  }

  _toggleAccordion($block) {
    if (breakpoint.min('L')) return;

    this.Accordion.toggle($block);
  }

  _toggleList(el) {
    const $trigger = $(el);
    const $items = $trigger.parent().siblings('[data-hidden-default]');
    const siblingsVisible = $trigger.data('menulist-toggle');

    $items.toggleClass('menulist--menu-item-hidden', siblingsVisible);
    $trigger
      .data('menulist-toggle', !siblingsVisible)
      .text(!siblingsVisible ? this.context.see_less : this.context.see_more);
  }

  onBreakpointChange(breakpoints) {
    if (breakpoint.min('L')) {
      this.Accordion.openAll();
    } else if (breakpoints.previous.value === 'L' && !breakpoint.min('L')) {
      this.Accordion.closeOpen();
    }
  }
}
