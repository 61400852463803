import $script from 'scriptjs';

import LiveSearch from '../search/LiveSearch';
import SearchForm from '../search/SearchForm';

export default class Header {
  constructor(options, settings) {
    this.$scripts = options.$scripts;
    this.section = options.section;
    this.header = this.section.querySelector('.site-header');
    this.searchBar = this.header.querySelector('[data-live-search]');

    this.postMessage = options.postMessage;

    this.settings = settings;

    this.stickyClass = 'site-header--sticky';

    if (this.settings.sticky_header) {
      this.section.classList.add(this.stickyClass);
    }

    // Setup search form/live search
    this.liveSearch = null;
    this.searchForm = null;

    if (this.settings.live_search.enable) {
      $script(this.$scripts.data('shopify-api-url'), () => {
        this.liveSearch = new LiveSearch({
          el: this.searchBar,
          header: this.header,
        }, this.settings.live_search);
      });
    } else {
      this.searchForm = new SearchForm(this.searchBar);
    }
  }

  unload() {
    this.section.classList.remove(this.stickyClass);

    if (this.settings.live_search.enable) {
      this.liveSearch.unload();
    } else {
      this.searchForm.unload();
    }
  }

  onSectionBlockSelect() {
    // Do nothing
  }

  onSectionBlockDeselect() {
    // Do nothing
  }

  sectionDeselect() {
    // Do nothing
  }
}
