import debounce from 'just-debounce';
import Flickity from 'flickity';
import * as breakpoint from '@pixelunion/breakpoint';
import $ from 'jquery';

export default class StaticSlideshow {
  constructor(section) {
    this.el = section.el;
    this.settings = section.data;
    this.$window = $(window);

    this.carousel = this.el.querySelector('.slideshow');
    this.slides = '.slideshow-slide';
    this.container = this.el.querySelector('.slideshow-height-fullscreen');
    this.isFullscreen = !!this.container;
    this.selectBlockOnLoad = true;
    this.continueAutoplay = true;
    this.stopAutoplay = false;
    this.autoplayTimeout = 0;
    this.$firstSlideImage = null;

    this.flickity = null;
    this.flickityOptions = null;

    if (this.carousel && this.carousel.querySelectorAll(this.slides).length > 1) {
      this._initSlider();
    }
  }

  _initSlider() {
    this.flickityOptions = {
      autoPlay: this.settings.enable_autoplay ? this.settings.autoplay_duration * 1000 : false,
      setGallerySize: !this.isFullscreen,
      pauseAutoPlayOnHover: false,
      accessibility: true,
      adaptiveHeight: true,
      cellAlign: 'left',
      cellSelector: this.slides,
      contain: true,
      wrapAround: true,
      arrowShape: {
        x0: 10,
        x1: 60,
        y1: 50,
        x2: 65,
        y2: 45,
        x3: 20,
      },
    };

    this.$firstSlideImage = $(this.slides, this.carousel).find('img[data-rimg]').first();

    const initFlickity = () => {
      this.flickity = new Flickity(this.carousel, this.flickityOptions);
      this._bindEvents();
    };

    // If image isn't lazy loaded, it may load before
    // this JS runs, so we need to check
    if (this.$firstSlideImage.length && !this.$firstSlideImage[0].complete) {
      const loadEventName = this.settings.lazyLoad ? 'rimg:load' : 'load';
      this.$firstSlideImage.one(loadEventName, initFlickity);
    } else {
      initFlickity();
    }
  }

  _bindEvents() {
    this._slideshowHeight();
    this._positionDots();
    this._changeArrrowColors();

    this.$window.on('resize.slideshow', debounce(() => {
      this._slideshowHeight();
      this._positionDots();
      this._changeArrrowColors();
    }));

    this.flickity.on('select', () => {
      this._slideshowHeight();
      this._positionDots();
      this._changeArrrowColors();

      this.selectBlockOnLoad = false;

      if (this.settings.enable_autoplay && !this.stopAutoplay) {
        this.flickity.pausePlayer();

        if (this.continueAutoplay) {
          this.autoplayTimeout = 0;
        } else {
          this.autoplayTimeout = 10000;
        }

        this.continueAutoplay = true;

        setTimeout(() => {
          this.flickity.playPlayer();
        }, this.autoplayTimeout);
      }
    });

    this.flickity.on('dragStart', () => {
      this.continueAutoplay = false;
    });

    this.stopAutoplaying = () => {
      this.stopAutoplay = true;
      this.flickity.stopPlayer();
    };

    // For users that need to use a keyboard for navigation, we want to pause the player
    // when they are focused on it, and we also want to slide to the slide their focus is on.
    this.slideElements = this.el.querySelectorAll(this.slides);
    this.slideElements.forEach((slide) => {
      slide.addEventListener('focusin', () => {
        this.stopAutoplaying();
        const $slides = $(this.slides);
        const $slide = $(slide);
        const slideIndex = $slides.index($slide);
        this.flickity.select(slideIndex, false, true);
      });
    });

    // For screen readers, we will add a live region that is hidden to users,
    // but is read when clicking the nagivation buttons
    this.liveregion = document.createElement('div');
    this.liveregion.setAttribute('aria-live', 'polite');
    this.liveregion.setAttribute('class', 'live-region visually-hidden');
    this.el.appendChild(this.liveregion);

    this.handleManualSlideChange = () => {
      this.stopAutoplaying();
      this.liveregion.textContent = `Slide autoplay stopped.
        On slide ${this.flickity.selectedIndex + 1} of ${this.flickity.slides.length}`;
    };

    // stop autoplaying if a user has interacted with the slideshow next/previous buttons
    this.el.querySelector('.flickity-prev-next-button.next')
      .addEventListener('click', this.handleManualSlideChange);
    this.el.querySelector('.flickity-prev-next-button.previous')
      .addEventListener('click', this.handleManualSlideChange);
  }

  _positionDots() {
    const pageDots = this.el.querySelector('.flickity-page-dots');
    let top = 'auto';

    if (breakpoint.max('XS') && !this.isFullscreen) {
      const content = this.flickity.selectedElement.querySelector('.slideshow-slide-content');
      const height = this.flickity.selectedElement.querySelector('.slideshow-background').offsetHeight;
      const dotGutter = 5;

      if (content) {
        top = `${height + dotGutter}px`;
      } else {
        const pageDotsHeight = pageDots.offsetHeight;
        const dotBottomGutter = 20;

        // If no content, and mobile, offset by height of dots, and the bottom gutter
        top = `${height - (pageDotsHeight + dotBottomGutter)}px`;
      }
    }

    pageDots.style.top = top;
  }

  _changeArrrowColors() {
    let currentColor = this.flickity.selectedElement.querySelector('.slideshow-background').dataset;
    const dots = this.el.querySelectorAll('.flickity-page-dots .dot');

    if (breakpoint.max('XS') && !this.isFullscreen) {
      currentColor = currentColor.themecolor;
    } else {
      currentColor = currentColor.slidecolor;
    }

    this.el.querySelector('.flickity-prev-next-button.previous svg').style.fill = currentColor;
    this.el.querySelector('.flickity-prev-next-button.next svg').style.fill = currentColor;

    for (let i = 0; i < dots.length; i++) {
      dots[i].style.background = currentColor;
    }
  }

  _slideshowHeight() {
    if (this.isFullscreen) {
      let offsetHeight = document.querySelector('[data-site-header]').getBoundingClientRect().height;
      const announcement = document.querySelector('.site-announcement');

      if (announcement) {
        offsetHeight += announcement.getBoundingClientRect().height;
      }

      this.el.querySelector('.flickity-viewport').style.height = `calc(100vh - ${offsetHeight}px)`;
      this.container.style.height = `calc(100vh - ${offsetHeight}px)`;
    }
  }

  onSectionUnload() {
    this._destroyFlickity();
    this.$window.off('.slideshow');
    if (this.$firstSlideImage) {
      this.$firstSlideImage.off('rimg:load');
    }
  }

  _destroyFlickity() {
    if (!this.flickity) {
      return;
    }

    this.flickity.destroy();
  }

  onSectionBlockSelect(block) {
    if (!this.flickity) return;

    setTimeout(() => {
      this.flickity.resize();
    }, 100);

    const $slides = $(this.slides);
    const $slide = $(block.el);
    const slideIndex = $slides.index($slide);

    if (this.settings.enable_autoplay && this.flickity) {
      this.stopAutoplay = true;
      this.flickity.pausePlayer();
    }

    if (!$slide.hasClass('is-selected') && !this.selectBlockOnLoad) {
      this.flickity.select(slideIndex, false, false);
    } else if (this.selectBlockOnLoad) {
      this.flickity.select(slideIndex, false, true);
    }
  }

  onSectionBlockDeselect() {
    if (this.flickity && this.settings.enable_autoplay) {
      this.stopAutoplay = false;
      this.flickity.playPlayer();
    }
  }
}
