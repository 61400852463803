import NavDesktopMenu from './NavDesktopMenu';

export default class NavDesktopParent {
  constructor(el, options) {
    this.listitem = el;
    this.link = null;
    this.submenu = null;
    this.isOpen = false;
    this.menu = null;
    this.closeSiblings = options.closeSiblings;

    const { children } = this.listitem;

    for (let i = 0; i < children.length; i++) {
      if (children[i].classList.contains('navmenu-link')) {
        this.link = children[i];
      } else if (children[i].classList.contains('navmenu-submenu')) {
        this.submenu = children[i];
      }
    }

    this.open = () => {
      this._open();
    };
    this.close = () => {
      this._close();
    };
    this.closeEsc = e => {
      if (e.key === 'Escape') {
        this.link.focus();
        this.close();
      }
    };

    this.timer = null;
    this.mouseover = () => {
      this.open();
      clearTimeout(this.timer);
    };
    this.mouseout = () => {
      this.timer = setTimeout(this.close, 400);
    };

    this.click = e => {
      e.stopPropagation();
      // if already open, continue to link destination
      if (!e.target.classList.contains('navmenu-link-parent') || this.isOpen) { return; }
      e.preventDefault();
      this.open();
    };
    this.focusin = e => {
      e.stopPropagation();
      if (e.target.classList.contains('navmenu-link-parent')) {
        this.closeSiblings(this);
      }
    };

    this.listitem.addEventListener('mouseover', this.mouseover);
    this.listitem.addEventListener('mouseout', this.mouseout);
    this.listitem.addEventListener('touchend', this.click);
    this.listitem.addEventListener('click', this.click);
    this.listitem.addEventListener('focusin', this.focusin);

    document.body.addEventListener('click', this.close);
    document.body.addEventListener('focusin', this.close);
  }

  _open() {
    this.closeSiblings(this);
    window.addEventListener('keydown', this.closeEsc);

    if (!this.listitem.classList.contains('open')) {
      this.listitem.classList.add('open');
      this.link.setAttribute('aria-expanded', true);

      this.submenuEndInAnimation = () => {
        this.submenu.classList.remove('animating-in');
        this.submenu.removeEventListener('animationend', this.submenuEndInAnimation);
      };

      this.submenu.addEventListener('animationend', this.submenuEndInAnimation);
      this.submenu.classList.add('animating-in');
    }

    if (!this.menu) {
      this.menu = new NavDesktopMenu(this.submenu);
    }

    // In some cases, we need to reposition the meganav to fill the whole viewport width
    if (this.submenu.classList.contains('navmenu-meganav')) {
      const submenuRect = this.submenu.getBoundingClientRect();

      if (submenuRect.left > 0) {
        this.submenu.style.left = `-${submenuRect.left}px`;
      }
    }

    // Determine if submenu needs to use alternate side for dropdown
    // Only applicable for regular drop down menus, not meganav
    if (!this.submenu.classList.contains('navmenu-meganav')) {
      const bounds = this.submenu.getBoundingClientRect();
      if (bounds.right > document.documentElement.clientWidth) {
        this.listitem.classList.add('alternate-drop');
      }
    }

    this.isOpen = true;
  }

  _close() {
    if (this.listitem.classList.contains('open')
      && !this.submenu.classList.contains('animating-out')) {
      this.submenuEndOutAnimation = () => {
        this.listitem.classList.remove('open');
        this.listitem.classList.remove('alternate-drop');
        this.link.setAttribute('aria-expanded', false);
        this.submenu.classList.remove('animating-out');
        this.submenu.removeEventListener('animationend', this.submenuEndOutAnimation);
      };

      this.submenu.addEventListener('animationend', this.submenuEndOutAnimation);
      this.submenu.classList.add('animating-out');
    }

    if (this.menu) {
      this.menu.unload();
      this.menu = null;
    }

    window.removeEventListener('keydown', this.closeEsc);

    this.isOpen = false;
  }

  openMeganavBlock(meganav) {
    if (this.submenu === meganav) {
      this.submenu.classList.add('meganav-editing-block');
    }
  }

  unload() {
    this.close();
    this.listitem.removeEventListener('mouseover', this.mouseover);
    this.listitem.removeEventListener('mouseout', this.mouseout);
    this.listitem.removeEventListener('touchend', this.click);
    this.listitem.removeEventListener('click', this.click);
    this.listitem.removeEventListener('focusin', this.focusin);

    window.removeEventListener('keydown', this.closeEsc);
    document.body.removeEventListener('click', this.bodyClose);
    document.body.removeEventListener('focusin', this.focusInClose);
  }
}
