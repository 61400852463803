/**
 * Allows a callback to be run once, when a target intersects the viewport.
 * @constructor
 * @param {HTMLElement} target Element to track
 * @param {Function} callback Function to execute when target enters viewport (only executed once)
 * @param {Object} [options] options with which to construct the IntersectionObserver
 * @param {string} [options.rootMargin='30%'] A string which specifies a set of offsets to add to the root's bounding_box when calculating intersections.
 * @param {number} [options.threshold=0] Ratio of intersection required to trigger callback
 */
export default class LazyLoader {
  constructor(target, callback, options) {
    const defaultOptions = {
      rootMargin: '30%',
      threshold: 0,
    };

    this.callback = callback;
    this._runCallback = this._runCallback.bind(this);

    // This is to solve an issue with ItersectionObserver where the events were not firing for
    // items that were already in the viewport on Chrome 71. It could have also been an issue that
    // was combined with Flickity. Calling the callback for items already in the viewport seemed
    // to fix the issue.
    if (this._isInViewport(target)) {
      this.callback();
    } else {
      this.observer = new IntersectionObserver(this._runCallback, { ...defaultOptions, ...options });
      this.observer.observe(target);
    }
  }

  _isInViewport(target) {
    const bounding = target.getBoundingClientRect();
    return bounding.top >= 0
      && bounding.left >= 0
      && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
      && bounding.right <= (window.innerWidth || document.documentElement.clientWidth);
  }

  /**
   * Runs the callback if first entry becomes intersecting, then unloads the LazyLoader
   * @_runCallback
   * @param {IntersectionObserverEntry[]} entries Entry to check - all but the
   * first element will be ignored.
   */
  _runCallback(entries) {
    // do nothing unless target moved into state of intersection
    if (entries[0].isIntersecting === true) {
      this.unload();
      this.callback();
    }
  }

  /**
   * Disconnects IntersectionObserver if active
   * @unload
   */
  unload() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
