export default class PaymentTerms {
  constructor(el) {
    this._el = el;
    this._reference = this._el.querySelector('[data-payment-terms-reference] shopify-payment-terms');

    if (!this._reference) return;

    this._target = this._el.querySelector('[data-payment-terms-target]');

    this._input = document.createElement('input');
    this._input.name = 'id';
    this._input.type = 'hidden';

    this._target.appendChild(this._input);
    this._target.appendChild(this._reference);

    this._target.style.display = null;
  }

  update(variantId) {
    if (!this._reference) return;
    this._input.value = variantId;
    this._input.dispatchEvent(new Event('change', { bubbles: true }));
  }
}
