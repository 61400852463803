import ProductCTA from './ProductCTA';
import Details from './Details';

export default class Shortcodes {
  constructor(el) {
    this.shortcodes = [];

    this.shortcodes.concat(ProductCTA.getShortcodes(el));
    this.shortcodes.concat(Details.getShortcodes(el));
  }

  unload() {
    this.shortcodes = this.shortcodes.filter(shortcode => {
      shortcode.unload();
      return false;
    });
  }
}
