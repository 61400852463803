import fitvids from 'fitvids';
import GroupedContent from '@pixelunion/grouped-content';

export default class RichText {
  constructor(el) {
    this.el = el;
    this._initExternalLinks();
    this.groupedContent = null;

    if (el) {
      this.groupedContent = new GroupedContent(el, {
        layout: 'tabs',
        intelliparse: false,
      });

      fitvids('.rte');
    }
  }

  unload() {
    if (this.groupedContent) {
      this.groupedContent.unload();
    }
  }

  /**
   * Open links within an RTE field in a new window if they point to external domains
   *
   * @private
   */
  _initExternalLinks() {
    this.el.querySelectorAll('a[href^="http"]').forEach(anchor => {
      if (anchor.href.indexOf(location.hostname) === -1) {
        anchor.setAttribute('target', '_blank');
      }
    });
  }
}
