import $ from 'jquery';
import debounce from 'just-debounce';
import VanillaModal from 'vanilla-modal';
import {
  trapFocus,
  removeTrapFocus,
} from '@shopify/theme-a11y';

export default class Modal {
  constructor(callbacks = {}) {
    this.$body = $(document.body);
    this.$window = $(window);

    this.modal = null;
    this.$modal = $('[data-modal-container]');
    this.$modalInner = this.$modal.find('[data-modal-inner]');

    this.trigger = null;

    // Extend default vanilla-modal callbacks back to instantiator of Modal
    this.defaultCallbacks = {
      onOpen: () => {},
      onClose: () => {},
      onBeforeOpen: () => {},
      onBeforeClose: () => {},
    };

    this.callbacks = $.extend({}, this.defaultCallbacks, callbacks);

    this._onOpen = this._onOpen.bind(this);
    this._onBeforeOpen = this._onBeforeOpen.bind(this);
    this._onClose = this._onClose.bind(this);
    this._onBeforeClose = this._onBeforeClose.bind(this);
    this._closeEsc = this._closeEsc.bind(this);

    this.position = this.position.bind(this);

    this.modalOptions = {
      loadClass: '',
      class: 'modal-loaded',
      onOpen: this._onOpen,
      onClose: this._onClose,
      onBeforeOpen: this._onBeforeOpen,
      onBeforeClose: this._onBeforeClose,
      transitions: false,
    };
  }

  unload() {
    if (!this.modal || !this.modal.isOpen) return;

    this.modal.destroy();
  }

  /**
   * Open a modal with contents from selector
   *
   * @param selector
   * @param handle
   */
  open(selector, handle = 'general', trigger = null) {
    this._addModalClass(handle);
    this.modal = new VanillaModal(this.modalOptions);
    this.modal.open(selector);
    this.trigger = trigger;
    window.addEventListener('keydown', this.closeEsc);
  }

  close() {
    this.modal.close();
    window.removeEventListener('keydown', this.closeEsc);
  }

  _closeEsc(e) {
    if (e.key === 'Escape') {
      this.close();
    }
  }

  isOpen() {
    return this.modal && this.modal.isOpen;
  }

  /**
   * Update the vertical positioning of modal
   */
  position() {
    const windowHeight = window.innerHeight;

    const modalHeight = this.$modalInner.outerHeight();
    const modalPadding = parseInt(this.$modal.css('padding-top'), 10) * 2;

    const offset = (windowHeight - modalPadding - modalHeight) / 2;
    const marginTop = offset > 0 ? offset : 0;
    this.$modalInner.css({ marginTop });
  }

  /**
   * Add a class to the modal for individual styling
   * @param handle
   * @private
   */
  _addModalClass(handle) {
    this.$modal.addClass(`modal--${handle}`);
  }

  /**
   * Remove modal class based on the handle
   * @private
   */
  _removeModalClass() {
    const modalClass = this.$modal.attr('class').match(/modal--[\w-]*\b/);
    if (!modalClass) {
      return;
    }

    this.$modal.removeClass(modalClass[0]);
  }

  _onClose() {
    this._removeModalClass();
    this.$body
      .removeClass('scroll-lock')
      .removeClass('modal-visible');

    this.$window.off('resize.modal');

    this.$modalInner.css({ marginTop: '' });

    this.callbacks.onClose();

    removeTrapFocus(this.$modal[0]);

    if (this.activeElement) {
      this.activeElement.focus();
    }
  }

  _onOpen() {
    this.activeElement = document.activeElement;
    this.position();
    this.$body
      .addClass('scroll-lock')
      .addClass('modal-visible');
    this.$window.on('resize.modal', debounce(() => this.position(), 16, true, true));

    this.callbacks.onOpen();

    trapFocus(this.$modal[0]);
  }

  _onBeforeClose() {
    this.callbacks.onBeforeClose();
  }

  _onBeforeOpen() {
    this.callbacks.onBeforeOpen();
  }
}
