import Flickity from 'flickity';
import * as breakpoint from '@pixelunion/breakpoint';

export default class DynamicTestimonials {
  constructor(section) {
    this.section = section;
    this.testimonialsEl = section.el.querySelector('[data-testimonials]');

    if (this.testimonialsEl.dataset.testimonials === '1') {
      // No work needs to be done if there is one testimonial
      return;
    }

    this._onBreakpointChange = this._onBreakpointChange.bind(this);

    breakpoint.onChange(this._onBreakpointChange);

    if (breakpoint.max('S')) {
      this._initializeMobile();
    }
  }

  onSectionUnload() {
    if (this.testimonialsEl.dataset.testimonials === '1') {
      // No work needs to be done if there is one testimonial
      return;
    }

    breakpoint.offChange(this._onBreakpointChange);
  }

  _onBreakpointChange(data) {
    const { current } = data;

    if (current === 'XS' || current === 'S') {
      this._initializeMobile();
    } else {
      this._destroyMobile();
    }
  }

  _initializeMobile() {
    if (this.flickity) {
      return;
    }

    this.flickity = new Flickity(
      this.testimonialsEl,
      {
        prevNextButtons: false,
      },
    );
  }

  _destroyMobile() {
    if (!this.flickity) {
      return;
    }

    this.flickity.destroy();
    this.flickity = null;
  }
}
