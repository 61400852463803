import $ from 'jquery';
import rimg from '@pixelunion/rimg-shopify';
import AsyncView from '@pixelunion/shopify-asyncview';
import 'intersection-observer';

import initShopifyProductReviews from '../helpers/ProductReviews';
import ProductGridItem from '../components/ProductGridItem';
import Modal from '../components/Modal';

function decodeUrlQueryParams(url) {
  const queryParamsString = new RegExp(/.*\?([^#]+)/).exec(url);
  const queryParams = {};

  if (queryParamsString && queryParamsString.length >= 2) {
    queryParamsString[1].split('&').forEach(param => {
      const [key, value] = param.split('=');

      queryParams[decodeURIComponent(key)] = decodeURIComponent(value);
    });
  }

  return queryParams;
}

function sanitizeQueryParams(params) {
  const sanitizedParams = params;

  if (typeof sanitizedParams !== 'object') {
    throw new Error('params is not of type Object');
  }

  if ('page' in sanitizedParams && sanitizedParams.page === 1) {
    delete sanitizedParams.page;
  }

  if ('view' in sanitizedParams) {
    if (sanitizedParams.view.indexOf('_ajax-collection-') !== -1) {
      sanitizedParams.view = sanitizedParams.view.replace('_ajax-collection-', '');
    }

    if (sanitizedParams.view === '_ajax-collection') {
      delete sanitizedParams.view;
    }
  }

  return sanitizedParams;
}

export default class StaticCollection {
  constructor(section) {
    this.id = section.id;
    this.el = section.el;
    this.showProductCounts = section.data.show_product_counts;
    this.productCount = section.data.product_count;
    this.context = section.data.context;
    this.events = [];
    this.processing = false;

    this.queryParams = {
      ...decodeUrlQueryParams(window.location.search),
    };

    this.view = this.queryParams.grid_list;

    this.filtersContent = '[data-productgrid-filters-content]';
    this.sortContent = '[data-productgrid-sort-content]';
    this.$sortTrigger = $('[data-productgrid-trigger-sort]', this.el);
    this.$showbyTrigger = $('[data-show-by]', this.el);
    this.$sortTriggerButton = $('[data-productgrid-trigger-sort-button]', this.el);
    this.$sortTriggerModal = $('[data-productgrid-modal-sort]', this.el);
    this.$filtersTrigger = $('[data-productgrid-trigger-filters]', this.el);
    this.$additionalTags = $('[data-filter-toggle]', this.el);
    this.$allTags = $(`${this.filtersContent} .filter-item a:not([data-filter-toggle])`, this.el);
    this.$viewToggle = $('[data-collection-view]', this.el);
    this.$gridContainer = $('.productgrid--outer', this.el);
    this.$gridTagLinks = $('.filter-item--grid a, .filter-item--grid-simple a', this.el);
    this.$paginationLinks = $('a.pagination__item', this.el);
    this.sidebarContainer = this.el.querySelector('.productgrid--sidebar');
    this.responseContainer = this.el.querySelector('.results--container');
    this.clickToLoadTrigger = this.el.querySelector('[data-click-to-load]');
    this.clickToLoad = this.el.querySelector('.click-to-load');
    this.infiniteScrollTrigger = this.el.querySelector('[data-infinite-scroll]');
    this.infiniteScroll = this.el.querySelector('.infinite-scroll');
    this.productGrid = this.responseContainer.querySelector('.productgrid--items');
    this.paginationContainer = this.responseContainer.querySelector('.pagination__wrapper:not(.visually-hidden)');

    this._changeSortingButton = this._changeSortingButton.bind(this);
    this._activateTag = this._activateTag.bind(this);
    this._toggleTags = this._toggleTags.bind(this);
    this._toggleView = this._toggleView.bind(this);

    // Ajax Search functions
    this._ajaxFilter = this._ajaxFilter.bind(this);

    this.modal = new Modal();

    if (window.history.scrollRestoration && (this.infiniteScroll || this.clickToLoad)) {
      window.history.scrollRestoration = 'manual';
    }

    this._updateView(this.view);

    if (this.productGrid) {
      this._initProductItems(this.productGrid.querySelectorAll('[data-product-item]'));
    }

    this._bindEvents();
    if (this.showProductCounts) {
      this._fetchProductCounts()
    }
  }

  onSectionUnload() {
    this.events.forEach($el => $el.off('.collection'));

    this.modal.unload();
    this.recentlyViewed.unload();

    this.productItems.forEach(productItem => {
      productItem.unload();
    });
  }

  _initProductItems(productEls) {
    this.productItems = [];

    for (let i = 0; i < productEls.length; i++) {
      const productItem = productEls[i];
      this.productItems.push(
        new ProductGridItem({
          $el: $(productItem),
          id: this.id,
          product: JSON.parse(productItem.querySelector('[data-product-data]').innerHTML),
          queryParams: this.queryParams,
          lazy: true,
        }),
      );
    }

    if ($('.spr-badge', this.el)) {
      initShopifyProductReviews();
    }
  }

  /**
   * Style a tag as active after click, before page transition
   *
   * @param event
   * @private
   */
  _activateTag(event) {
    if (this.processing) {
      event.preventDefault();
      return;
    }

    $(event.target)
      .closest('.filter-item')
      .toggleClass('filter-item--active')
      .toggleClass('filter-item--inactive');

    this._ajaxFilter(event);
  }

  /**
   * Expand / Collapse additional tags in the sidebar
   *
   * @param event
   * @private
   */
  _toggleTags(event) {
    event.preventDefault();

    if (this.processing) {
      return;
    }

    const $trigger = $(event.currentTarget);
    const $items = $trigger.parent().siblings('[data-hidden-default]');
    const siblingsVisible = $trigger.data('filter-toggle');

    $items.toggleClass('filter-item--hidden', siblingsVisible);
    $trigger
      .data('filter-toggle', !siblingsVisible)
      .text(!siblingsVisible ? this.context.see_less : this.context.see_more);

    if (this.modal.isOpen()) {
      this.modal.position();
    }
  }

  /**
   * Sort by opens a modal on mobile, this handles button events
   *
   * @param event
   * @private
   */
  _changeSortingButton(event) {
    if (this.processing) {
      return;
    }

    const activeClass = 'utils-sortby--modal-button--active';

    $(event.currentTarget)
      .addClass(activeClass)
      .parent()
      .siblings()
      .find(`.${activeClass}`)
      .removeClass(activeClass);

    this._ajaxFilter(event);
  }

  /**
   * Toggle grid or list view
   *
   */
  _toggleView(event) {
    const view = event.currentTarget.getAttribute('data-collection-view');
    this.queryParams.grid_list = view;
    this.view = view;

    this._updateView(this.queryParams.grid_list);
    this._updateHistory(window.location.pathname);
    if (this.productItems.length) {
      this.productItems.forEach(productItem => productItem.handleViewChange(this.queryParams.grid_list));
    }
  }

  _updateView(view = 'grid-view') {
    $('.utils-viewtoggle-button', this.el).removeClass('active');
    $(`[data-collection-view=${view}]`).addClass('active');

    const className = view.replace('-', '');

    if (className === 'listview') {
      this.$gridContainer.removeClass('productgrid-gridview');
    } else {
      this.$gridContainer.removeClass('productgrid-listview');
    }

    this.$gridContainer.addClass(`productgrid-${className}`);
  }

  /* AJAX search functions */
  _setProcessing(processing = true, type = 'filter') {
    this.processing = processing;

    if (processing) {
      if (type === 'filter') {
        const y = $(window).scrollTop();
        if (y >= 310) {
          $('html, body')
            .animate({ scrollTop: y - 300 }, 150)
            .animate({ scrollTop: 10 }, 0)
            .animate({ scrollTop: 0 }, 150);
        } else {
          $('html, body').animate({ scrollTop: 0 }, 150);
        }
      }

      if (this.sidebarContainer) {
        this.sidebarContainer.classList.add(`processing--${type}`);
      }
      this.responseContainer.classList.add(`processing--${type}`);
    } else {
      if (this.sidebarContainer) {
        this.sidebarContainer.classList.add(`processing--${type}`);
      }
      this.responseContainer.classList.remove(`processing--${type}`);
    }
  }

  /**
   * Retrieve the template containing filtered product items and tags via AJAX
   */
  _ajaxFilter(event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.processing === true) {
      return;
    }

    const target = event.currentTarget;
    let fetchUrl = window.location.pathname;

    this.queryParams.page = 1;

    if (target.hasAttribute('data-show-by')) { // 'Show by' - number of products per page
      this.queryParams.view = target.getAttribute('data-show-by');
    } else if (target.hasAttribute('data-productgrid-trigger-sort') || target.hasAttribute('data-productgrid-trigger-sort-button')) { // Sortby select/button
      this.queryParams.sort_by = $(target).val();
    } else if (target.hasAttribute('data-to-page')) { // Pagination link
      this.queryParams.page = target.getAttribute('data-to-page');
    } else { // Filter links
      const href = target.getAttribute('href');
      const queryParams = decodeUrlQueryParams(href);

      delete this.queryParams.constraint;
      if (queryParams.constraint) {
        this.queryParams.constraint = queryParams.constraint;
      }

      fetchUrl = href.replace(/\?[^#]+/, '');
    }

    this._ajaxFetch(fetchUrl, 'filter');
  }

  _ajaxPagination(infiniteScroll = false) {
    this.queryParams.page = infiniteScroll
      ? this.infiniteScrollTrigger.getAttribute('data-to-page')
      : this.clickToLoadTrigger.getAttribute('data-to-page');

    this._ajaxFetch(window.location.pathname, 'pagination');
  }

  _ajaxFetch(url, type) {
    // Trigger UI processing state
    this._setProcessing(true, type);
    this.queryParams = sanitizeQueryParams(this.queryParams);

    const params = {
      ...this.queryParams,
    };

    params.view = this.queryParams.view ? `_ajax-collection-${this.queryParams.view}` : '_ajax-collection';
    AsyncView.load(url, params)
      .then(({ html, data }) => {
        if (type === 'pagination') {
          const fragment = document.createDocumentFragment();
          const productItems = document.createElement('div');

          productItems.innerHTML = html.productgridItems;

          while (productItems.firstChild) {
            fragment.appendChild(productItems.firstChild);
          }
          const newProductItems = fragment.querySelectorAll('[data-product-item]');
          this.productGrid.appendChild(fragment);
          this._initProductItems(newProductItems);

          this.paginationContainer.innerHTML = html.pagination;
        } else {
          this.productItems.forEach(item => item.unload());
          this.responseContainer.innerHTML = html.productgrid;
          this._initProductItems(this.responseContainer.querySelectorAll('[data-product-item]'));

          if (this.sidebarContainer) {
            this.sidebarContainer.innerHTML = html.sidebar;
          }
        }

        this.productCount = data.product_count;

        this._updateView(this.queryParams.grid_list);

        rimg.watch(this.responseContainer);

        if (this.modal.isOpen()) {
          this.modal.close();
        }

        this._updateHistory(url);

        // Update link bindings with new DOM elements
        this._updateLinkBindings();
        this._bindEvents();
        if (this.showProductCounts) {
          this._fetchProductCounts();
        }

        // Once it's all updated, update the UI to show processing is complete
        this._setProcessing(false, type);
      });
  }


  /**
   * Unbind AJAX events for links no longer on the page, and bind new links
   */
  _updateLinkBindings() {
    this.events.forEach($el => $el.off('.collection'));

    this.$gridTagLinks = $('.filter-item--grid a, .filter-item--grid-simple a', this.el);
    this.$additionalTags = $('[data-filter-toggle]', this.el);
    this.$viewToggle = $('[data-collection-view]', this.el);
    this.$filtersTrigger = $('[data-productgrid-trigger-filters]', this.el);
    this.$sortTriggerModal = $('[data-productgrid-modal-sort]', this.el);
    this.$sortTrigger = $('[data-productgrid-trigger-sort]', this.el);
    this.$sortTriggerButton = $('[data-productgrid-trigger-sort-button]', this.el);
    this.$showbyTrigger = $('[data-show-by]', this.el);
    this.$paginationLinks = $('a.pagination__item', this.el);
    this.$allTags = $(`${this.filtersContent} .filter-item a:not([data-filter-toggle])`, this.el);
    this.clickToLoad = this.el.querySelector('.click-to-load');
    this.clickToLoadTrigger = this.el.querySelector('[data-click-to-load]');
    this.infiniteScrollTrigger = this.el.querySelector('[data-infinite-scroll]');
    this.infiniteScroll = this.el.querySelector('.infinite-scroll');
    this.productGrid = this.responseContainer.querySelector('.productgrid--items');
    this.paginationContainer = this.responseContainer.querySelector('.pagination__wrapper:not(.visually-hidden)');
  }

  /**
   * Bind filter link click events to new ajax functionality
   */
  _bindEvents() {
    const links = this.$gridTagLinks.get().concat(this.$showbyTrigger.get());

    this.events.push(...links.map(el => $(el).on('click.collection', this._ajaxFilter)));
    this.events.push(
      this.$sortTrigger.on('change.collection', this._ajaxFilter),
      this.$viewToggle.on('click.collection', this._toggleView),
      this.$filtersTrigger.on('click.collection', () => this.modal.open(this.filtersContent, 'productgrid-filters')),
      this.$allTags.on('click.collection', this._activateTag),
      this.$additionalTags.on('click.collection', this._toggleTags),
      this.$sortTriggerModal.on('click.collection', () => this.modal.open(this.sortContent, 'productgrid-sort')),
      this.$sortTriggerButton.on('click.collection', this._changeSortingButton),
    );

    if (this.infiniteScrollTrigger) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0) {
            this._ajaxPagination(true);
          }
        });
      });

      observer.observe(this.infiniteScrollTrigger);
    } else if (this.clickToLoad) {
      this.events.push($(this.clickToLoadTrigger).on('click.collection', () => this._ajaxPagination(false)));
    } else {
      this.events.push(this.$paginationLinks.on('click.collection', this._ajaxFilter));
    }
  }

  /**
   * Update the page URL so users can link to their particular search
   */
  _updateHistory(url) {
    if ((window.Shopify && window.Shopify.designMode) || !window.history.pushState) {
      return;
    }

    const querylessHref = url.replace(/\?[^#]+/, '');
    const href = querylessHref
      .replace(
        /([^#]+)(.*)/,
        (match, address, hash) => `${address}?${Object.keys(this.queryParams).sort().map(key => `${encodeURIComponent(key)}=${encodeURIComponent(this.queryParams[key])}`).join('&')}${hash}`,
      );

    if (window.location.href !== href) {
      window.history.pushState({}, '', href);
    }
  }

  _fetchProductCounts() {
    if (this.sidebarContainer) {
      const inactiveTags = this.sidebarContainer.querySelectorAll('.filter-item--inactive');
      const activeTags = this.sidebarContainer.querySelectorAll('.filter-item--active');

      activeTags.forEach(el => {
        if (el.closest('.productgrid--sidebar-item-swatches-grid') == null) {
          el.querySelector('[data-filtered-product-count]').innerHTML = `(${this.productCount})`;
        }
      });

      inactiveTags.forEach(el => {
        if (el.closest('.productgrid--sidebar-item-swatches-grid') == null) {
          let url = el.querySelector('a').getAttribute('href');

          url = url.split('?')[0];

          AsyncView.load(url, { view: '_ajax-product-count' })
            .then(({ data }) => {
              el.querySelector('[data-filtered-product-count]').innerHTML = `(${data.product_count})`;
            });
        }
      });
    }
  }
}
