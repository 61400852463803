import $ from 'jquery';
import scrollLeft from '../../helpers/scrollLeft';

export default class MegaNavScroller {
  constructor($el, block = null) {
    this.$el = $el;
    this.$menuList = this.$el.find('.navmenu-meganav-items');

    this._bindEvents();
    this._addScrollClasses();

    if (block) {
      scrollLeft($(block.el));
    }
  }

  unload() {
    this.$menuList.off('.meganav');
  }

  _bindEvents() {
    this.$menuList.on('scroll.meganav', () => this._addScrollClasses());
  }

  _addScrollClasses() {
    const scrollLeftPosition = this.$menuList.scrollLeft();
    const scrollWidth = this.$menuList[0].scrollWidth;
    const width = this.$menuList.width();

    this.$el.toggleClass('meganav--overflows-left', scrollLeftPosition > 0);
    this.$el.toggleClass('meganav--overflows-right', scrollLeftPosition < scrollWidth - width);
  }
}
