import $ from 'jquery';
import $script from 'scriptjs';
import debounce from 'just-debounce';
import { CountryProvinceSelector } from '@shopify/theme-addresses';

import Forms from '../Forms';
import MessageBanner from '../components/MessageBanner';
import CartItem from '../components/CartItem';
import CrossSell from '../components/CrossSell';

export default class StaticCart {
  constructor(section) {
    this.settings = section.data.settings;
    this.shipping = section.data.shipping;
    this.cart = section.data.cart;
    this.messageBanner = new MessageBanner();

    this.$window = $(window);
    this.el = section.el;
    this.$el = $(section.el);
    this.$header = $('[data-site-header-main]');
    this.$total = this.$el.find('[data-cart-total]');
    this.$shipping = this.$el.find('[data-cartshipping]');

    // Product form containers
    this.$titleTotalSmall = this.$el.find('.cart-title-total--small');
    this.$titleTotalLarge = this.$el.find('.cart-title-total--large');
    this.$titleTotalContents = this.$el.find('[data-cart-title-total]');

    // Shipping calculator elements
    this.$shippingToggle = this.$el.find('[data-cartshipping-toggle]');
    this.$shippingResponse = this.$shipping.find('[data-cartshipping-response]');
    this.$shippingResponseMessage = this.$shippingResponse.find('[data-cartshipping-message]');
    this.$shippingResponseRates = this.$shippingResponse.find('[data-cartshipping-rates]');
    this.$shippingSubmit = this.$shipping.find('[data-cartshipping-submit]');

    this.$form = this.$el.find('[data-cart-form]');
    this.$checkoutButtons = this.$el.find('button[name="checkout"]');
    this.$totals = this.$el.find('[data-cart-totals]');

    this._moveTitleTotal();

    const $scripts = $('[data-scripts]');

    $script($scripts.data('shopify-api-url'), () => {
      this._bindEvents();


      this.cartItems = this.cart.items.map((lineItem) => {
        const $el = $(`[data-cartitem-id="${lineItem.id}"]`, this.$el);

        return new CartItem($el.get(0), lineItem, this.settings);
      });

      this.cartTotalValue = window.api.theme.useValue({
        name: 'cart:total',
        handler: data => {
          if (!data) {
            console.error('Error initializing cart total value.');
            return;
          }

          const { price } = data;

          this.$total.each((i, el) => {
            el.setAttribute('data-cart-total', price);
            el.innerHTML = Shopify.formatMoney(price, this.settings.money_format);
          });
        },
        initialValue: { price: this.cart.total_price, compare_at_price: this.cart.total_price },
      });

      this.cartCheckoutValue = window.api.theme.useValue({
        name: 'cart:checkout',
        handler: value => {
          if (typeof value === 'undefined' || value === null) {
            console.error("Error initializing cart checkout value.");
            return;
          }

          this.$checkoutButtons.prop('disabled', !value);
        },
        initialValue: true,
      });

      this.cartNotificationsElement = window.api.theme.useElement('cart:notifications', section.el.querySelector('[data-cart-notifications]'));
      this.cartToolsElement = window.api.theme.useElement('cart:tools', section.el.querySelector('[data-cart-tools]'));

      // When the cart is submitted, we should fire an event that can be cancelled by integrations
      this.$form.on('submit', event => window.api.theme.triggerEvent('cart:checkout', { cancel: () => event.preventDefault() }));

      Shopify.onError = this._handleErrors.bind(this);
    });

    this.forms = new Forms(this.$el);

    if (this.settings.shipping && this.$shipping.length) {
      $script($scripts.data('shopify-countries'), () => {
        $script($scripts.data('shopify-common'), () => {
          $script($scripts.data('shopify-cart'), () => {
            this._initShippingCalc();
          });
        });
      });
    }

    const crossSellEl = this.el.querySelector('[data-cross-sell]');

    if (crossSellEl) {
      this.crossSell = new CrossSell(
        crossSellEl,
        section.data.cross_sell,
      );
    }
  }

  onSectionUnload() {
    this.cartItems.forEach(cartItem => cartItem.unload());
    this.cartTotalValue.unload();
    this.cartCheckoutValue.unload();
    this.cartNotificationsElement.unload();
    this.cartToolsElement.unload();
    this.$el.off('.cart-page');
    this.$window.off('.cart-page');
    this.forms.unload();
    this.messageBanner.unload();
    this.messageBanner.dismissBanners();
  }

  _bindEvents() {
    this.$el.on('keydown.cart-page', '[data-cartitem-quantity]', (event) => {
      this._quantityKeyDown(event);
    });

    /*
     * There are two [data-cartitem-quantity] selectors.
     * Use the input as the source of truth rather than the select
     * because the input has an infinite value range whereas the select
     * has a finite range.
     */
    this.$el.on('change.cart-page', '[data-cartitem-quantity][data-quantity-input]', (event) => {
      this._editItemQuantity(event);
    });

    this.$el.on('click.cart-page', '[data-cartitem-remove]', (event) => {
      event.preventDefault();
      this._editItemQuantity(event, true);
    });

    this.$window.on('resize.cart-page', debounce(() => this._moveTitleTotal(), 20));
  }

  _moveTitleTotal() {
    if (!this.$titleTotalContents.length) {
      return;
    }

    if (this.$window.outerWidth() >= 480) {
      if (!$.contains(this.$titleTotalLarge[0], this.$titleTotalContents[0])) {
        const $form = this.$titleTotalContents.detach();
        this.$titleTotalLarge.append($form);
      }
    } else {
      if (!$.contains(this.$titleTotalSmall[0], this.$titleTotalContents[0])) {
        const $form = this.$titleTotalContents.detach();
        this.$titleTotalSmall.append($form);
      }
    }
  }

  /**
   * Prevent form submission when pressing `enter` within a quantity selector
   *
   * @param event
   * @private
   */
  _quantityKeyDown(event) {
    // Only block enter keys
    if (event.keyCode !== 13) {
      return;
    }

    // Block form submission, but update field
    $(event.currentTarget).trigger('change');

    event.preventDefault();
  }

  /**
   * Handle an item quantity change
   *
   * @param event
   * @param {Boolean} remove - Set as true to remove cart item
   * @private
   */
  _editItemQuantity(event, remove = false) {
    this.messageBanner.dismissBanners();

    const $target = $(event.currentTarget);
    const $cartItem = $target.closest('[data-cartitem-id]');

    let quantity = remove ? 0 : parseInt($target.val(), 10);
    quantity = Math.max(quantity, 0);

    this._updateCart($cartItem, quantity);

    // Remove item from cart visually if it is being removed
    if (quantity === 0 || remove) {
      $cartItem.height($cartItem.height());
    }
  }

  /**
   * Update cart with a valid quantity
   *
   * @param $cartItem
   * @param quantity
   * @private
   */
  _updateCart($cartItem, quantity) {
    const id = $cartItem.data('cartitem-id');

    // Notify Shopify updated item
    Shopify.changeItem(id, quantity, (response) => {
      this._didUpdate(id, quantity, response, $cartItem);
    });
  }

  _quantityError(limit, title) {
    const errorMessage = this.settings.stock_limit
      .replace('** quantity **', limit)
      .replace('** title **', title);

    this.messageBanner.message(errorMessage, 'error');
  }

  /**
   * Update cart content based on information from Shopify
   *
   * @param id
   * @param quantity
   * @param response
   * @param $cartItem
   * @returns {*}
   * @private
   */
  _didUpdate(id, quantity, response, $cartItem) {
    // Reload page if all items are removed from cart
    if (!response.items.length) {
      window.location = window.Theme.routes.cart_url;
      return;
    }

    window.api.theme.triggerEvent('cart:updated', { cancel: () => {} });

    this.cart = response;

    this.$header.trigger('cartcount:update', { response });

    // Update all totals on the page when the cart has been updated
    window.api.theme.updateValue('cart:total', { price: this.cart.total_price, compare_at_price: this.cart.total_price });
    window.api.theme.updateValue('cart:checkout', true);
    window.api.theme.updateElement('cart:notifications');
    window.api.theme.updateElement('cart:tools');

    // Select item from response
    const filteredItems = response.items.filter(item => item.id === id);

    if (!filteredItems.length) {
      $cartItem.addClass('removing')
        .one('trend', () => {
          $cartItem
            .off('trend')
            .remove();
        });
      return;
    }

    window.api.theme.updateValue(
      `line-item:${id}:price`,
      {
        price: this.cart.items.filter(lineItem => lineItem.id === id)[0].line_price,
        compare_at_price: this.cart.items.filter(lineItem => lineItem.id === id)[0].original_line_price,
      },
    );

    const cartItem = filteredItems[0];

    if (cartItem.quantity !== quantity) {
      this._quantityError(cartItem.quantity, cartItem.title);
      $cartItem.find('[data-quantity-input]').val(cartItem.quantity);
      $cartItem.find('[data-quantity-select]').val(cartItem.quantity);
    }
  }

  /**
   * Handle Errors returned from Shopify
   *
   * @param errors
   * @private
   */
  _handleErrors(errors = null) {
    if (!errors) {
      return;
    }

    const shippingResponse = {
      message: this.shipping.error_general,
    };

    if (errors.zip && errors.zip.length > 0) {
      if (errors.zip[0].indexOf('is not valid') !== -1 || errors.zip[0].indexOf('can\'t be blank') !== -1) {
        shippingResponse.message = `${this.shipping.zip} ${errors.zip}`;
      }
    }

    if (errors.error && errors.error.length > 0) {
      if (errors.error[0].indexOf('shipment_too_heavy') !== -1) {
        shippingResponse.message = this.shipping.shipment_too_heavy;
      }
    }

    this._handleShippingResponse(shippingResponse);
  }

  _initShippingCalc() {
    this._bindShippingCalcEvents();

    const countrySelect = document.getElementById('address_country');
    const provinceSelect = document.getElementById('address_province');
    const provinceContainer = document.getElementById('address_province_container');

    this.shippingCountryProvinceSelector = new CountryProvinceSelector(countrySelect.innerHTML);

    this.shippingCountryProvinceSelector
      .build(
        countrySelect,
        provinceSelect,
        {
          onCountryChange: (provinces) => {
            if (provinces.length) {
              provinceContainer.style.display = 'block';
            } else {
              provinceContainer.style.display = 'none';
            }

            // "Province", "State", "Region", etc. and "Postal Code", "ZIP Code", etc.
            // Even countries without provinces include a label.
            const { label, zip_label } = window.Countries[countrySelect.value];
            provinceContainer.querySelector('label[for="address_province"]').innerHTML = label;
            this.el.querySelector('#address_zip ~ label[for="address_zip"]').innerHTML = zip_label;
          },
        },
      );
  }

  _bindShippingCalcEvents() {
    this.$el.on('click.cart-page', '[data-cartshipping-toggle]', () => {
      this._toggleShippingCalc();
    });

    this.$el.on('click.cart-page', '[data-cartshipping-submit]', () => {
      this._getShippingRates();
    });

    this.$el.on('keypress.cart-page', '#address_zip', (event) => {
      if (event.keyCode === 10 || event.keyCode === 13) {
        event.preventDefault();
        this.$shippingSubmit.trigger('click');
      }
    });
  }

  _toggleShippingCalc() {
    const oldText = this.$shippingToggle.text();
    const newText = this.$shippingToggle.data('cartshipping-toggle');

    this.$shippingToggle
      .text(newText)
      .data('cartshipping-toggle', oldText);

    this.$shipping.toggleClass('open');
  }

  _getShippingRates() {
    this._disableShippingButton();

    const shippingAddress = {};
    shippingAddress.country = $('#address_country').val() || '';
    shippingAddress.province = $('#address_province').val() || '';
    shippingAddress.zip = $('#address_zip').val() || '';

    const queryString = Object.keys(shippingAddress)
      .map(key => `${encodeURIComponent(`shipping_address[${key}]`)}=${encodeURIComponent(shippingAddress[key])}`)
      .join('&');

    $.ajax(`${window.Theme.routes.cart_url}/shipping_rates.json?${queryString}`, { dataType: 'json' })
      .fail(error => this._handleErrors(error.responseJSON || {}))
      .done((response) => {
        const rates = response.shipping_rates;
        const addressBase = [];

        if (shippingAddress.zip.length) {
          addressBase.push(shippingAddress.zip.trim());
        }

        if (shippingAddress.province.length) {
          addressBase.push(shippingAddress.province);
        }

        if (shippingAddress.country.length) {
          addressBase.push(shippingAddress.country);
        }

        const address = addressBase.join(', ');

        let message = '';
        if (rates.length > 1) {
          const firstRate = window.Shopify.formatMoney(rates[0].price, this.settings.money_format);
          message = this.shipping.multiple_rates
            .replace('*address*', address)
            .replace('*number_of_rates*', rates.length)
            .replace('*rate*', `<span class="money">${firstRate}</span>`);
        } else if (rates.length === 1) {
          message = this.shipping.one_rate.replace('*address*', address);
        } else {
          message = this.shipping.no_rates;
        }

        const ratesList = rates.map((rate) => {
          const price = window.Shopify.formatMoney(rate.price, this.settings.money_format);
          const rateValue = this.shipping.rate_value
            .replace('*rate_title*', rate.name)
            .replace('*rate*', `<span class="money">${price}</span>`);

          return `<li>${rateValue}</li>`;
        });

        this._handleShippingResponse({
          message,
          rates: ratesList,
        });
      });
  }

  _enableShippingButton() {
    this.$shippingSubmit
      .text(this.shipping.calculate_shipping)
      .attr('disabled', false);
  }

  _disableShippingButton() {
    this.$shippingSubmit
      .text(this.shipping.calculating)
      .attr('disabled', true);
  }

  _showShippingResponse() {
    this.$shippingResponse.addClass('visible');
  }

  _hideShippingResponse() {
    this.$shippingResponse.removeClass('visible');
  }

  /**
   * Handle shipping responses
   *
   * @param {object} shippingResponse
   * @property {String} shippingResponse.messages - Error / Success message
   * @property {Array|String} shippingResponse.rates - Shipping rates
   * @private
   */
  _handleShippingResponse(shippingResponse = {}) {
    // Hide the response so that it can be populated smoothly
    this._hideShippingResponse();

    const message = shippingResponse.message || null;
    const rates = shippingResponse.rates || null;

    // Empty out contents
    this.$shippingResponseMessage.empty();
    this.$shippingResponseRates.empty();

    if (message) {
      this.$shippingResponseMessage
        .html(message);
    }

    if (rates) {
      this.$shippingResponseRates
        .html(rates);
    }

    // Reset the calculating button so it can be used again
    this._enableShippingButton();

    // No error provided
    if (!message && !rates) {
      return;
    }

    // Show the response
    this._showShippingResponse();
  }
}
