import $ from 'jquery';

// IE does not correctly apply CSS transforms to svg elements, which are used
// in this theme to transform  icon-chevron-down to a minus/close icon.
// We need to manually get the calculated transform value and apply it to the
// transform attribute, which is properly supported. Yuck.
function fixIEsetTransformAttribute($block) {
  if (navigator.userAgent.match(/(Trident\/7\.)|(MSIE)/) === null) {
    return;
  }

  const $paths = $block.parent().find('path');

  $paths.each((index, path) => {
    const transform = window.getComputedStyle(path).getPropertyValue('transform');
    path.setAttribute('transform', transform);
  });
}

export default class Accordion {
  constructor($el, options = {}) {
    this.$el = $el;

    this.options = $.extend({}, {
      activeClass: 'accordion--active',
      autoCollapse: false,
      content: '[data-accordion-content]',
      eligibleClass: 'has-accordion',
    }, options);
  }

  toggle($block, open = false) {
    if (open || !$block.hasClass(this.options.activeClass)) {
      if (this.options.autoCollapse) this.closeOpen();
      this._open($block);
    } else {
      this._close($block);
    }
  }

  closeOpen() {
    $(this.$el.find(`.${this.options.activeClass}`)).each((i, block) => {
      const $block = $(block);
      if ($block.hasClass(this.options.activeClass)) {
        this._close($block);
      }
    });
  }

  openAll() {
    $(this.$el.find(`.${this.options.eligibleClass}`)).each((i, block) => {
      this._open($(block), 0);
    });
  }

  _open($block) {
    $block.addClass(this.options.activeClass);
    fixIEsetTransformAttribute($block);
  }

  _close($block) {
    $block.removeClass(this.options.activeClass);
    fixIEsetTransformAttribute($block);
  }
}
