export default class Images {
  /**
   * Preloads an image in memory and uses the browsers cache to store it until needed.
   *
   * @param {Array} images - A list of image urls
   * @param {String} size - A shopify image size attribute
   */
  preload(images, size) {
    if (typeof images === 'string') {
      images = [images];
    }

    for (let i = 0; i < images.length; i++) {
      this.loadImage(this.getSizedImageUrl(images[i], size));
    }
  }

  /**
   * Loads and caches an image in the browsers cache.
   *
   * @param {string} path - An image url
   */
  loadImage(path) {
    return new Image().src = path;
  }

  /**
   * Adds a Shopify size attribute to a URL
   *
   * @param src
   * @param size
   * @returns {*}
   */
  getSizedImageUrl(src = null, size) {
    if (!size) {
      return null;
    }

    if (size === 'master') {
      return this.removeProtocol(src);
    }

    const match = src.match(/\.(jpg|jpeg|gif|png|bmp|bitmap|tiff|tif)(\?v=\d+)?$/i);

    if (match) {
      const prefix = src.split(match[0]);
      const suffix = match[0];

      return this.removeProtocol(`${prefix[0]}_${size}${suffix}`);
    } else {
      console.warn(`No ${size} found for '${src}`);
      return null;
    }
  }

  removeProtocol(path) {
    return path.replace(/http(s)?:/, '');
  }
}
