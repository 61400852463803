import $ from 'jquery';
import RichText from '../components/RichText';
import Forms from '../Forms';

import Shortcodes from '../shortcodes/Shortcodes';

export default class StaticArticle {
  constructor(section) {
    this.$el = $(section.el);
    this.$commentForm = this.$el.find('[data-articlecomments-form]');

    this.richText = new RichText(section.el);

    if (this.$commentForm.length) {
      this.commentForm = new Forms(this.$commentForm);
    }

    this.shortcodes = new Shortcodes(section.el);
  }

  onSectionUnload() {
    this.richText.unload();

    if (this.commentForm) {
      this.commentForm.unload();
    }

    if (this.shortcodes) {
      this.shortcodes.unload();
    }
  }
}
