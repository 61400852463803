import $ from 'jquery';
import {
  trapFocus,
  removeTrapFocus,
} from '@shopify/theme-a11y';

import MessageBanner from './MessageBanner';
import Images from '../helpers/Images';

class AddToCartFlyout {
  constructor() {
    this.formData = {};
    this.settings = {};
    this.events = [];
    this.Images = new Images();
    this.flyOutSelector = '[data-atc-banner]';
    this.$atcTemplate = $(`[data-templates] ${this.flyOutSelector}`);
    this.activeElement = null;

    this.atcFlyOut = document.querySelector(this.flyOutSelector);

    this._onInit = this._onInit.bind(this);
    this._onError = this._onError.bind(this);
    this._onSuccess = this._onSuccess.bind(this);
    this._onCloseAll = this._onCloseAll.bind(this);
    this._handleDocumentClick = this._handleDocumentClick.bind(this);

    this._closeEsc = this._closeEsc.bind(this);

    this.messageBanner = new MessageBanner();

    this.header = document.querySelector('[data-site-header]');
    this.$header = $(this.header);
    this.$headerMain = this.$header.find('[data-site-header-main]')
    this.$body = $(document.body);
  }

  init(formData, product, options, callbacks = {}) {
    this.formData = formData;
    this.product = product;

    // Allows ATC Flow to be overridden
    this.callbacks = $.extend({
      onInit: this._onInit,
      onError: this._onError,
      onSuccess: this._onSuccess,
      onClose: this._onCloseAll,
    }, callbacks);

    const $atcButton = options.$atcButton || $(options.atcButton);

    this.settings = $.extend({
      moneyFormat: null,
      cartRedirection: false,
    }, options.settings);

    // If any FlyOuts are fully open, close them
    this._closeFlyOuts();

    this.callbacks.onInit();

    this.activeElement = document.activeElement;

    this._disableButton($atcButton);
    this._updateCart($atcButton);
  }

  unload() {
    this.messageBanner.dismissBanners();
    this._removeFlyOuts();
    delete this.Images;
  }

  _updateCart($atcButton) {
    const $flyOut = this.$atcTemplate.clone();

    const idField = this.formData.filter(data => data.name === 'id');
    const id = parseInt(idField[0].value, 10);
    const variants = this.product.variants.filter(variant => variant.id === id);
    const variant = variants[0];
    const quantityField = this.formData.filter(data => data.name === 'quantity');
    const quantity = parseInt(quantityField[0].value, 10);

    $.ajax({
      type: 'POST',
      url: `${window.Theme.routes.cart_add_url}.js`,
      data: $.param(this.formData),
      dataType: 'json',
    })
      .done((response) => {
        window.api.theme.triggerEvent('cart:updated', { cancel: () => {} });

        if (this.settings.cartRedirection) {
          location.href = window.Theme.routes.cart_url;
          return;
        }

        if (response.image) {
          const imageUrl = this.Images.getSizedImageUrl(response.image, '200x');
          const image = this.Images.loadImage(imageUrl);

          $flyOut.find('[data-atc-banner-product-image]')
            .html(`<img src="${image}" alt="${response.product_title}">`);
        }

        $flyOut.find('[data-atc-banner-product-title]')
          .text(response.product_title);

        /*
          TODO: Bring in `variant.options`, iterate through to get option
            name for: <strong>Option name:</strong> Option
        */
        if (response.variant_options[0] !== 'Title' && response.variant_options[0] !== 'Default Title') {
          $flyOut.find('[data-atc-banner-product-options]')
            .text(response.variant_options.join(', '));
        }

        /*
          TODO: Bring in variant, and use that to check compare_at_price
            to see if the item is on sale
        */
        const $productPriceQuantity = $flyOut.find('[data-atc-banner-product-price-quantity]');
        $productPriceQuantity.text(`${quantity} × `);

        const $productPriceValue = $flyOut.find('[data-atc-banner-product-price-value]');

        $productPriceValue.text(Shopify.formatMoney(variant.price, this.settings.moneyFormat));

        // Price Per Unit
        const unitPrice = $flyOut[0].querySelector('[data-atc-banner-unit-price]');
        let unitPriceString = unitPrice.innerHTML;

        if (unitPrice && variant.unit_price_measurement) {
          unitPriceString = unitPriceString.replace(
            '** total_quantity **',
            `${variant.unit_price_measurement.quantity_value}${variant.unit_price_measurement.quantity_unit}`,
          );

          unitPriceString = unitPriceString.replace(
            '** unit_price **',
            Shopify.formatMoney(variant.unit_price, this.settings.money_format),
          );
          if (variant.unit_price_measurement.reference_value === 1) {
            unitPriceString = unitPriceString.replace('** unit_measure **', variant.unit_price_measurement.reference_unit);
          } else {
            unitPriceString = unitPriceString.replace(
              '** unit_measure **',
              `${variant.unit_price_measurement.reference_value}${variant.unit_price_measurement.reference_unit}`,
            );
          }
          unitPrice.innerHTML = unitPriceString;
          unitPrice.classList.remove('hidden');
        }

        $.ajax({
          type: 'GET',
          url: `${window.Theme.routes.cart_url}.js`,
          dataType: 'json',
        })
          .done((response) => {
            this.callbacks.onSuccess();

            // Reset formData in case instance is never cleared
            this.formData = {};


            const $subTotal = $flyOut.find('[data-atc-banner-cart-subtotal]');
            const cartTotalValueListener = ({ price }) => {
              if ($subTotal) {
                $subTotal[0].innerHTML = Shopify.formatMoney(price, this.settings.moneyFormat);
              }
            };

            this.cartTotalValue = window.api.theme.useValue({
              name: 'cart:total',
              handler: cartTotalValueListener,
              initialValue: { price: response.total_price, compare_at_price: response.total_price },
            });

            $flyOut
              .find('[data-atc-banner-cart-button]')
              .find('span').text(response.item_count);

            this.$header.append($flyOut);

            // Notifiy Header of new cart count
            this.$headerMain.trigger('cartcount:update', { response });

            this._bindEvents($flyOut);
            this._openFlyOut($flyOut, $atcButton);
          });
      })
      .fail((response) => {
        let errorText;
        try {
          const responseText = JSON.parse(response.responseText);
          errorText = responseText.description;
        } catch (error) {
          errorText = `${response.status} ${response.statusText}`;
          if (response.status === 401) {
            errorText = `${errorText}. Try refreshing and logging in.`;
          }
        }

        this._enableButton($atcButton);
        this.callbacks.onError(errorText);
      });
  }

  _onError(error) {
    this.messageBanner.message(error, 'error');
  }

  _onInit() {
    this.messageBanner.dismissBanners();
  }

  _onSuccess() {
    /*
      By default, the ATC Flyout doesn't need any additional success callbacks

      The `this.callbacks.onSuccess` is used to allow other views to initiate
      behaviour when a product has been added to the cart
     */
  }

  _onCloseAll() {
    /*
      By default, the ATC Flyout doesn't need any additional close callbacks

      The `this.callbacks.onClose` is used to allow other views to initiate
      behaviour when the atc banner has been closed
     */
  }

  _bindEvents($flyOut) {
    this.events.push([
      $flyOut.on('click.atc-banner', '[data-atc-banner-close]', event => {
        event.preventDefault();
        this._closeFlyOut($flyOut);
      }),
      $('[data-atc-button-checkout]', $flyOut).on(
        'click.atc-banner',
        event => window.api.theme.triggerEvent('cart:checkout', { cancel: () => event.preventDefault() }),
      ),
    ]);
  }

  /**
   * Trigger any existing open FlyOuts to close
   *
   * @private
   */
  _closeFlyOuts($excludeFlyOut = null) {
    $(this.flyOutSelector).filter('[data-flyout-active]').each((index, el) => {
      const $flyOut = $(el);

      if ($excludeFlyOut && $flyOut.is($excludeFlyOut)) {
        return;
      }

      this._closeFlyOut($flyOut, index);
    });

    if (!$excludeFlyOut) {
      this.callbacks.onClose();
    }

    window.removeEventListener('keydown', this._closeEsc);
    window.removeEventListener('click', this._handleDocumentClick);
  }

  _openFlyOut($flyOut, $atcButton) {
    /*
     If user has initiated a new ATC Flow before the first has finished,
     the first FlyOut could have opened after the first attempt to close open flyouts
    */
    this._closeFlyOuts($flyOut);

    $flyOut
      .addClass('animating animating-in')
      .one('trend', () => {
        $flyOut
          .removeClass('animating animating-in')
          .addClass('visible')
          .off('trend');

        $flyOut.attr('data-flyout-active', true);

        trapFocus($flyOut[0]);

        window.addEventListener('keydown', this._closeEsc);
        window.addEventListener('click', this._handleDocumentClick);

        this._enableButton($atcButton);
      });
  }

  _closeEsc(e) {
    if (e.key === 'Escape') {
      this._closeFlyOuts();
    }
  }

  /**
   * Close an open FlyOut
   *
   * @param $flyOut
   * @param index
   * @private
   */
  _closeFlyOut($flyOut = null, index = 0) {
    if (!$flyOut) {
      return;
    }

    $flyOut
      .addClass('animating animating-out')
      .one('trend', () => {
        $flyOut
          .removeClass('animating animating-out visible')
          .one('trend', () => {
            $flyOut.off('trend');
            this._removeFlyOut($flyOut, index);

            // if the user clicked onto the search box, close the atc flyout and move focus
            // to the search instead of going to the previous active element.
            if (this.documentClickEventTarget && 'liveSearchInput' in this.documentClickEventTarget.dataset) {
              this.documentClickEventTarget.focus();
            } else if (this.activeElement) {
              this.activeElement.focus();
            }

            removeTrapFocus($flyOut[0]);
            this.callbacks.onClose();
          });
      });
  }

  _removeFlyOuts() {
    $(this.flyOutSelector).filter('[data-flyout-active]').each((index, el) => {
      this._removeFlyOut($(el), index);
    });
  }

  /**
   * Remove the Flyout from the DOM, and clean up listeners
   *
   * @param $flyOut
   * @param index
   * @private
   */
  _removeFlyOut($flyOut, index) {
    if (this.events[index]) {
      this.events[index].forEach($el => $el.off('.atc-banner'));
      this.events.splice(index, 1);
    }

    if (this.cartTotalValue) {
      this.cartTotalValue.unload();
      this.cartTotalValue = null;
    }

    if ($flyOut) {
      $flyOut.remove();
    }
  }

  _disableButton($atcButton) {
    $atcButton
      .addClass('processing')
      .prop('disabled', true);
  }

  _enableButton($atcButton) {
    $atcButton
      .prop('disabled', false)
      .removeClass('processing');
  }

  _handleDocumentClick(event) {
    const target = event.target;
    const $parent = $(target).parents('[data-atc-banner]');

    if ($parent.length) {
      return;
    }

    this.documentClickEventTarget = target;
    this._closeFlyOuts();
  }
}

export default new AddToCartFlyout();
