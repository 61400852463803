import Shortcode from './Shortcode';

export default class ProductCTA extends Shortcode {
  static get shortcode() {
    return 'product-cta';
  }

  constructor(el) {
    super();

    this.productFormEl = null;
    let parentEl = el.parentElement;

    while (parentEl) {
      this.productFormEl = parentEl.hasAttribute('[data-product-form]') ? parentEl : parentEl.querySelector('[data-product-form]');

      if (this.productFormEl) break;

      parentEl = parentEl.parentElement;
    }

    if (!this.productFormEl) {
      throw new Error('ProductCTA: No product form found.');
    }

    // Keep a reference to shortcode element to unhide
    this.el = el;

    this.variantId = el.getAttribute('variant-id');

    // Build the call to action element
    this.ctaEl = document.createElement('a');
    this.ctaEl.classList.add('shortcode--product-cta');

    if (el.getAttribute('button-style')) {
      this.ctaEl.setAttribute('button-style', el.getAttribute('button-style'));
    }

    if (el.getAttribute('button-size')) {
      this.ctaEl.setAttribute('button-size', el.getAttribute('button-size'));
    }

    this.ctaEl.href = el.getAttribute('link');
    this.ctaEl.innerHTML = el.innerHTML;

    const target = el.getAttribute('target');
    if (target) {
      this.ctaEl.setAttribute('target', target);
      if (target === '_blank') {
        const icon = document.createElement('span');
        icon.classList.add('button-icon');
        icon.setAttribute('aria-label', '(New window)');
        icon.innerHTML = '<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.81792 2.29812L5.68732 5.42884L6.57121 6.3127L9.70198 3.18183L9.70227 5.51933L10.9523 5.51917L10.9518 1.67302L10.9517 1.0481L10.3268 1.0481L6.48062 1.04815L6.48064 2.29815L8.81792 2.29812ZM1.67297 1.04817H1.04797V1.67317V10.327V10.952H1.67297H10.3268H10.9518V10.327V8.13026H9.70175V9.70195H2.29797V2.29817H3.83642V1.04817H1.67297Z" fill="currentColor"/></svg>';
        this.ctaEl.appendChild(icon);
      }
    }

    // Remove element from DOM and insert new element into position
    this.el.toggleAttribute('hidden', true);
    this.productFormEl.parentNode.insertBefore(this.ctaEl, this.productFormEl.nextSibling);

    if (this.variantId) {
      const variantsEl = this.productFormEl.querySelector('[data-variants]');
      this._registerEvent({ el: variantsEl, event: 'change', listener: this._onVariantChange.bind(this) });
      this._setVisible(variantsEl.value === this.variantId);
    } else {
      this._setVisible(true);
    }
  }

  unload() {
    super.unload();

    this.productFormEl.parentNode.removeChild(this.ctaEl);
    this.el.toggleAttribute('hidden', false);
  }

  _setVisible(visible) {
    this.ctaEl.toggleAttribute('hidden', !visible);
  }

  _onVariantChange(e) {
    this._setVisible(e.currentTarget.value === this.variantId);
  }
}
