import ShopifyVariants from '@pixelunion/shopify-variants';
import Forms from '../Forms';
import RichText from './RichText';

export default class CrossSell {
  constructor(el) {
    this.el = el;
    this.form = el.querySelector('[data-product-form]');
    this.description = el.querySelector('[data-cross-sell-description]');
    this.productAtcButton = this.el.querySelector('[data-product-atc]');
    this.productAtcButtonText = this.productAtcButton.querySelector('[data-product-atc-text]');
    this.productVariants = this.form.querySelector('[data-variants]');
    this.productOptions = this.form.querySelectorAll('[data-product-option]');

    const dataEl = el.querySelector('[data-cross-sell-data]');

    try {
      this.data = JSON.parse(dataEl.innerHTML);
    } catch {
      return;
    }

    this.forms = new Forms(this.form);
    this.richText = new RichText(this.description);

    if (this.productVariants) {
      this.variantHelper = new ShopifyVariants(
        this.data.product,
        this.productVariants,
        this.productOptions,
        {
          mixedControls: true,
          onSwitchVariant: this._switchVariant.bind(this),
        },
      );
    }

    this._onProductOptionsFocus = () => { this.activeElement = document.activeElement; };
    this.productOptions.forEach(productOption => productOption.addEventListener('focus', this._onProductOptionsFocus));
  }

  unload() {
    this.productOptions.forEach(productOption => productOption.removeEventListener('focus', this._onProductOptionsFocus));
    this.forms.unload();

    if (this.variantHelper) {
      this.variantHelper.unload();
    }
  }

  selectVariant(variantId) {
    if (variantId) {
      this.variantHelper.selectVariant(variantId);
    }
  }

  _switchVariant(data) {
    const { product, variant, firstLoad } = data;

    if (product !== this.data.product) return;

    if (firstLoad) {
      return;
    }

    // Refocus the active element (Shopify Variants causes element to lose focus)
    if (this.activeElement) {
      this.activeElement.focus();
    }

    // Update main select
    this.productVariants.value = variant.id;

    // Update Variant information
    this._updateButton(variant);
    this._updateSwatchLabel(variant);
  }

  _updateButton(variant) {
    if (variant.available) {
      this.productAtcButtonText.innerText = this.data.context.product_available;
      this.productAtcButton.classList.remove('disabled');
      this.productAtcButton.removeAttribute('disabled');
    } else {
      this.productAtcButtonText.innerText = this.data.context.product_unavailable;
      this.productAtcButton.classList.add('disabled');
      this.productAtcButton.setAttribute('disabled', true);
    }
  }

  _updateSwatchLabel(variant) {
    if (this.data.settings.enableSwatches) {
      const swatchLabel = this.form.querySelector('[data-option-swatch-value]');
      if (swatchLabel) {
        swatchLabel
          .innerText = variant.options[parseInt(swatchLabel.dataset.optionSwatchValue, 10)];
      }
    }
  }
}
