const polyfillUrls = [];

// Checks if all IntersectionObserver and IntersectionObserverEntry
// features are natively supported.
if (!(
  'IntersectionObserver' in window
  && 'IntersectionObserverEntry' in window
  && 'intersectionRatio' in window.IntersectionObserverEntry.prototype
) || !('open' in document.createElement('details'))) {
  polyfillUrls.push(document.querySelector('[data-scripts]').dataset.pxuPolyfills);
}

// Polyfill NodeList.forEach if required.
// Polyfill is so small it doesn't need to load any external code.
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

if (!Element.prototype.toggleAttribute) {
  Element.prototype.toggleAttribute = (name, force) => {
    if (force !== undefined) {
      force = !!force;
    }

    if (this.hasAttribute(name)) {
      if (force) return true;

      this.removeAttribute(name);
      return false;
    }
    if (force === false) return false;

    this.setAttribute(name, '');
    return true;
  };
}

export default polyfillUrls;
