import $ from 'jquery';
import RichText from '../components/RichText';

import Shortcodes from '../shortcodes/Shortcodes';

export default class Page {
  constructor() {
    this.$pageContent = $('[data-template-page]');

    if (this.$pageContent.length) {
      new RichText(this.$pageContent.get(0));
      new Shortcodes(this.$pageContent[0]);
    }
  }
}
