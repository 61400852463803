export default class StaticAnnouncement {
  constructor(section) {
    // Since the announcement bar positioning is actually handled by the
    // StaticSectionHeader and StickyHeader classes, we need to let them know
    // when the announcement element is refreshed within the editor.
    if (window.Shopify && window.Shopify.designMode) {
      section.postMessage('announcement:load');
    }
  }
}
