import * as breakpoint from '@pixelunion/breakpoint';
import Header from './Header';

export default class TraditionalHeader extends Header {
  constructor(options, settings) {
    super(options, settings);

    this.body = document.querySelector('body');
    this.menuToggle = this.header.querySelector('[data-menu-toggle]');
    this.menu = this.section.querySelector('[data-site-navigation]');
    this.searchInput = this.header.querySelector('[data-live-search-input]');

    this.lastToggle = Date.now() - 1000;
    this.transitioning = false;

    this.navOpenClass = 'site-header-nav--open';
    this.scrolledClass = 'traditional-header--scrolled';

    // Setup Menu toggles
    this._toggleMenu = this._toggleMenu.bind(this);
    this._toggleStickyHeader = this._toggleStickyHeader.bind(this);

    this.toggleClick = e => {
      e.preventDefault();
      if (breakpoint.min('L')) this._toggleMenu();
    };

    this.menuToggle.addEventListener('click', this.toggleClick);

    // Setup Search Toggle for mobile

    this.searchInputFocused = e => {
      e.preventDefault();
      if (this.liveSearch && (breakpoint.max('S'))) {
        this.liveSearch.openMobileTakeover();
      }
    };

    this.searchInput.addEventListener('focus', this.searchInputFocused);

    // Setup sticky header collapse
    if (this.settings.sticky_header) {
      this.observer = new IntersectionObserver(entries => this._toggleStickyHeader(entries));
      this.observer.observe(document.querySelector('.intersection-target'));
    }
  }

  closeNavigation() {
    if (this.transitioning) { return; }

    this.menuToggle.classList.remove('active');

    this.navTransitionOutEvent = () => {
      this.header.classList.remove(this.navOpenClass);
      this.transitioning = false;
      this.menu.removeEventListener('transitionend', this.navTransitionOutEvent);
    };

    this.menu.addEventListener('transitionend', this.navTransitionOutEvent);

    this.transitioning = true;
    this.menu.setAttribute('style', `margin-top: -${this.menu.getBoundingClientRect().height}px;`);

    this.postMessage('nav:close-all');
  }

  openNavigation() {
    if (this.transitioning || this.header.classList.contains(this.navOpenClass)) { return; }

    this.menuToggle.classList.add('active');

    this.navTransitionInEvent = () => {
      this.transitioning = false;
      this.menu.removeEventListener('transitionend', this.navTransitionInEvent);
    };

    this.menu.addEventListener('transitionend', this.navTransitionInEvent);

    this.transitioning = true;

    // We need to wait for the browser to set the display to 'block' before we set the margin
    // This will help with ensuring the different animations/transitions happen in sequence
    // and not at the same time.
    window.requestAnimationFrame(() => {
      this.header.classList.add(this.navOpenClass);

      window.requestAnimationFrame(() => {
        this.menu.setAttribute('style', 'margin-top: 0;');
      });
    });
  }

  _toggleMenu() {
    if (this.header.classList.contains(this.navOpenClass)) {
      this.closeNavigation();
    } else {
      this.openNavigation();
    }
  }

  /**
   * Sticky header only shows as sticky after scroll
   *
   * @private
   */
  _toggleStickyHeader(entries) {
    if (this.body.classList.contains('scroll-lock') || !breakpoint.min('L')) {
      return;
    }

    const shouldShrink = !entries[0].isIntersecting;

    // Sticky header is scrolled, is and is visible -- nothing more to do!
    if (shouldShrink && this.header.classList.contains(this.scrolledClass)) {
      return;
    }

    // We also check to make sure the toggle hasnt activated recently to stop jerky transitions
    if (this.lastToggle + 500 > Date.now()) {
      return;
    }

    this.lastToggle = Date.now();

    if (shouldShrink) {
      this._shrink();
    } else {
      this._expand();
    }
  }

  _shrink() {
    this.closeNavigation();
    this.header.classList.add(this.scrolledClass);
  }

  _expand() {
    this.openNavigation();
    this.header.classList.remove(this.scrolledClass);
    this.menuToggle.classList.remove('active');
  }

  onSectionBlockSelect() {
    this.header.classList.add(this.navOpenClass);
    this.menuToggle.classList.add('active');
    this.menu.setAttribute('style', 'margin-top: 0;');
    this.openNavigation();
  }

  unload() {
    super.unload();
    this.body.classList.remove(this.scrolledClass);

    this.menuToggle.removeEventListener('click', this.toggleClick);
    this.searchInput.removeEventListener('focus', this.searchInputFocused);
    window.removeEventListener('scroll', this._toggleStickyHeader);

    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
