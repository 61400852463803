import $ from 'jquery';
import Flickity from 'flickity';
import * as breakpoint from '@pixelunion/breakpoint';
import initShopifyProductReviews from '../helpers/ProductReviews';
import ProductGridItem from '../components/ProductGridItem';

export default class DynamicFeaturedCollection {
  constructor(section) {
    this.section = section;
    this.$el = $(section.el);
    this.flickity = null;
    this.contentEl = section.el.querySelector('[data-content]');
    this.initialLayout = this.contentEl.dataset.layout;

    this._initFlickity = this._initFlickity.bind(this);
    this._destroyFlickity = this._destroyFlickity.bind(this);

    // Product items
    this.productItems = [];
    const $productItems = this.$el.find('[data-product-item]');

    $productItems.each((i, productItem) => {
      const productData = JSON.parse(productItem.querySelector('[data-product-data]').innerHTML);

      this.productItems.push(
        new ProductGridItem({
          $el: $(productItem),
          id: this.section.id,
          product: productData,
          lazy: true,
        }),
      );
    });

    const hasBanner = section.el.querySelector('[data-collection-banner]');
    let minProductCount = 5;

    if (hasBanner) {
      minProductCount = 4;
    }

    if (this.productItems.length > minProductCount && this.initialLayout === 'slideshow') {
      this._initFlickity();
    } else {
      this.onBreakpointChange = () => {
        const useSlideshow = breakpoint.max('L');

        if (useSlideshow) {
          this.contentEl.dataset.layout = 'slideshow';
          window.requestAnimationFrame(() => this._initFlickity());
        } else {
          this.contentEl.dataset.layout = 'grid';
          this._destroyFlickity();
        }
      };

      breakpoint.onChange(this.onBreakpointChange);

      if (breakpoint.max('L')) {
        window.requestAnimationFrame(() => this._initFlickity());
      }
    }

    if (this.$el.find('.spr-badge').length) {
      initShopifyProductReviews();
    }
  }

  _initFlickity() {
    if (this.flickity) return; // Already initialized

    this.flickity = new Flickity(
      this.contentEl,
      {
        autoPlay: 0,
        accessibility: true,
        cellAlign: 'left',
        cellSelector: '.productgrid--item',
        groupCells: true,
        pageDots: false,
        contain: true,
        arrowShape: 'M65.29 11.99L27.28 50L65.3 87.99L70.25 83.06L37.19 50L70.26 16.94L65.29 11.99Z',
      },
    );

    const { resize } = Flickity.prototype;
    const viewport = this.contentEl.querySelector('.flickity-viewport');
    const slider = this.contentEl.querySelector('.flickity-slider');

    /*
     * We must wrap Flickity's slider element to allow the usage of
     * clip, and clip-path to obscure the overflow product items.
     * To use clip, and clip-path, the clipped element must be absolutely
     * positioned. In this case, the only native Flickity element
     * that is absolutely positioned is the slider; but, it moves with the first
     * slide rather than remaining in the viewport removing it as a candidate.
     * Therefore, it is necessary to include a wrapper element that we can
     * absolutely position that remains within the viewport.
     * The only thing we need to watch out for is that the elements
     * are moved in a non-destructive manner.
     */
    const sliderWrapper = document.createElement('div');
    sliderWrapper.classList.add('flickity-slider--wrapper');
    viewport.appendChild(sliderWrapper);
    sliderWrapper.appendChild(slider);

    this.flickity.resize = function r() {
      viewport.style.height = '';
      resize.call(this);
    };

    this.$el.on('rimg:load', () => this.flickity.resize());
  }

  onSectionUnload() {
    this._destroyFlickity();
    this.productItems.forEach(productItem => {
      productItem.unload();
    });
  }

  _destroyFlickity() {
    if (!this.flickity) return; // Already uninitialized

    this.contentEl.dataset.layout = this.initialLayout;
    const viewport = this.contentEl.querySelector('.flickity-viewport');
    const slider = this.contentEl.querySelector('.flickity-slider');

    /*
     * Remember to move the Flickity native elements back
     * into the correct DOM layout, before removing the added
     * wrapper.
     */
    const sliderWrapper = this.contentEl.querySelector('.flickity-slider--wrapper');
    viewport.appendChild(slider);
    viewport.removeChild(sliderWrapper);

    this.flickity.destroy();
    this.flickity = null;
  }
}
