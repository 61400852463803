import $ from 'jquery';
import {
  trapFocus,
  removeTrapFocus,
} from '@shopify/theme-a11y';

import * as breakpoint from '@pixelunion/breakpoint';
import NavMobileSubMenus from './NavMobileSubMenus';

export default class NavMobile {
  constructor(elements) {
    this.$body = $(document.body);
    this.$window = $(window);
    this.$el = elements.$el;
    this.$toggleOpen = elements.$toggleOpen;
    this.isOpen = false;
    this.subMenus = null;

    this.$mobileNav = this.$el.find('[data-mobile-nav]');
    this.$panel = this.$el.find('[data-mobile-nav-panel]');
    this.$overlay = this.$el.find('[data-mobile-nav-overlay]');
    this.$toggleClose = this.$el.find('[data-mobile-nav-close]');

    // Revert navigation to original state on breakpoint change
    this.layoutHandler = this.onBreakpointChange.bind(this);
    breakpoint.onChange(this.layoutHandler);

    this._closeEsc = e => {
      if (e.key === 'Escape') {
        this._close();
      }
    };

    this._bindEvents();
  }

  unload() {
    this._close();
    this.$window.off('resize.mobile-nav');
    breakpoint.offChange(this.layoutHandler);
  }

  onBreakpointChange() {
    if (breakpoint.min('L') && this.isOpen) {
      this._close();
    }
  }

  _bindEvents() {
    this.$toggleOpen.on('click.mobile-nav', event => {
      event.preventDefault();

      if (!breakpoint.min('L')) this._open();
    });

    this.$window.on('resize.mobile-nav', () => {
      this.$el.find('[data-menu-toggle]').removeClass('active');

      if (this.isOpen) {
        this._setPanelHeight();
      }
    });
  }

  _setPanelHeight() {
    this.$panel.height(window.innerHeight);
  }

  _open() {
    this.$body.addClass('scroll-lock');
    this.isOpen = true;

    // Activate Submenu handler
    this.subMenus = new NavMobileSubMenus(this.$panel);
    this._setPanelHeight();

    // Animate in elements
    this.$mobileNav
      .addClass('animating animating-in')
      .one('trend', () => {
        this.$mobileNav
          .removeClass('animating animating-in')
          .addClass('visible')
          .off('trend');
      });

    trapFocus(this.$mobileNav[0]);

    // Event handlers
    window.addEventListener('keydown', this._closeEsc);

    this.events = [
      // Close menu when clicking on the overlay
      this.$overlay.on('click.mobile-nav', event => this._close(event)),
      this.$toggleClose.on('click.mobile-nav', event => this._close(event)),

      // Disable scrolling on overlay and contactbar
      this.$overlay.on('touchmove.mobile-nav', event => event.preventDefault()),
    ];
  }

  _close(event = false) {
    if (event) event.preventDefault();

    this.$mobileNav
      .addClass('animating animating-out')
      .one('trend', () => {
        this.$mobileNav
          .removeClass('animating animating-out')
          .removeClass('visible')
          .off('trend');
      });

    this.$body.removeClass('scroll-lock');
    this.isOpen = false;

    removeTrapFocus(this.$mobileNav);
    this.$toggleOpen.focus();

    // Close any open drop down menus
    if (this.subMenus) {
      this.subMenus.closeSubMenus(this.$el);
      this.subMenus.closeAllSubmenus();

      // Unbind Mobile sub menus
      this.subMenus.unload();
    }

    // Unbind events
    window.removeEventListener('keydown', this._closeEsc);

    if (this.events) {
      this.events.forEach($el => $el.off('.mobile-nav'));
      this.events = [];
    }
  }
}
