import $ from 'jquery';
import AsyncView from '@pixelunion/shopify-asyncview';
import ShopifyVariants from '@pixelunion/shopify-variants';
import Forms from '../Forms';
import AddToCartFlyout from './AddToCartFlyout';
import ProductGallery from './ProductGallery';
import PaymentTerms from '../helpers/PaymentTerms';

export default class ProductDetails {
  constructor(options) {
    this.$window = $(window);
    this.$formArea = options.$formArea;
    this.$details = options.$details;
    this.context = options.context;
    this.settings = options.settings;
    this.product = options.product;
    this.useHistory = options.useHistory;
    this.productEl = options.productEl;

    this.variantHelper = null;

    this.atcCallbacks = options.atcCallbacks;

    // Form
    this.$form = this.$formArea.find('[data-product-form]');
    this.$productAtcButton = this.$formArea.find('[data-product-atc]');
    this.$productVariants = this.$form.find('[data-variants]');
    this.productVariants = this.$form.get(0).querySelector('[data-variants]');
    this.$productOptions = this.$form.find('[data-product-option]');
    this.productOptions = this.$form.get(0).querySelectorAll('[data-product-option]');
    this.detailsLink = this.$formArea[0].querySelector('[data-product-details-link]');
    this.inStockNotificationForm = this.$formArea[0].querySelector('[data-in-stock-notification-form]');

    if (this.detailsLink) {
      this.detailsBaseHref = this.detailsLink.getAttribute('href');
    }

    this.variantFields = {
      $price: this.$details.find('[data-price]'),
      $priceMoney: this.$details.find('[data-price] .money'),
      $compareAtPrice: this.$details.find('[data-price-compare-at]'),
      $compareAtPriceMoney: this.$details.find('[data-price-compare-at] .money'),
      $badge: this.$details.find('[data-badge-sales]'),
      $badgeMoneySaved: this.$details.find('[data-price-money-saved]'),
      $badgePercentSaved: this.$details.find('[data-price-percent-saved]'),
      $stockLevel: this.$formArea.find('[data-stock-level]'),
      $sku: this.$details.find('[data-product-sku]'),
      unitPrice: this.$details[0].querySelector('[data-unit-price]'),
      totalQuantity: this.$details[0].querySelector('[data-total-quantity]'),
      unitPriceAmount: this.$details[0].querySelector('[data-unit-price-amount]'),
      unitPriceMeasure: this.$details[0].querySelector('[data-unit-price-measure]'),
      taxLine: this.$details[0].querySelector('[data-tax-line]'),
    };

    this.paymentTerms = new PaymentTerms(this.productEl);

    this.forms = new Forms(this.$form);

    if (this.inStockNotificationForm) {
      this.inStockForm = new Forms(this.inStockNotificationForm);
    }

    if (this.product && 'media' in this.product) {
      this.gallery = new ProductGallery({
        el: options.gallery,
        settings: this.settings,
        isQuickshop: options.isQuickshop,
        models: options.models,
      });
    }

    this._bindEvents();
    this._initOptions();
  }

  onQuickshopOpen() {
    if (this.gallery) {
      this.gallery.onQuickshopOpen();
    }
  }

  unloadAllMedia() {
    if (this.gallery) {
      this.gallery.unloadAllMedia();
    }
  }

  unload() {
    this.$form.off(`.product-details-${this.settings.section_id}`);
    this.$window.off(`.product-details-${this.settings.section_id}`);
    this.forms.unload();

    if (this.inStockForm) {
      this.inStockForm.unload();
    }

    if (this.variantHelper) {
      this.variantHelper.unload();
    }

    if (this.gallery) {
      this.gallery.unload();
    }
  }

  selectVariant(variantId) {
    if (variantId) {
      this.variantHelper.selectVariant(variantId);
    }
    if (this.gallery) {
      this.gallery.updateSlideHeight();
    }
  }

  _bindEvents() {
    this.$productOptions.on(`focus.product-details-${this.settings.section_id}`, () => {
      this.activeElement = document.activeElement;
    });
    this.$window.on(`shopify-variants:switch-variant.product-details-${this.settings.section_id}`, event => this._switchVariant(event));
    this.$form.on(`submit.product-details-${this.settings.section_id}`, event => this._AddToCartFlyout(event));
  }

  _initOptions() {
    if (!this.$productVariants.length) return;

    this.variantHelper = new ShopifyVariants(
      this.product,
      this.productVariants,
      this.productOptions,
      { mixedControls: true },
    );
  }

  _switchVariant(event) {
    const { product, variant, firstLoad } = event.detail;

    if (product !== this.product) return;

    this.paymentTerms.update(variant.id);

    if (firstLoad) {
      return;
    }

    // Refocus the active element (Shopify Variants causes element to lose focus)
    if (this.activeElement) {
      this.activeElement.focus();
    }

    // Update main select
    this.$productVariants.val(variant.id);
    if (this.gallery) {
      this.gallery.selectMediaByVariant(variant);
    }

    // Update Variant information
    this._updatePrice({ price: variant.price, compare_at_price: variant.compare_at_price });
    this._updateSKU(variant);
    this._updateBadge(variant);
    this._updateStockLevel(variant);
    this._updateButton(variant);
    this._updateSwatchLabel(variant);
    this._updateFullDetailsLink(variant);
    this._updateUnitPrice(variant);

    if (this.useHistory) {
      const url = `${this.product.handle}?${$.param({ variant: variant.id })}`;
      history.replaceState({}, 'variant', url);
    }
  }

  _updatePrice(price) {
    // Update compare at price
    const hasComparePrice = (
      !!price.compare_at_price && price.compare_at_price > price.price
    );
    this.variantFields.$compareAtPrice.toggleClass('visible', hasComparePrice);
    this.variantFields.$compareAtPriceMoney.text(Shopify.formatMoney(price.compare_at_price, this.settings.money_format));

    // Update price
    this.variantFields.$priceMoney.text(Shopify.formatMoney(price.price, this.settings.money_format));
  }

  _updateSKU(variant) {
    if (variant.sku === '') {
      this.variantFields.$sku.parent().addClass('product-sku--empty');
    } else {
      this.variantFields.$sku.parent().removeClass('product-sku--empty');
    }

    this.variantFields.$sku.text(variant.sku);
  }

  _updateBadge(variant) {
    const priceSaved = variant.compare_at_price ? variant.compare_at_price - variant.price : 0;
    this.variantFields.$badge.toggle(!!priceSaved);

    if (this.variantFields.$badgeMoneySaved.length) {
      // Update badge if it shows money saved
      this.variantFields.$badgeMoneySaved[0].innerHTML = Shopify.formatMoney(priceSaved, this.settings.money_format)
    }

    if (this.variantFields.$badgePercentSaved.length) {
      // Update badge if it shows percentiles
      const percentileSaved = Math.round((priceSaved * 100) / variant.compare_at_price);
      this.variantFields.$badgePercentSaved.text(percentileSaved);
    }
  }

  _updateStockLevel(variant) {
    if (!this.settings.enableStockBadge) return;

    let url = window.location.pathname;

    if (!url.includes('/products')) {
      url = `${url}/products/${this.product.handle}`
    }

    const params = {
      view: '_stock-level',
      variant: variant.id,
    }

    if (variant.inventory_management === null) {
      this.variantFields.$stockLevel.html('');
    } else {
      AsyncView.load(url, params)
        .then(({ html, data }) => {
          let fragment = document.createElement('div');
          fragment.innerHTML = html.stockLevel;

          const replacement = fragment.querySelector('.product--stock-level');

          this.variantFields.$stockLevel.html(replacement);
        });
    }
  }

  _updateButton(variant) {
    if (variant.available) {
      this.$productAtcButton.text(this.context.product_available);
      this.$productAtcButton
        .removeClass('disabled')
        .prop('disabled', false);
    } else {
      this.$productAtcButton.text(this.context.product_unavailable);
      this.$productAtcButton
        .addClass('disabled')
        .prop('disabled', true);
    }
  }

  _updateSwatchLabel(variant) {
    if (this.settings.enableSwatches) {
      const swatchLabel = this.$form[0].querySelector('[data-option-swatch-value]');
      if (swatchLabel) {
        swatchLabel.innerText = variant.options[parseInt(swatchLabel.dataset.optionSwatchValue, 10)];
      }
    }
  }

  _updateFullDetailsLink(variant) {
    if (this.detailsLink) {
      this.detailsLink.setAttribute('href', `${this.detailsBaseHref}?variant=${variant.id}`);
    }
  }

  _updateUnitPrice(variant) {
    if (this.variantFields.unitPrice && variant.unit_price_measurement) {
      this.variantFields.totalQuantity.innerHTML = `${variant.unit_price_measurement.quantity_value}${variant.unit_price_measurement.quantity_unit}`;

      this.variantFields.unitPriceAmount.innerHTML = Shopify.formatMoney(variant.unit_price, this.settings.money_format);
      if (variant.unit_price_measurement.reference_value === 1) {
        this.variantFields.unitPriceMeasure.innerHTML = variant.unit_price_measurement.reference_unit;
      } else {
        this.variantFields.unitPriceMeasure.innerHTML = `${variant.unit_price_measurement.reference_value}${variant.unit_price_measurement.reference_unit}`;
      }
      this.variantFields.unitPrice.classList.remove('hidden');
    } else if (this.variantFields.unitPrice) {
      this.variantFields.unitPrice.classList.add('hidden');
    }

    if (this.variantFields.taxLine) {
      if (variant.taxable) {
        this.variantFields.taxLine.classList.remove('hidden');
      } else {
        this.variantFields.taxLine.classList.add('hidden');
      }
    }
  }

  _AddToCartFlyout(event) {
    event.preventDefault();

    const formData = this.$form.serializeArray();

    const options = {
      $atcButton: this.$productAtcButton,
      settings: {
        moneyFormat: this.settings.money_format,
        cartRedirection: this.settings.cart_redirection,
      },
    };

    AddToCartFlyout.init(formData, this.product, options, this.atcCallbacks);
  }
}
