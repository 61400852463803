import $ from 'jquery';
import * as breakpoint from '@pixelunion/breakpoint';


export default class DynamicPromoBlocks {
  constructor(section) {
    this.$el = $(section.el);
    this.contentSelector = section.data.content_selector;
    this.blockSelector = section.data.block_selector;
    this.content = `[${this.contentSelector}]`;
    this.expandedClass = `${this.blockSelector}--expanded`;
    this.compressBlocks = section.data.compress_blocks;
    // Adjusts the height of the block so it can contain the wrapper within it
    this.adjustHeight = (block) => {
      const $block = $(block);
      const $wrapper = $block.find(`.${this.blockSelector}--content-wrapper`);

      const padding = window.getComputedStyle($block[0], null).getPropertyValue('padding-top').replace('px', '');

      if ($block.innerHeight() - (padding * 2) < $wrapper.innerHeight()) {
        $block.css({ height: `${$wrapper.innerHeight() + (padding * 2)}px` });
        $wrapper.css({ transform: 'none', top: 'auto' });
      }
    };

    // Removes height settings on the block because they only need to be there for small screens
    this.resetHeight = (block) => {
      const $block = $(block);
      const $wrapper = $block.find(`.${this.blockSelector}--content-wrapper`);
      $block.css({ height: '' });
      $wrapper.css({ transform: '', top: '' });
    };

    // Revert navigation to original state on breakpoint change
    this.layoutHandler = this.onBreakpointChange.bind(this);
    breakpoint.onChange(this.layoutHandler);

    this._blockInteraction = this._blockInteraction.bind(this);

    this.$el.on(`click.${this.blockSelector}`, this.content, this._blockInteraction);

    if (!this.compressBlocks && breakpoint.max('XS')) {
      this.$el.find(this.content).each((index, block) => {
        this.adjustHeight(block);
      });
    }
  }

  /**
   * Remove block's toggled state and attributes when leaving mobile
   */
  onBreakpointChange() {
    if (!breakpoint.max('XS')) {
      this.$el.find(`.${this.expandedClass}`).each((i, content) => {
        this._collapse(content);
      });
      this.$el.find(this.content).each((index, block) => {
        this.resetHeight(block);
      });
    } else if (!this.compressBlocks) {
      this.$el.find(this.content).each((index, block) => {
        this.adjustHeight(block);
      });
    }
  }

  /**
   * Unbind events when section is re-drawn
   */
  onSectionUnload() {
    this.$el.off(`.${this.blockSelector}`);
    breakpoint.offChange(this.layoutHandler);
  }

  /**
   * Callback to open block on mobile from the TE
   *
   * @param block
   */
  onSectionBlockSelect(block) {
    if (!breakpoint.max('XS')) return;

    this._expand(block.el.querySelector(this.content));
  }

  /**
   * Callback to close block on mobile from the TE
   *
   * @param block
   */
  onSectionBlockDeselect(block) {
    if (!breakpoint.max('XS')) return;

    this._collapse(block.el.querySelector(this.content));
  }

  /**
   * Expand a block on first click, then allow it to behave as normal
   *
   * @param event
   * @private
   */
  _blockInteraction(event) {
    const content = event.currentTarget;
    const clicked = content.getAttribute('data-clicked');

    // On second click, or on desktop, don't interfere with block
    if (clicked || !breakpoint.max('XS') || !this.compressBlocks) {
      return;
    }

    event.preventDefault();
    content.setAttribute('data-clicked', 'clicked');

    this._expand(content);
  }

  /**
   * Expand promo block
   *
   * @param content
   * @private
   */
  _expand(content) {
    if (!this.compressBlocks) {
      return;
    }

    const $content = $(content);

    $content
      .addClass('animating animating-in')
      .one('trend', () => {
        $content
          .removeClass('animating animating-in')
          .addClass(this.expandedClass)
          .off('trend');

        this.adjustHeight($content);
      });
  }

  /**
   * Collapse a block
   *
   * @param content
   * @private
   */
  _collapse(content) {
    if (!this.compressBlocks) {
      return;
    }

    const $content = $(content);

    $content
      .addClass('animating animating-out')
      .one('trend', () => {
        $content
          .removeClass(`animating animating-out ${this.expandedClass}`)
          .off('trend');
        content.removeAttribute('data-clicked');
        this.resetHeight($content);
      });
  }
}
