import $ from 'jquery';
import $script from 'scriptjs';
import * as breakpoint from '@pixelunion/breakpoint';
import Forms from '../Forms';

import RichText from '../components/RichText';
import ProductDetails from '../components/ProductDetails';

import Shortcodes from '../shortcodes/Shortcodes';

export default class StaticProduct {
  constructor(section) {
    this.section = section;
    this.postMessage = section.postMessage;
    this.context = section.data.context;
    this.settings = section.data.settings;
    this.product = section.data.product;
    this.models = section.data.models;
    this.richText = null;
    this.reviewForm = null;
    this.flickity = null;
    this.flickityOptions = null;

    this.$el = $(section.el);
    this.$product = this.$el.find('[data-product-wrapper]');

    if (this.settings) {
      this.isThreeColumn = this.settings.layout === 'layout--three-col';
    }

    // History
    this.isThemeEditor = window.Shopify && window.Shopify.designMode;
    this.useHistory = !this.isThemeEditor && window.history.replaceState && this.settings.enableHistory;

    const gallery = this.$product.find('[data-product-gallery]')[0];

    // Product details
    this.$details = this.$product.find('[data-product-details]');

    // Product description
    this.$description = this.$product.find('[data-product-description]');
    this.$readmore = this.$product.find('.product-readmore');

    // Product form containers
    this.$formRegular = this.$product.find('[data-product-form-regular]');
    this.$formAlt = this.$product.find('[data-product-form-alt]');

    // Product form area
    this.$formArea = this.$product.find('[data-product-form-area]');

    // Move product form and information on breakpoint change
    this.layoutHandler = this.onBreakpointChange.bind(this);
    breakpoint.onChange(this.layoutHandler);

    // Product reviews
    this.$productReviews = this.$el.find('[data-product-reviews]');

    this._moveForm();

    if (this.$description.length) {
      this.richText = new RichText(this.$description.get(0));
    }

    if (this.$readmore.length) {
      this.richText = new RichText(this.$readmore.get(0));
    }

    if (this.$productReviews.length) {
      this.reviewForm = new Forms(this.$productReviews, '.spr-form-input');
    }

    // Instantiate ProductDetails after Shopify API is loaded
    $script($('[data-scripts]').data('shopify-api-url'), () => {
      const options = {
        $formArea: this.$formArea,
        gallery,
        $details: this.$details,
        context: this.context,
        settings: this.settings,
        product: this.product,
        models: this.models,
        useHistory: this.useHistory,
        productEl: this.$el[0],
      };

      this.productDetails = new ProductDetails(options);
    });

    this.shortcodes = new Shortcodes(section.el);
  }

  onSectionUnload() {
    breakpoint.offChange(this.layoutHandler);
    this.productDetails.unload();

    if (this.richText) {
      this.richText.unload();
    }

    if (this.reviewForm) {
      this.reviewForm.unload();
    }

    if (this.shortcodes) {
      this.shortcodes.unload();
    }
  }

  onBreakpointChange() {
    this._moveForm();
  }

  /**
   * Move product form if is a three column layout
   * @private
   */
  _moveForm() {
    if (!this.isThreeColumn) return;

    if (breakpoint.min('L')) {
      if (!$.contains(this.$formAlt[0], this.$formArea[0])) {
        const $form = this.$formArea.detach();
        this.$formAlt.append($form);
      }
    } else {
      if (!$.contains(this.$formRegular[0], this.$formArea[0])) {
        const $form = this.$formArea.detach();
        this.$formRegular.append($form);
      }
    }
  }
}
