export default class Quantity {
  constructor(el) {
    this.$el = $(el);
    this.$inputParent = this.$el.find('.form-field--qty-input');
    this.$input = this.$el.find('[data-quantity-input]');
    this.$selectParent = this.$el.find('.form-field--qty-select');
    this.$select = this.$el.find('[data-quantity-select]');

    this._watchSelect = this._watchSelect.bind(this);
    this._watchInput = this._watchInput.bind(this);

    this.$select.on('change.quantity', this._watchSelect);
    this.$input.on('change.quantity', this._watchInput);
  }

  unload() {
    this.$el.off('.quantity');
  }

  _validateValue(event) {
    const baseValue = parseInt(event.currentTarget.value, 10);

    return isNaN(baseValue) ? 1 : baseValue;
  }

  _watchSelect(event) {
    const value = this._validateValue(event);

    // Update input to match select
    this.$input.val(value).trigger('change');

    // Switch to quantity input when 10 or more
    if (value >= 10) {
      this.$inputParent
        .removeClass('hidden')
        .addClass('visible');

      this.$input
        .focus()
        .removeAttr('tabindex')
        .select();

      this.$selectParent
        .removeClass('visible')
        .addClass('hidden');

      this.$select.attr('tabindex', '-1');
    }
  }

  _watchInput(event) {
    this.$input.val(this._validateValue(event));
  }
}
