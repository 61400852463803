import NavDesktopParent from './NavDesktopParent';

export default class NavDesktopMenu {
  constructor({ children }) {
    this.parents = [];
    this.children = children;

    this.closeSiblings = current => {
      this.parents.forEach(parent => {
        if (parent !== current) {
          parent.close();
        }
      });
    };

    for (let i = 0; i < this.children.length; i++) {
      if (this.children[i].dataset.navmenuParent !== undefined) {
        this.parents.push(
          new NavDesktopParent(
            this.children[i],
            {
              closeSiblings: this.closeSiblings,
            },
          ),
        );
      } else if (children[i].classList.contains('navmenu-item')) {
        this.children[i].addEventListener('focusin', this.closeSiblings);
      }
    }
  }

  openMeganavBlock(meganav) {
    this.parents.forEach(parent => { parent.openMeganavBlock(meganav); });
  }

  unload() {
    this.parents.forEach(parent => { parent.unload(); });
    for (let i = 0; i < this.children.length; i++) {
      this.children[i].removeEventListener('focusin', this.closeSiblings);
    }
  }
}
