import $ from 'jquery';

export default class SearchForm {
  constructor(container, options = {}) {
    this.$body = $('body');
    this.$container = $(container);
    this.$form = this.$container.find('[data-live-search-form]');
    this.isLiveSearch = options.liveSearch ? options.liveSearch : false;

    this.submitHandler = this.submitHandler.bind(this);

    this.events = [
      this.$form.on('submit.search-form', this.submitHandler),
    ];
  }

  unload() {
    this.events.forEach($el => $el.off('.search-form'));
  }

  submitHandler(event) {
    event.preventDefault();

    const $form = $(event.currentTarget).clone();
    const $terms = $form.find('[name=q]');

    $form.css({
      position: 'absolute',
      left: -1000,
      bottom: -1000,
      visibility: 'hidden',
    });

    let terms = $terms.val();

    if (this.isLiveSearch && !terms) {
      return;
    }

    // Adds `*` after each word in search terms, doesn't add to last word
    if (terms.indexOf(' ') > 0) {
      terms = terms.split(' ').join('* ').trim();
    }

    // Adds `*` at the end of the search terms
    terms = `${terms}*`;

    // Update value
    $terms.val(terms);

    // Forms must be in the browser context in order to submit
    this.$body.append($form);
    $form.submit();
  }
}
