export default class Shortcode {
  static get shortcode() {
    throw new Error('Shortcode.shortcode is not defined.');
  }

  static getShortcodes(el) {
    const shortcodeEls = el.querySelectorAll(this.shortcode);
    const shortcodes = [];

    for (let i = shortcodeEls.length - 1; i >= 0; i--) {
      const shortcodeEl = shortcodeEls[i];

      shortcodes.push(new this(shortcodeEl));
    }

    return shortcodes;
  }

  static isShortcode(el) {
    return el.tagName === this.shortcode;
  }

  constructor() {
    this.events = [];
  }

  unload() {
    this.events.forEach(({ el, event, listener }) => el.removeEventListener(event, listener));
  }

  _registerEvent({ el, event, listener }) {
    this.events.push({ el, event, listener });

    el.addEventListener(event, listener);

    return { el, event, listener };
  }

  _unregisterEvent({ el, event, listener }) {
    this.events.filter(e => el !== e.el || event !== e.event || listener !== e.listener);

    el.removeEventListener(event, listener);

    return { el, event, listener };
  }
}
