import $ from 'jquery';
import debounce from 'just-debounce';
import Flickity from 'flickity';
import * as breakpoint from '@pixelunion/breakpoint';


export default class DynamicBlogPosts {
  constructor(section) {
    this.$el = $(section.el);
    this.$window = $(window);
    this.flickity = null;

    this.$blogPosts = this.$el.find('[data-blog-posts]');

    // Activate flickity on mobile
    this._mobileSlider = this._mobileSlider.bind(this);
    breakpoint.onChange(this._mobileSlider);
    this._mobileSlider();
  }

  /**
   * Unbind events when section is re-drawn
   */
  onSectionUnload() {
    breakpoint.offChange(this._mobileSlider);
    this._destroyFlickity();
  }

  _initFlickity() {
    this.flickity = new Flickity(this.$blogPosts[0], {
      cellSelector: '.article--excerpt-wrapper',
      contain: true,
      freeScroll: true,
      percentPosition: false,
      prevNextButtons: false,
      pageDots: false,
      setGallerySize: false,
    });

    this._bindSlider();
    this.flickity.resize();
  }

  _destroyFlickity() {
    if (!this.flickity) {
      return;
    }

    this.$window.off('.blog-posts');
    this.$blogPosts.off('.blog-posts');
    this.flickity.destroy();
    this.flickity = null;
  }

  _mobileSlider() {
    // If is Large layout, attempt to destroy flickity
    if (breakpoint.min('L')) {
      this._destroyFlickity();
      return;
    }

    // Is XS/S/M, and flickity is init'd -- do nothing
    if (this.flickity) {
      this.flickity.resize();
      return;
    }

    // Is XS/S/M, and flickity is not init'd
    this._initFlickity();
  }

  _bindSlider() {
    const $slider = this.$blogPosts.find('.flickity-slider');

    this.$window.on('resize.blog-posts', debounce(() => {
      this.$blogPosts.trigger('heightUpdate.blog-posts');
    }));

    this.flickity.on('cellSelect', () => {
      this.$blogPosts.trigger('heightUpdate.blog-posts');
    });

    this.$blogPosts.on('heightUpdate.blog-posts', () => {
      if (!this.flickity) {
        return;
      }

      $slider.height(Math.ceil(this.flickity.maxCellHeight));
    });

    // Sets the Slider to the height of the first slide
    this.$blogPosts.trigger('heightUpdate.blog-posts');
  }
}
