import $ from 'jquery';
import rimg from '@pixelunion/rimg-shopify';
import AsyncView from '@pixelunion/shopify-asyncview';

import ProductGridItem from '../components/ProductGridItem';
import SearchForm from '../components/search/SearchForm';

export default class StaticSearch {
  constructor(section) {
    this.section = section;
    this.responseContainer = this.section.el.querySelector('[data-html]');
    this.$el = $(section.el);
    this.searchFieldSelector = '[data-live-search]';
    this.paginationSelector = 'a.pagination__item';
    this.$searchField = this.$el.find(this.searchFieldSelector);
    this.paginationLinks = this.section.el.querySelectorAll(this.paginationSelector);
    this.ajaxEvents = [];
    this.processing = false;

    this._ajaxFetch = this._ajaxFetch.bind(this);
    this._initProducts = this._initProducts.bind(this);

    this.paginationLinks.forEach(el => {
      this.ajaxEvents.push(
        $(el).on('click', this._ajaxFetch),
      );
    });

    // Product items
    this.productItems = [];
    this._initProducts();

    this.searchForm = new SearchForm(this.$searchField);

    window.onpopstate = event => { this._ajaxFetch(event); };
  }

  _ajaxFetch(event) {
    event.preventDefault();
    event.stopPropagation();

    let fetchUrl;
    let pageNumber;

    if (event.type === 'popstate') {
      fetchUrl = event.state.path;
      pageNumber = event.state.toPage;
    } else {
      fetchUrl = event.currentTarget
        .getAttribute('href')
        .replace('&view=undefined', '');
      pageNumber = event.currentTarget.getAttribute('data-to-page');
    }

    this.processing = true;
    this.responseContainer.classList.add('processing');
    $('html, body').animate({ scrollTop: 0 }, 'slow');

    AsyncView.load(fetchUrl, { view: '' })
      .then(({ html }) => {
        this.ajaxEvents.forEach($el => $el.off('click', this._ajaxFetch));
        this.ajaxEvents = [];

        this.responseContainer.innerHTML = html;

        this.searchForm.unload();
        this.$searchField = this.$el.find(this.searchFieldSelector);
        this.searchForm = new SearchForm(this.$searchField);

        rimg.watch(this.responseContainer);
        this._initProducts();

        this.paginationLinks = this.section.el.querySelectorAll(this.paginationSelector);
        this.paginationLinks.forEach(el => {
          this.ajaxEvents.push(
            $(el).on('click', this._ajaxFetch),
          );
        });

        if (event.type !== 'popstate') {
          const newUrl = `${window.location.protocol}//${window.location.host}${fetchUrl}`;
          try {
            window.history.pushState({ path: fetchUrl, toPage: pageNumber }, '', newUrl);
          } catch {
            // do nothing (we're probably in the editor)
          }
        }

        this.responseContainer.classList.remove('processing');
      });
  }

  _initProducts() {
    if (this.productItems.length > 0) {
      this.productItems.forEach(productItem => {
        productItem.unload();
      });

      this.productItems = [];
    }

    const $productItems = this.$el.find('[data-product-item]');

    $productItems.each((i, productItem) => {
      this.productItems.push(
        new ProductGridItem({
          $el: $(productItem),
          id: this.section.id,
          product: JSON.parse(productItem.querySelector('[data-product-data]').innerHTML),
          lazy: true,
        }),
      );
    });
  }

  onSectionUnload() {
    this.searchForm.unload();

    this.productItems.forEach(productItem => {
      productItem.unload();
    });
  }
}
