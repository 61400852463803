import $ from 'jquery';
import twitterFetcher from '@pixelunion/twitter-fetcher';
import debounce from 'just-debounce';
import Flickity from 'flickity';
import * as breakpoint from '@pixelunion/breakpoint';

import LazyLoader from '../helpers/LazyLoader';

export default class DynamicTwitterFeed {
  constructor(section) {
    this.$el = $(section.el);
    this.settings = section.data;
    this._load = this._load.bind(this);

    this.lazyLoader = new LazyLoader(section.el, this._load);
  }

  _load() {
    this.$window = $(window);
    this.flickity = null;

    this.$content = this.$el.find('[data-twitter-content]');
    this.$wrapper = this.$el.find('[data-twitter-wrapper]');
    this.$tweets = this.$el.find('[data-tweet-content]');

    this.$template = $(this.settings.template);

    const onboarding = this.settings.onboarding;
    const username = this.settings.username;
    const retweets = this.settings.retweets;
    const images = this.settings.images;
    const locale = this.settings.locale || 'en';

    // Activate flickity on mobile
    this._mobileSlider = this._mobileSlider.bind(this);
    breakpoint.onChange(this._mobileSlider);
    this._mobileSlider();

    if (onboarding || !username) {
      return;
    }

    twitterFetcher.fetch({
      'profile': { 'screenName': username },
      'maxTweets': 3,
      'enableLinks': true,
      'showUser': true,
      'showTime': true,
      'dataOnly': false,
      'useEmoji': true,
      'showImages': images,
      'showRetweet': retweets,
      'lang': locale,
      'customCallback': (tweets) => this._renderTweets(tweets),
      'showInteraction': false,
    });
  }

  /**
   * Unbind events when section is re-drawn
   */
  onSectionUnload() {
    this.$window.off('.twitter');
    this.$content.off('.twitter');

    breakpoint.offChange(this._mobileSlider);
    this._destroyFlickity();
    this.lazyLoader.unload();
  }

  _initFlickity() {
    this.flickity = new Flickity(this.$content[0], {
      cellSelector: '[data-tweet-content]',
      contain: true,
      freeScroll: true,
      percentPosition: false,
      prevNextButtons: false,
      pageDots: false,
      setGallerySize: false,
    });

    this._bindSlider();
    this.flickity.resize();
  }

  _destroyFlickity() {
    if (!this.flickity) {
      return;
    }

    this.flickity.destroy();
    this.flickity = null;
  }

  _mobileSlider() {
    // If is Large layout, attempt to destroy flickity
    if (breakpoint.min('L')) {
      this._destroyFlickity();
      return;
    }

    // Is XS/S/M, and flickity is init'd -- resize
    if (this.flickity) {
      this.flickity.resize();
      return;
    }

    // Is XS/S/M, and flickity is not init'd
    this._initFlickity();
  }

  _bindSlider() {
    const $slider = this.$content.find('.flickity-slider');

    this.$window.on('resize.twitter', debounce(() => {
      this.$content.trigger('heightUpdate.twitter');
    }));

    this.flickity.on('cellSelect', () => {
      this.$content.trigger('heightUpdate.twitter');
    });

    this.$content.on('heightUpdate.twitter', () => {
      if (!this.flickity) {
        return;
      }

      $slider.height(Math.ceil(this.flickity.maxCellHeight));
    });

    // Sets the Slider to the height of the first slide
    this.$content.trigger('heightUpdate.twitter');
  }

  /**
   * Retrieve information about user who tweeted
   *
   * @param el
   * @returns {{$avatar: *, link: *, name, screenName}}
   * @private
   */
  _extractAuthor(el) {
    const $el = $(el);

    const link = $el.find('a').attr('href');
    const name = $el.find('[data-scribe="element:name"]').text();
    const screenName = $el.find('[data-scribe="element:screen_name"]').text();
    const $avatar = $el.find('img');

    return { $avatar, link, name, screenName };
  }

  /**
   * Retrieve link to tweet, and when it was tweeted
   *
   * @param el
   * @returns {{link: *, postedAt: *}}
   * @private
   */
  _extractMeta(el) {
    const $el = $(el);

    const link = $el.find('a').attr('href');
    const postedAt = $el.text();

    return { link, postedAt };
  }

  _extractWrapperClass() {
    const wrapperClass = this.$wrapper.attr('class').match(/tweet--count-\d+$/);

    if (wrapperClass) {
      return wrapperClass[0];
    } else {
      return '';
    }
  }

  _renderTweets(tweets) {
    const tweetsLength = tweets.length;

    if (!tweets || !tweetsLength) return;

    const tweetsArray = [];
    const wrapperClass = `tweet--count-${tweetsLength}`;

    tweets.forEach((tweet) => {
      const $tweet = $(tweet);

      const $template = this.$template.clone();
      const authorInfo = this._extractAuthor($tweet[0]);
      const meta = this._extractMeta($tweet[2]);

      const content = $tweet[1];

      // Render header
      $template
        .find('.tweet--header')
        .attr('href', authorInfo.link);

      $template
        .find('.tweet--header-image')
        .html(authorInfo.$avatar);

      $template
        .find('.tweet--header-name')
        .text(authorInfo.name);

      $template
        .find('.tweet--header-screenname')
        .text(authorInfo.screenName);

      // Render content
      $template
        .find('.tweet--content')
        .append($tweet[3] ? $tweet[3] : null)
        .append(content);

      // Render footer
      $template
        .find('.tweet--footer')
        .attr('href', meta.link)
        .find('.tweet--footer--posted')
        .text(meta.postedAt);

      tweetsArray.push($template);
    });

    this.$wrapper.css('min-height', this.$wrapper.height());

    this.$tweets.fadeOut().promise().then(() => {
      this._destroyFlickity();
      this.$tweets.remove();

      this.$content.append(tweetsArray);

      this.$wrapper
        .removeClass(this._extractWrapperClass())
        .addClass(wrapperClass)
        .css('min-height', '');

      this.$tweets = this.$el.find('[data-tweet-content]');

      this._mobileSlider();
    });
  }
}
