import $script from 'scriptjs';
import { createMap, displayErrorInThemeEditor } from '../helpers/mapUtils';
import LazyLoader from '../helpers/LazyLoader';

export default class DynamicMap {
  constructor(section) {
    const initMap = () => {
      this.map = null;

      const el = section.el.querySelector('[data-map]');
      const container = el.querySelector('[data-map-container]');
      const settings = section.data;
      const { address, colors } = settings;
      const apiKey = settings.api_key;
      // Scale so range is 12 ~ 17, rather than 1 to 6
      const zoom = Number.isNaN(settings.zoom) ? 13 : 11 + parseInt(settings.zoom, 10);

      if (apiKey) {
        if (window.googleMaps === undefined) {
          $script(`https://maps.googleapis.com/maps/api/js?key=${apiKey}`, () => {
            window.googleMaps = true;
            createMap({
              container,
              address,
              zoom,
              colors,
            }).done((map) => {
              this.map = map;
            }).fail(error => displayErrorInThemeEditor(container, error));
          });
        } else {
          createMap({
            container,
            address,
            zoom,
            colors,
          }).done((map) => {
            this.map = map;
          }).fail(error => displayErrorInThemeEditor(container, error));
        }
      }
    };

    this.lazyLoader = new LazyLoader(section.el, initMap);
  }

  onSectionUnload() {
    this.map = null;
    this.lazyLoader.unload();
  }
}
