import $ from 'jquery';
import rimg from '@pixelunion/rimg-shopify';
import AsyncView from '@pixelunion/shopify-asyncview';
import 'intersection-observer';

import initShopifyProductReviews from '../helpers/ProductReviews';
import ProductGridItem from '../components/ProductGridItem';

export default class StaticCollectionMinimal {
  constructor(section) {
    this.el = section.el;
    this.id = section.id;

    // Pagination
    this.paginationStyle = section.data.pagination_style;
    this.clickToLoadTrigger = this.el.querySelector('[data-click-to-load]');
    this.clickToLoad = this.el.querySelector('.click-to-load');
    this.infiniteScrollTrigger = this.el.querySelector('[data-infinite-scroll]');
    this.infiniteScroll = this.el.querySelector('.infinite-scroll');
    this.paginationLinks = this.el.querySelectorAll('a.pagination__item');
    this.responseContainer = this.el.querySelector('.results--container');
    this.paginationContainer = this.responseContainer.querySelector('.pagination__wrapper:not(.visually-hidden)');
    this.productGrid = this.responseContainer.querySelector('.productgrid--items');
    this.toPage = null;
    // Product Cards
    this.productItemEls = this.productGrid.querySelectorAll('[data-product-item]');
    this.productItems = [];

    this._processing = this._processing.bind(this);
    this._bindEvents = this._bindEvents.bind(this);
    this._unbindEvents = this._unbindEvents.bind(this);
    this._initProductCards = this._initProductCards.bind(this);
    this._updateHistory = this._updateHistory.bind(this);
    this._fetchProductCards = this._fetchProductCards.bind(this);

    this._initProductCards(this.productItemEls);
    this._bindEvents();
  }

  _bindEvents() {
    if (this.clickToLoadTrigger) {
      this.clickToLoadTrigger.addEventListener('click', this._fetchProductCards);
    } else if (this.infiniteScrollTrigger) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0) {
            this._fetchProductCards(false);
          }
        });
      });

      observer.observe(this.infiniteScrollTrigger);
    } else {
      this.paginationLinks.forEach(link => {
        link.addEventListener('click', this._fetchProductCards);
      });
    }
  }

  _unbindEvents() {
    if (this.clickToLoadTrigger) {
      this.clickToLoadTrigger.removeEventListener('click', this._fetchProductCards);
    } else {
      this.paginationLinks.forEach(link => {
        link.removeEventListener('click', this._fetchProductCards);
      });
    }
  }

  onSectionUnload() {
    this._unbindEvents();
    this.recentlyViewed.unload();

    this.productItems.forEach(productItem => {
      productItem.unload();
    });
  }

  _initProductCards(productEls) {
    for (let i = 0; i < productEls.length; i++) {
      const productItem = productEls[i];
      this.productItems.push(
        new ProductGridItem({
          $el: $(productItem),
          id: this.id,
          product: JSON.parse(productItem.querySelector('[data-product-data]').innerHTML),
          queryParams: { page: this.toPage },
          lazy: true,
        }),
      );
    }

    if ($('.spr-badge', this.el)) {
      initShopifyProductReviews();
    }
  }

  _processing(isProcessing) {
    if (isProcessing && this.paginationStyle === 'traditional') {
      this.responseContainer.classList.add('processing--filter');

      const y = $(window).scrollTop();
      if (y >= 310) {
        $('html, body')
          .animate({ scrollTop: y - 300 }, 150)
          .animate({ scrollTop: 10 }, 0)
          .animate({ scrollTop: 0 }, 150);
      } else {
        $('html, body').animate({ scrollTop: 0 }, 150);
      }
    } else if (isProcessing && this.paginationStyle !== 'traditional') {
      this.responseContainer.classList.add('processing--pagination');
    } else if (!isProcessing && this.paginationStyle === 'traditional') {
      this.responseContainer.classList.remove('processing--filter');
    } else {
      this.responseContainer.classList.remove('processing--pagination');
    }
  }

  _fetchProductCards(event) {
    this._processing(true);

    this.toPage = event ? event.target.dataset.toPage : this.infiniteScrollTrigger.dataset.toPage;

    if (event) {
      event.preventDefault();
    }

    const fetchUrl = window.location.pathname;

    const params = {
      page: this.toPage,
      view: '_ajax-minimal'
    };

    AsyncView.load(fetchUrl, params)
      .then(({ html }) => {
        const fragment = document.createDocumentFragment();
        const productItems = document.createElement('div');

        productItems.innerHTML = html.productgridItems;

        while (productItems.firstChild) {
          fragment.appendChild(productItems.firstChild);
        }

        if (this.paginationStyle === 'traditional') {
          this.productItems.forEach(item => item.unload());
          this.productItems = [];
          this.responseContainer.innerHTML = html.productgrid;
          this._initProductCards(this.responseContainer.querySelectorAll('[data-product-item]'));
        } else {
          const newProductItems = fragment.querySelectorAll('[data-product-item]');
          this.productGrid.appendChild(fragment);
          this._initProductCards(newProductItems);
        }

        this.paginationContainer.innerHTML = html.pagination;
        rimg.watch(this.responseContainer);

        this._unbindEvents();

        this.clickToLoadTrigger = this.el.querySelector('[data-click-to-load]');
        this.clickToLoad = this.el.querySelector('.click-to-load');
        this.infiniteScrollTrigger = this.el.querySelector('[data-infinite-scroll]');
        this.infiniteScroll = this.el.querySelector('.infinite-scroll');
        this.paginationLinks = this.el.querySelectorAll('a.pagination__item');

        this._bindEvents();

        this._updateHistory(fetchUrl, params);

        this._processing(false);
      });
  }

  _updateHistory(url, params) {
    if ((window.Shopify && window.Shopify.designMode) || !window.history.pushState) {
      return;
    }

    const href = `${url}?page=${params.page}`;

    window.history.pushState({}, '', href);
  }
}
