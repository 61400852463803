import $ from 'jquery';

import Modal from '../components/Modal';
import Forms from '../Forms';

export default class StaticPassword {
  constructor(section) {
    this.$el = $(section.el);

    this.modalForms = null;
    this.newsletterForm = null;
    this.modalContents = '[data-passwordentry]';
    this.$newsletterForm = this.$el.find('[data-password-newsletter]');

    this.modalOpen = this.onModalOpen.bind(this);
    this.modalClose = this.onModalClose.bind(this);
    this._openModal = this._openModal.bind(this);

    this.modal = new Modal({
      onOpen: this.modalOpen,
      onClose: this.modalClose,
    });

    if (this.$newsletterForm) {
      this.newsletterForm = new Forms(this.$newsletterForm);
    }

    this._checkForPasswordAttempt();
    this._bindEvents();
  }

  onSectionUnload() {
    this.$el.off('.static-password');

    if (this.newsletterForm) {
      this.newsletterForm.unload();
    }

    if (this.modalForms) {
      this.modalForms.unload();
    }
  }

  onModalOpen() {
    const $contents = $('[data-passwordentry-contents]');
    this.modalForms = new Forms($contents);
  }

  onModalClose() {
    this.modalForms.unload();
    this.modalForms = null;
  }

  _bindEvents() {
    this.$el.on('click.static-password', '[data-passwordentry-toggle]', this._openModal);
  }

  _openModal() {
    this.modal.open(this.modalContents, 'passwordentry');
  }

  _checkForPasswordAttempt() {
    if (!$('[data-passwordentry-errors]').length) {
      return;
    }

    this._openModal();
  }
}
