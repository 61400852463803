import $ from 'jquery';
import * as breakpoint from '@pixelunion/breakpoint';
import Disclosure from '@pixelunion/shopify-cross-border';

import Accordion from '../Accordion';
import Forms from '../Forms';

export default class StaticSectionFooter {
  constructor(section) {
    this.$el = $(section.el);
    this.el = section.el;
    this.currencyDisclosureEl = this.el.querySelector('[data-disclosure-currency]');
    this.localeDisclosureEl = this.el.querySelector('[data-disclosure-locale]');
    this.disclosures = [];

    this.Accordion = new Accordion(this.$el);
    this.forms = new Forms(this.$el);

    if (this.currencyDisclosureEl) {
      this.disclosures.push(new Disclosure(this.currencyDisclosureEl));
    }

    if (this.localeDisclosureEl) {
      this.disclosures.push(new Disclosure(this.localeDisclosureEl));
    }

    // Handle Accordion interaction when window size changes
    this.layoutHandler = this.onBreakpointChange.bind(this);
    breakpoint.onChange(this.layoutHandler);

    this._bindEvents();
  }

  _bindEvents() {
    this.$el.on('click.footer', '[data-accordion-trigger]', event => {
      event.preventDefault();
      this._toggleAccordion($(event.currentTarget).parent());
    });
  }

  onSectionUnload() {
    this.$el.off('.footer');
    this.forms.unload();
    this.disclosures.forEach(disclosure => disclosure.unload());

    delete this.Accordion;
  }

  onSectionBlockSelect(block) {
    const $block = $(block.el);

    if ($block.hasClass('has-accordion')) this._toggleAccordion($block, true);
  }

  onSectionBlockDeselect(block) {
    const $block = $(block.el);

    if ($block.hasClass('has-accordion')) this._toggleAccordion($block, false);
  }

  _toggleAccordion($block) {
    if (breakpoint.min('L')) return;

    this.Accordion.toggle($block);
  }

  onBreakpointChange(breakpoints) {
    if (breakpoint.min('L')) {
      this.Accordion.openAll();
    } else if (breakpoints.previous.value === 'L' && !breakpoint.min('L')) {
      this.Accordion.closeOpen();
    }
  }
}
