import PhotoSwipe from 'photoswipe';
import EventHandler from '@pixelunion/events';
import rimg from '@pixelunion/rimg-shopify';
import {
  trapFocus,
  removeTrapFocus,
} from '@shopify/theme-a11y';

import photoswipeUi from 'photoswipe/dist/photoswipe-ui-default';

import layout from '../Layout';

export default class ProductClicktoZoom {
  constructor({
    settings,
    slides,
    originalThumbnails,
    startIndex,
    selectGalleryIndex,
  }) {
    this.settings = settings;

    this.photoswipeDialog = document.querySelector('[data-photoswipe]');
    this.thumbContainer = this.photoswipeDialog.querySelector('[data-photoswipe-thumbs]');
    this.thumbScroller = this.photoswipeDialog.querySelector('[data-photoswipe-thumb-scroller]');
    this.thumbScrollerButtons = this.photoswipeDialog.querySelectorAll('[data-gallery-scroll-button]');
    this.closeButton = this.photoswipeDialog.querySelector('.pswp__button--close');
    this.selectedSlide = slides[startIndex];

    this.thumbScroller.innerHTML = '';
    this.thumbnails = [];

    let selectedIndex = startIndex;

    // Clone the original thumbnails into the photoswipe dialog
    originalThumbnails.forEach((thumb, thumbIndex) => {
      if (thumb.dataset.mediaType !== 'image') {
        if (startIndex > thumbIndex) selectedIndex--;
        return;
      }
      const newThumb = thumb.cloneNode(true);
      this.thumbScroller.appendChild(newThumb);
      this.thumbnails.push(newThumb);
    });

    rimg.watch(this.thumbContainer);

    this.selectedThumb = this.thumbContainer.querySelector('[data-gallery-selected="true"]');

    this.events = new EventHandler();

    // Set up options for initializing PhotoSwipe
    const photoswipeSlides = [];

    slides.forEach(slide => {
      if (['video', 'external_video', 'model'].indexOf(slide.dataset.mediaType) > -1) return;
      photoswipeSlides.push({
        src: slide.dataset.zoom,
        msrc: slide.dataset.zoom,
        h: slide.dataset.imageHeight,
        w: slide.dataset.imageWidth,
      });
    });

    // Adjust the bottom bar height to allow for the mobile thumbnails
    const bottomBarHeight = layout.isGreaterThanBreakpoint('L', true) ? 0 : 75;

    const photoswipeOptions = {
      index: selectedIndex,
      barsSize: { top: 0, bottom: bottomBarHeight },
      fullscreenEl: false,
      zoomEl: false,
      shareEl: false,
      counterEl: false,
      arrowEl: false,
      preloaderEl: false,
      closeOnScroll: false,
      showHideOpacity: true,
      history: false,
      loop: true,
      clickToCloseNonZoomable: false,
      timeToIdle: false,
      timeToIdleOutside: false,
    };

    if (this.settings.click_to_zoom === 'always'
      || (layout.isGreaterThanBreakpoint('S', true) && this.settings.click_to_zoom === 'desktop')
      || (layout.isLessThanBreakpoint('S') && this.settings.click_to_zoom === 'mobile')) {
      photoswipeOptions.getThumbBoundsFn = () => {
        const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
        const bounds = this.selectedSlide.querySelector('[data-rimg]').getBoundingClientRect();
        return { x: bounds.left, y: bounds.top + pageYScroll, w: bounds.width };
      };
    }

    this.photoswipe = new PhotoSwipe(
      this.photoswipeDialog,
      photoswipeUi,
      photoswipeSlides,
      photoswipeOptions,
    );

    this.events.register(this.closeButton, 'click', () => this.photoswipe.close());
    // The following fixes an issue on iOS12 and below, where click events were not registering
    this.events.register(this.closeButton, 'touchstart', () => this.photoswipe.close());

    this.thumbnails.forEach((thumb, index) => {
      this.events.register(thumb, 'click', () => {
        this.photoswipe.goTo(index);
      });
    });

    this.events.register(this.thumbScroller, 'scroll', () => this._handleScrollButtonVisibility());
    this.events.register(this.thumbScrollerButtons[0], 'click', () => this._onScrollButtonClick(true));
    this.events.register(this.thumbScrollerButtons[1], 'click', () => this._onScrollButtonClick(false));

    // Photoswipe documentation claims that the closeOnVerticalDrag option is always false if
    // the mouse is used, which is what we want. Unfortunately that's not how it actually works,
    // so we need to dynamically update the setting when a mouse is detected.
    if (this.photoswipe.options.mouseUsed) {
      this.photoswipe.options.closeOnVerticalDrag = false;
    } else {
      this.photoswipe.listen('mouseUsed', () => {
        this.photoswipe.options.closeOnVerticalDrag = false;
      });
    }

    this.photoswipe.listen('afterChange', () => {
      const index = this.photoswipe.getCurrentIndex();
      if (this.selectedThumb) {
        this.selectedThumb.dataset.gallerySelected = false;
      }
      this.selectedThumb = this.thumbnails[index];
      this.selectedThumb.dataset.gallerySelected = true;

      const slideIndex = this.selectedThumb.getAttribute('data-gallery-index');
      const slide = slides[slideIndex];

      this._adjustMobileThumbPosition();
      this._handleScrollButtonVisibility();

      selectGalleryIndex(slideIndex);
      this.selectedSlide = slide;
    });

    this.photoswipe.listen('close', () => {
      removeTrapFocus(this.photoswipeDialog);
      this.selectedSlide.focus();
      this.photoswipe = null;
    });

    this.photoswipe.listen('destroy', () => {
      this.events.unregisterAll();
      this.photoswipe = null;
    });

    // Detect if mouse is over the thumbnails, if it is allow scroll left/right
    this.events.register(this.thumbContainer, 'mouseenter', () => {
      if (layout.isLessThanBreakpoint('S')) {
        this.photoswipe.options.closeOnScroll = true;
      }
    });

    this.events.register(this.thumbContainer, 'mouseleave', () => {
      if (layout.isLessThanBreakpoint('S')) {
        this.photoswipe.options.closeOnScroll = false;
      }
    });

    // If a user closes the dialog using the escape key, then we should focus
    // on the expand button.
    const closeEsc = e => {
      if (e.key === 'Escape') {
        slides[0].parentNode.querySelector('[data-gallery-expand]').focus();
      }
    };

    this.events.register(window, 'keydown', e => closeEsc(e));

    this.photoswipe.init();

    trapFocus(this.photoswipeDialog);
  }

  _adjustMobileThumbPosition() {
    // This brings the thumbnail into view if it is not visible on the screen after
    // a user swipes to a slide that it is tied to.
    if (layout.isLessThanBreakpoint('S')) {
      const thumbBounds = this.selectedThumb.getBoundingClientRect();
      const thumbWrapperBounds = this.thumbScroller.getBoundingClientRect();

      if (thumbBounds.left + thumbBounds.width + 30 > thumbWrapperBounds.width) {
        this.thumbScroller.scrollLeft = this.selectedThumb.offsetLeft
          + thumbBounds.width - thumbWrapperBounds.width + 35;
      } else if (this.selectedThumb.offsetLeft < this.thumbScroller.scrollLeft) {
        this.thumbScroller.scrollLeft = this.selectedThumb.offsetLeft - 35;
      }
    }
  }

  _handleScrollButtonVisibility() {
    if (layout.isLessThanBreakpoint('S')) {
      // We use 4px here just to ensure the user has scrolled a little bit before
      // showing the buttons.
      if (this.thumbScroller.scrollLeft > 4) {
        this.thumbScrollerButtons[0].classList.add('visible');
      } else {
        this.thumbScrollerButtons[0].classList.remove('visible');
      }

      if (this.thumbScroller.scrollLeft < this.thumbScroller.scrollWidth
        - this.thumbScroller.clientWidth) {
        this.thumbScrollerButtons[1].classList.add('visible');
      } else {
        this.thumbScrollerButtons[1].classList.remove('visible');
      }
    } else {
      this.thumbScrollerButtons[0].classList.remove('visible');
      this.thumbScrollerButtons[1].classList.remove('visible');
    }
  }

  _onScrollButtonClick(scrollRight) {
    if (scrollRight) {
      this.thumbScroller.scrollLeft = this.thumbScroller.scrollLeft - 100;
    } else {
      this.thumbScroller.scrollLeft = this.thumbScroller.scrollLeft + 100;
    }
  }

  unload() {
    if (this.photoswipe) {
      this.photoswipe.destroy();
      this.photoswipe = null;
    }
  }
}
