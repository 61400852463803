import $ from 'jquery';

import Forms from '../Forms';

export default class Contact {
  constructor() {
    this.$contactContents = $('[data-template-contact]');

    if (this.$contactContents.length) {
      new Forms(this.$contactContents);
    }
  }
}
