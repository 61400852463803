export default function scrollLeft($el) {
  const el = $el.get(0);
  const container = el.parentElement;
  const $container = $el.parent();

  if (!container) {
    return;
  }

  const containerBounds = container.getBoundingClientRect();
  const containerCenter = containerBounds.left + (containerBounds.width / 2);
  const elBounds = el.getBoundingClientRect();
  const elCenter = elBounds.left + (elBounds.width / 2);
  const scrollAmount = container.scrollLeft - (containerCenter - elCenter);

  $container.animate({
    scrollLeft: scrollAmount,
  });
}
