import $ from 'jquery';
import * as breakpoint from '@pixelunion/breakpoint';

import Forms from '../Forms';
import TraditionalHeader from '../components/header/TraditionalHeader';
import MinimalHeader from '../components/header/MinimalHeader';
import BasicHeader from '../components/header/BasicHeader';
import NavMobile from '../components/navigation/NavMobile';
import MegaNavScroller from '../components/navigation/MegaNavScroller';
import NavDesktopMenu from '../components/navigation/NavDesktopMenu';

export default class StaticSectionHeader {
  constructor(section) {
    this.$el = $(section.el);
    this.settings = section.data.settings;
    this.postMessage = section.postMessage;
    this.headerSearch = null;

    this.$scripts = $('[data-scripts]');

    this.$menuToggle = this.$el.find('[data-menu-toggle]');
    this.$cartCount = this.$el.find('[data-header-cart-count]');
    this.$searchField = this.$el.find('[data-live-search]');

    this.$siteNavigation = this.$el.find('[data-site-navigation]');
    this.$desktopNavigation = this.$siteNavigation.find('ul.navmenu-depth-1');
    this.$headerTools = this.$siteNavigation.find('[data-header-actions]');

    const headerProperties = {
      section: section.el,
      postMessage: this.postMessage,
      $scripts: this.$scripts,
    };

    switch (this.settings.header_layout) {
      case 'minimal':
        this.header = new MinimalHeader(headerProperties, this.settings);
        break;
      case 'basic':
        this.header = new BasicHeader(headerProperties, this.settings);
        break;
      case 'traditional':
        this.header = new TraditionalHeader(headerProperties, this.settings);
        break;
      default:
        this.header = new TraditionalHeader(headerProperties, this.settings);
        break;
    }

    this.megaNavs = {};
    const meganavMenus = document.querySelectorAll('[data-meganav-menu]');
    for (let i = 0; i < meganavMenus.length; i++) {
      const el = meganavMenus[i];
      const id = el.getAttribute('data-meganav-id');
      this.megaNavs[id] = new MegaNavScroller($(el));
    }

    this.navMobile = new NavMobile({
      $el: this.$el,
      $toggleOpen: this.$menuToggle,
    });

    this.navDesktop = new NavDesktopMenu(document.querySelector('.site-navigation > [data-navmenu]'));

    this.forms = new Forms(this.$el);

    this.$el.on('cartcount:update', (event, data) => {
      this.$cartCount
        .attr('data-header-cart-count', data.response.item_count)
        .toggleClass('visible', data.response.item_count > 0);
    });
  }

  onSectionDeselect() {
    this._closeAllMeganavs();
    this.header.sectionDeselect();
  }

  onSectionUnload() {
    this.header.unload();
    this.navMobile.unload();
    this.navDesktop.unload();
    this.forms.unload();

    this.$el.off('cartcount:update');
  }

  onSectionMessage(name, data) {
    if (name === 'nav:close-all' && breakpoint.min('L')) {
      this._closeAllMeganavs();
    }
  }

  onSectionBlockSelect(block) {
    if (!breakpoint.min('L')) {
      return;
    }

    this.header.onSectionBlockSelect(block);
    this.navDesktop.openMeganavBlock(this.megaNavs[block.id].$el[0]);
  }

  onSectionBlockDeselect(block) {
    this.header.onSectionBlockDeselect(block);

    if (this.megaNavs[block.id]) {
      this.megaNavs[block.id].$el.removeClass('meganav-editing-block');
    }
  }

  _closeAllMeganavs() {
    const keys = Object.keys(this.megaNavs);
    for (let i = 0; keys.length < i; i++) {
      this.megaNavs[keys[i]].$el.removeClass('meganav-editing-block');
    }
  }
}
