import $ from 'jquery';
import debounce from 'just-debounce';

import Search from '../../Search';
import SearchForm from './SearchForm';

import {
  ContentResults,
  ContentNoResults,
  ProductResults,
  ProductNoResults,
  ResultsFooter,
} from './LiveSearchTemplates';

const MAX_RESULTS = 5;

export default class LiveSearch {
  constructor(els, settings) {
    this.$el = $(els.el);
    this.$body = $('body');
    this.$header = $(els.header);
    this.$document = $(document);

    this.settings = settings;
    this.formOptions = { liveSearch: true };
    this.contentTypes = this.settings.content_types;
    this.enableImages = true;
    this.flyDownActive = false;
    this.takeOverActive = false;
    this.showFlyDown = false;

    this.searchOpenClass = 'site-header-search--open';

    this.$form = this.$el.find('[data-live-search-form]');
    this.$input = this.$form.find('[data-live-search-input]');
    this.$button = this.$form.find('[data-live-search-submit]');
    this.$takeOverCancel = this.$form.find('[data-live-search-takeover-cancel]');

    this.$flyDown = this.$el.find('[data-live-search-flydown]');
    this.$searchResults = this.$flyDown.find('[data-live-search-results]');
    this.$searchPlaceholder = this.$flyDown.find('[data-live-search-placeholder]');
    this.$quickLinks = this.$flyDown.find('[data-live-search-quick-links]');

    this.staticSearch = new SearchForm(this.$el, this.formOptions);

    this.search = new Search({
      view: 'header',
      type: this.contentTypes,
      onCancel: this._searchCancelled.bind(this),
      onComplete: this._searchComplete.bind(this),
      onError: this._searchError.bind(this),
    });

    this._search = this._search.bind(this);
    this._toggleTakeOver = this._toggleTakeOver.bind(this);
    this._searchFocused = this._searchFocused.bind(this);
    this._documentFocus = this._documentFocus.bind(this);

    this._closeEsc = e => {
      if (e.key === 'Escape') {
        e.stopPropagation();
        this.$input.focus();
        this._closeFlyDown(true);
      }
    };

    this.events = [
      this.$button.on('click.live-search', event => {
        event.preventDefault();
        this.$form.submit();
      }),
      this.$input.on('keyup.live-search', debounce(this._search, 250)),
      this.$input.on('focus.live-search', this._searchFocused),
      this.$takeOverCancel.on('click.live-search', this._toggleTakeOver),
    ];
  }

  unload() {
    this.events.forEach($el => $el.off('.live-search'));

    this.staticSearch.unload();
  }

  /**
   * If is searching, only show placeholder
   *
   * @private
   */
  _flyDownSearching() {
    this.$searchResults.removeClass('visible');
    this.$quickLinks.removeClass('visible');

    this.$searchPlaceholder.addClass('visible');
  }

  /**
   * Search field has been focused, or has been cleared of contents
   *   - If previous search contents, show
   *   - If no previous search contents, show quicklinks if exists
   *
   * @param hasTerms
   * @private
   */
  _flyDownInitial(hasTerms) {
    this.showFlyDown = false;

    const hasNoResults = this.$searchResults.find('[data-live-search-no-products]').length === 0;
    const hasPreviousResults = this.$searchResults.children().length > 0;

    if (hasTerms && hasNoResults && hasPreviousResults) {
      this.$searchResults.addClass('visible');
      this.$quickLinks.removeClass('visible');
      this.showFlyDown = true;
    } else if (this.$quickLinks.length) {
      this.$searchResults.removeClass('visible');
      this.$quickLinks.addClass('visible');
      this.showFlyDown = true;
    }
  }

  _search(event) {
    // Ignore non character key strokes
    const invalidKeyStrokes = [
      'Alt',
      'ArrowRight',
      'ArrowLeft',
      'ArrowUp',
      'ArrowDown',
      'Capslock',
      'Control',
      'Escape',
      'Meta',
      'Shift',
      'Tab',
      'Enter',
    ];

    if (event.key && invalidKeyStrokes.indexOf(event.key) !== -1) {
      return;
    }

    let terms = this.$input.val();
    const hasTerms = terms.length > 0;

    if (!hasTerms) {
      if (!this.takeOverActive) {
        this._flyDownInitial(true);
      }

      if (!this.showFlyDown) {
        this._closeFlyDown(true);
      }

      return;
    }

    this._flyDownSearching();

    // Clear previous results
    this.$searchResults.html('');

    // If terms has length, this will be true
    this._toggleButton(hasTerms);

    // Show the flyout if it isn't open
    if (!this.$flyDown.data('is-open')) {
      this.showFlyDown = true;
      this._openFlyDown();
    }

    // Adds `*` after each word in search terms, doesn't add to last word
    if (terms.indexOf(' ') > 0) {
      terms = terms.split(' ').join('* ').trim();
    }

    // Adds `*` at the end of the search terms
    terms = `${terms}*`;

    this.search.execute(terms);
  }

  _searchError(response) {
    console.warn('Search had error');
    console.log(response.message, response.error, response.event);
    this._toggleButton(false);
  }

  /**
   * Search has been cancelled due to new search being performed
   *
   * @private
   */
  _searchCancelled() {
    // Searches are canceled on user interaction, UI should remain unchanged
  }

  _searchComplete(response) {
    let { content, products, terms, sanitizedTerms } = response;

    content = content.slice(0, MAX_RESULTS),
    products = products.slice(0, MAX_RESULTS);

    const settings = {
      products: {
        results: {
          products,
          enableImages: this.enableImages,
        },
        noResults: {
          context: this.settings.context,
          terms: sanitizedTerms,
        },
      },
      content: {
        results: {
          context: this.settings.context,
          content,
        },
        noResults: {
          context: this.settings.context,
        },
      },
      footer: {
        context: this.settings.context,
        terms,
        type: this.searchType,
      },
    };

    const $productResults = products.length
      ? ProductResults(settings.products.results)
      : ProductNoResults(settings.products.noResults);

    const $contentResults = content.length
      ? ContentResults(settings.content.results)
      : ContentNoResults(settings.content.noResults);

    $productResults.find('.money').each((i, el) => {
      el.innerHTML = Shopify.formatMoney(el.innerHTML, this.settings.money_format);
    });

    // Hide placeholder
    // TODO: Lock minimum height?
    this.$searchPlaceholder.removeClass('visible');

    // Show results
    this.$searchResults
      .html($productResults)
      .addClass('visible');

    if (this.contentTypes !== 'product') {
      this.$searchResults
        .append($contentResults);
    }

    this.$searchResults
      .append(ResultsFooter(settings.footer));

    this._toggleButton(false);
  }

  /**
   * Toggles search button while processing
   *
   * @param disable
   * @private
   */
  _toggleButton(disable) {
    if (disable) {
      this.$button
        .addClass('search-icon--processing')
        .attr('disabled');
    } else {
      this.$button
        .removeClass('search-icon--processing')
        .removeAttr('disabled');
    }
  }

  _searchFocused(e) {
    e.stopPropagation();

    if (this.flyDownActive) {
      return;
    }

    const hasTerms = this.$input.val().length > 0;

    // Only listen for events outside of the search flydown when its open
    this.closeEvents = [
      this.$document.on('focusin.live-search', this._documentFocus),
      this.$document.on('touchstart.live-search', this._documentFocus),
      this.$document.on('click.live-search', this._documentFocus),
    ];

    this.$el.addClass('live-search--focused');

    this._flyDownInitial(hasTerms);

    this._openFlyDown();
  }

  _openFlyDown() {
    if (!this.showFlyDown) {
      return;
    }

    window.addEventListener('keydown', this._closeEsc);

    this.$el.addClass('live-search--active');
    this.$flyDown.data('is-open', true);
    this.flyDownActive = true;
  }

  /**
   * Close the FlyDown when no longer needed
   *  - Keep focus styling if input is still being interacted with
   *
   * @param retainFocus
   * @private
   */
  _closeFlyDown(retainFocus = false) {
    if (!this.flyDownActive) {
      if (!retainFocus) {
        this.$el.removeClass('live-search--focused');
      }

      return;
    }

    if (this.takeOverActive) {
      this._toggleTakeOver(null, false);
    }

    this.$flyDown.data('is-open', false);
    this.$el.removeClass('live-search--active');

    if (!retainFocus) {
      this.$el.removeClass('live-search--focused');
    }

    this.flyDownActive = false;
    if (this.closeEvents && this.closeEvents.length) {
      this.closeEvents.forEach($el => {
        $el.off('.live-search');
      });
    }

    window.removeEventListener('keydown', this._closeEsc);

    this.closeEvents = [];
  }

  /**
   * Open or close the Take over for mobile
   *
   *
   * @param event
   * @param enable
   * @private
   */
  _toggleTakeOver(event = null, enable = false) {
    this.takeOverActive = enable;

    if (event) {
      event.preventDefault();
    }

    if (!enable) {
      this._closeFlyDown(false);
    }

    if (this.$header.hasClass('search--section')) {
      this.$body.toggleClass('search-takeover-active', enable);
    }

    this.$el.toggleClass('live-search--takeover', enable);
    this.$body.toggleClass('scroll-lock', enable);
  }

  openMobileTakeover() {
    this._toggleTakeOver(null, true);

    window.addEventListener('keydown', this._closeEsc);

    this.$input.focus();
  }

  /**
   * When the focus element has changed, either by clicking, touching, or tabbing
   * check to see if it is within the flydown
   *
   * @param event
   * @private
   */
  _documentFocus(event) {
    const $parent = $(event.target).parents('[data-live-search]');

    if ($parent.length) {
      return;
    }

    this._closeFlyDown();
  }
}
