import $ from 'jquery';

export default class MessageBanner {
  constructor() {
    this.$window = $(window);
    this.$document = $(document);
    this.$body = $(document.body);
    this.$bannerTemplate = $('[data-templates] [data-message-banner]');
    this.$banners = [];

    this.header = document.querySelector('[data-site-header]');
    this.events = [];

    this.$document.on('touchstart.message-banner, click.message-banner', (event) => {
      this._handleDocumentClick(event.target);
    });

    this.$window.on('keydown.message-banner', event => this._closeEsc(event));
  }

  unload() {
    this.$document.off('touchstart.message-banner, click.message-banner');
    this.$window.off('keydown.message-banner');
  }

  message(message, type) {
    const $banner = this.$bannerTemplate.clone();

    $banner
      .addClass(`message--${type}`)
      .find('[data-message-banner-content]')
      .html(message);

    $(this.header).append($banner);
    this._bindEvents($banner);
    this._show($banner);
  }

  dismissBanners() {
    const $visibleBanners = $('[data-message-banner]:visible');

    $visibleBanners.each((index, banner) => {
      this._hide($(banner), index);
    });
  }

  _bindEvents($banner) {
    this.events.push([
      $banner.on('click.message-banner', '[data-message-banner-close]', (event) => {
        event.preventDefault();
        this._hide($banner);
      }),
      this.$window.on('keydown.message-banner', (e) => {
        if (e.key === 'Escape') {
          this._hide($banner);
        }
      }),
    ]);
  }

  _closeEsc(e) {
    if (e.key === 'Escape') {
      this.dismissBanners();
    }
  }

  _show($banner) {
    this.$banners.push($banner);

    $banner
      .addClass('animating animating-in')
      .one('trend', () => {
        $banner
          .removeClass('animating animating-in')
          .addClass('visible')
          .off('trend');
      });
  }

  _hide($banner, index = 0) {
    $banner
      .addClass('animating animating-out')
      .one('trend', () => {
        $banner
          .removeClass('animating animating-out visible')
          .off('trend');
        this._removeBanner($banner, index);
      });
  }

  _removeBanner($banner, index) {
    if (this.events[index]) {
      this.events[index].forEach($el => $el.off('.message-banner'));
      this.events.splice(index, 1);
    }

    this.$banners.splice(index, 1);
    $banner.remove();
  }

  _handleDocumentClick(target) {
    const $parent = $(target).parents('[data-message-banner]');
    if ($parent.length) return;

    this.dismissBanners();
  }
}
