/**
 * Search API Class
 */
export default class Search {
  /**
   * Create a new Search instance, and override defaults
   *
   * @param options
   * @property {string} options.type - Search restriction. Use 'article', 'page', 'product', or ''
   * @property {string} options.view - Search template suffix. Specify different templates as needed
   */
  constructor(options = {}) {
    this.options = {
      type: '',
      view: 'json',
      onInitialize: () => {},
      onCancel: () => {},
      onComplete: () => {},
      onAbort: () => {},
      onError: () => {},
      ...options,
    };
    this.inProgress = false;

    this.xhr = window.XMLHttpRequest
      ? new XMLHttpRequest()
      : new ActiveXObject('Microsoft.XMLHTTP');

    this._xhrLoad = this._xhrLoad.bind(this);
    this._xhrError = this._xhrError.bind(this);
    this._xhrAbort = this._xhrAbort.bind(this);

    this._bindEvents();
  }

  _bindEvents() {
    this.xhr.addEventListener('load', this._xhrLoad);
    this.xhr.addEventListener('error', this._xhrError);
    this.xhr.addEventListener('abort', this._xhrAbort);
  }

  /**
   * XHR was successfull
   *
   * @description XHR completed successfully
   * @param {object} event
   * @fires Search#complete
   * @private
   */
  _xhrLoad(event) {
    let response = {};
    const readyState = event.target.readyState;
    const status = event.target.status;

    try {
      if (readyState > 3 && status == 200) {
        response = JSON.parse(event.target.responseText);
        this.options.onComplete(response);
      } else {
        const message = `Invalid readyState: ${readyState}, or status: ${status}.`;
        return this._xhrError(event, message);
      }
    } catch (error) {
      return this._xhrError(event, 'Invalid JSON', error);
    }

    this.inProgress = false;
  }

  /**
   * XHR contains an error
   *
   * @description XHR has contained an error, and was unable to complete
   * @param {object} event
   * @param {object|string} message
   * @param {object} error
   * @fires Search#error
   * @private
   */
  _xhrError(event, message = null, error = null) {
    this.inProgress = false;
    this.options.onError({ event, message, error });
  }

  /**
   * XHR has been aborted
   *
   * @description This event only fires if there is an active XHR request that has been terminated
   * @fires Search#aborted
   * @private
   */
  _xhrAbort() {
    this.options.onAbort();
  }

  /**
   * Perform the search
   *
   * @param {string} term - Search term(s)
   * @fires Search#initialized
   * @fires Search#cancelled
   */
  execute(term) {
    this.options.onInitialize({ term });

    if (this.inProgress) {
      this.options.onCancel();
      this.xhr.abort();
    }

    this.inProgress = true;

    const url = window.Theme.routes.search_url;
    const params = {
      q: term,
      type: this.options.type,
      view: this.options.view,
    };

    const searchParams = Object.keys(params).map((key) => {
      const value = params[key];
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    }).join('&');

    this.xhr.open('get', `${url}?${searchParams}`);
    this.xhr.setRequestHeader('Accept', 'application/json');
    this.xhr.send();
  }
}

/**
 * @description XHR initialized
 * @event Search#initialized
 * @type {object}
 */

/**
 * @description XHR completed
 * @event Search#complete
 * @property {object} response - JSON response from end point
 */

/**
 * @description XHR had an error
 * @event Search#error
 */

/**
 * @description XHR cancelled due to user interaction. If an XHR is being processed, this does not terminate it
 * @event Search#cancelled
 */

/**
 * @description XHR aborted due to user interaction, terminating previously executed XHR.
 * @event Search#aborted
 */
