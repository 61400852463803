import * as breakpoint from '@pixelunion/breakpoint';
import Header from './Header';

export default class MinimalHeader extends Header {
  constructor(options, settings) {
    super(options, settings);

    this.navOpenClass = 'site-header-nav--open';
    this.menuToggle = this.header.querySelector('[data-menu-toggle]');
    this.menu = this.section.querySelector('[data-site-navigation]');

    this.searchOpenClass = 'site-header-search--open';
    this.searchToggle = this.header.querySelector('[data-search-toggle]');
    this.searchInput = this.header.querySelector('[data-live-search-input]');

    this.transitioning = false;

    // Setup Menu toggles
    this._toggleMenu = this._toggleMenu.bind(this);

    this.toggleClick = event => {
      event.preventDefault();
      if (breakpoint.min('L')) this._toggleMenu();
    };

    this.menuToggle.addEventListener('click', this.toggleClick);

    // Setup Search toggles
    this._toggleSearchBar = this._toggleSearchBar.bind(this);
    this._closeSearchBar = this._closeSearchBar.bind(this);

    this.searchTransitioning = false;

    // Show the menu briefly to get its height and then replace its style
    this.menu.setAttribute('style', 'display: block; visibility: hidden;');
    this.menu.setAttribute('style', `margin-top: -${this.menu.getBoundingClientRect().height}px;`);

    this._closeEsc = e => {
      if (e.key === 'Escape') {
        if (this.searchBar.contains(document.activeElement)) {
          this.searchToggle.focus();
        }
        this._closeSearchBar();
      }
    };

    this.stopProp = e => e.stopPropagation();

    // If reloading in the editor close the search bar so it doesn't interfere with other settings
    this._closeSearchBar();

    this.searchToggle.addEventListener('click', this._toggleSearchBar);
    this.searchBar.addEventListener('click', this.stopProp);
    window.addEventListener('resize', this._closeSearchBar);
    window.addEventListener('click', this._closeSearchBar);
    window.addEventListener('keydown', this._closeEsc);
  }

  closeNavigation() {
    if (this.searchTransitioning || this.transitioning) { return; }

    this.menuToggle.classList.remove('active');

    this.navTransitionOutEvent = () => {
      this.header.classList.remove(this.navOpenClass);
      this.transitioning = false;
      this.menu.removeEventListener('transitionend', this.navTransitionOutEvent);
    };

    this.menu.addEventListener('transitionend', this.navTransitionOutEvent);

    this.transitioning = true;
    this.menu.setAttribute('style', `margin-top: -${this.menu.getBoundingClientRect().height}px;`);

    this.postMessage('nav:close-all');
  }

  openNavigation() {
    if (this.searchTransitioning) {
      return;
    }

    this._closeSearchBar();

    if (this.transitioning || this.header.classList.contains(this.navOpenClass)) { return; }

    this.menuToggle.classList.add('active');

    this.navTransitionInEvent = () => {
      this.transitioning = false;
      this.menu.removeEventListener('transitionend', this.navTransitionInEvent);
    };

    this.menu.addEventListener('transitionend', this.navTransitionInEvent);

    this.transitioning = true;

    // We need to wait for the browser to set the display to 'block' before we set the margin
    // This will help with ensuring the different animations/transitions happen in sequence
    // and not at the same time.
    window.requestAnimationFrame(() => {
      this.header.classList.add(this.navOpenClass);

      window.requestAnimationFrame(() => {
        this.menu.setAttribute('style', 'margin-top: 0;');
      });
    });
  }

  _toggleMenu() {
    if (this.header.classList.contains(this.navOpenClass)) {
      this.closeNavigation();
    } else {
      this.openNavigation();
    }
  }

  _toggleSearchBar(e) {
    e.stopPropagation();
    e.preventDefault();

    if (breakpoint.max('S')) {
      if (this.header.classList.contains(this.searchOpenClass)) {
        // This will only be called on mobile if LiveSearch is not enabled.
        this._closeSearchBar();
      } else if (this.liveSearch) {
        // If LiveSearch is enabled, open the mobile search takeover
        this.liveSearch.openMobileTakeover();
      } else {
        // If LiveSearch is not enabled, we need to show the mobile search bar without takeover
        this._openSearchBar();
      }
    } else if (this.header.classList.contains(this.searchOpenClass)) {
      // If the search bar is open
      this._closeSearchBar();
    } else {
      this._openSearchBar();
    }
  }

  _openSearchBar() {
    if (this.searchTransitioning || this.header.classList.contains(this.searchOpenClass)) {
      return;
    }

    if (this.header.classList.contains(this.navOpenClass)) {
      this.closeNavigation();
    }

    this.searchTransitioning = true;

    this.searchAnimatingInEvent = () => {
      this.searchBar.classList.remove('animating-in');
      this.searchTransitioning = false;
      this.searchBar.removeEventListener('animationend', this.searchAnimatingInEvent);
    };

    this.header.classList.add(this.searchOpenClass);
    this.searchBar.addEventListener('animationend', this.searchAnimatingInEvent);
    this.searchBar.classList.add('animating-in');

    this.searchInput.focus();
  }

  _closeSearchBar() {
    if (this.searchTransitioning || (!this.header.classList.contains(this.searchOpenClass))) {
      return;
    }

    this.searchTransitioning = true;

    this.searchAnimatingOutEvent = () => {
      this.searchBar.classList.remove('animating-out');
      this.header.classList.remove(this.searchOpenClass);
      this.searchTransitioning = false;
      this.searchBar.removeEventListener('animationend', this.searchAnimatingOutEvent);
    };

    this.searchBar.addEventListener('animationend', this.searchAnimatingOutEvent);
    this.searchBar.classList.add('animating-out');
  }

  unload() {
    super.unload();
    this.menuToggle.removeEventListener('click', this.toggleClick);
    this.searchToggle.removeEventListener('click', this._toggleSearchBar);
    this.searchBar.removeEventListener('click', this.stopProp);
    window.removeEventListener('resize', this._closeSearchBar);
    window.removeEventListener('click', this._closeSearchBar);
    window.removeEventListener('keydown', this._closeEsc);
  }

  onSectionBlockSelect() {
    this.menuToggle.classList.add('active');
    this.header.classList.add(this.navOpenClass);
    this.openNavigation();
  }

  onSectionBlockDeselect() {
    this.menuToggle.classList.remove('active');
  }

  sectionDeselect() {
    this.closeNavigation();
  }
}
