/*
 * Initialize Shopify Product Reviews
 *
 * */

export default function initShopifyProductReviews() {
  if (!window.SPR) {
    return;
  }

  window.SPR.registerCallbacks();
  window.SPR.initRatingHandler();
  window.SPR.initDomEls();
  window.SPR.loadProducts();
  window.SPR.loadBadges();
}

